import { InvalidInput } from "./error";
import {
  CompDifference,
  OmitKeys,
  buildArrayUpdate,
  AllowedDecimalPlaces,
  addDecimal,
} from "../utils";
import Decimal from "decimal.js";
import { AssetType, CustomizedType } from "./enums";
import { EncryptedType, RequireEncryptionFields } from "../encryption/utils";
import { ExchangeRateDataMap } from "../database/exchangeRate";
import { Asset } from "./common/asset";
import type { Attachment } from "./common/attachment";
import { SupportLiabilityType } from "./cashAndBankingSummary";
import {
  IsEnum,
  IsOptionalOnUpdate,
  IsPositive,
  NotEncrypted,
  SimpleUpdate,
} from "../decorators";
import { Insured } from "./insurance";

export * from "./common/attachment";
export class AssetV2 extends Asset {}
export { AssetType, CustomizedType };

export interface IAggregateData {
  id: string;
  version: number;
}

export interface Amount {
  currency: Currency;
  value: number;
}
export namespace Amount {
  export function validate(fieldName: string, data: Amount) {
    if (isNaN(data.value)) {
      throw new InvalidInput("Value should be a number");
    }
    // (1) 12 leading numeric characters and 2 decimal places
    // (2) cannot be greater than 999,999,999,999.00
    const regex = /^-?\d{1,12}(\.\d{1,2})?$/;
    const labelName = {
      price: "item price",
      value: "total valuation",
      totalCost: "total cost",
    }[fieldName];
    if (
      !regex.test(data.value.toString()) ||
      Math.abs(data.value) > 999999999999.0
    )
      throw new InvalidInput(
        `The value of ${labelName} cannot be greater than 999,999,999,999.00.`
      );
  }
  export function add(a: Amount, b: Amount): Amount {
    if (a.currency !== b.currency) {
      throw new InvalidInput("Currency mismatch");
    }
    return {
      currency: a.currency,
      value: new Decimal(a.value).add(b.value).toNumber(),
    };
  }
  export function equal(a: Amount, b: Amount): boolean {
    return a.currency === b.currency && a.value === b.value;
  }
  export function optionalEqual(a: Optional<Amount>, b: Optional<Amount>) {
    if (a && b) {
      return equal(a, b);
    } else {
      return a === b;
    }
  }
  export function defaultValue(): Amount {
    return { value: 0, currency: Currency.USD };
  }

  export function zero(currency: Currency = Currency.USD): Amount {
    return { value: 0, currency };
  }

  export function toNegative(from: Amount): Amount {
    return {
      currency: from.currency,
      value: -from.value,
    };
  }
  export function toAbsolute(from: Amount): Amount {
    return {
      currency: from.currency,
      value: Math.abs(from.value),
    };
  }
}

export type MultiCurrencyAmount = {
  [key in Currency]?: number;
};
export namespace MultiCurrencyAmount {
  export function add(base: MultiCurrencyAmount, ...amounts: Amount[]) {
    amounts.forEach((amount) => {
      if (amount.value === 0) return;
      base[amount.currency] = new Decimal(base[amount.currency] || 0)
        .add(amount.value)
        .toNumber();
    });
    return base;
  }

  export function subtract(base: MultiCurrencyAmount, ...amounts: Amount[]) {
    amounts.forEach((amount) => {
      if (amount.value === 0) return;
      base[amount.currency] = new Decimal(base[amount.currency] || 0)
        .sub(amount.value)
        .toNumber();
    });
    return base;
  }

  export function equal(a: MultiCurrencyAmount, b: MultiCurrencyAmount) {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) return false;

    return keysA.every(
      (key) =>
        a[key as keyof MultiCurrencyAmount] ===
        b[key as keyof MultiCurrencyAmount]
    );
  }

  export function calculateBaseAmount(
    amount: MultiCurrencyAmount,
    exchangeRates: MultiCurrencyAmount,
    baseCurrency: Currency
  ): Amount {
    return Object.entries(amount).reduce(
      (acc: Amount, [currency, value]) => {
        if (currency == baseCurrency) {
          addDecimal(acc.value, value);
        } else if (exchangeRates[<Currency>currency]) {
          const rate = exchangeRates[<Currency>currency]!;
          new Decimal(1)
            .div(rate)
            .mul(value)
            .toDecimalPlaces(AllowedDecimalPlaces)
            .add(acc.value);
        }
        return acc;
      },
      { currency: baseCurrency, value: 0 }
    );
  }

  export function calculateTargetCurrencyAmount(
    amount: MultiCurrencyAmount,
    exchangeRate: TargetCurrencyExchangeRateDataMap
  ): Amount {
    const targetValue = Object.entries(amount).reduce(
      (acc, [currency, value]) => {
        return new Decimal(value)
          .mul(exchangeRate.rates[currency].rate)
          .toDecimalPlaces(AllowedDecimalPlaces)
          .add(acc);
      },
      new Decimal(0)
    );
    return {
      currency: exchangeRate.targetCurrency,
      value: targetValue.toNumber(),
    };
  }

  export function fromAmounts(...amounts: Amount[]) {
    return amounts.reduce((acc, amount) => {
      if (amount.value === 0) return acc;
      acc[amount.currency] = (acc[amount.currency] || 0) + amount.value;
      return acc;
    }, {} as MultiCurrencyAmount);
  }

  export function toAmounts(amount: MultiCurrencyAmount): Amount[] {
    return Object.entries(amount).map(([currency, value]) => ({
      currency: <Currency>currency,
      value: value!,
    }));
  }
}

export interface Ownership {
  myOwnership: number;
  shareholder: Owner[];
}
export namespace Ownership {
  export function validate(data: Ownership) {
    Owner.validate(data.myOwnership, data.shareholder);
  }

  export function equal(a: Ownership, b: Ownership): boolean {
    return (
      a.myOwnership === b.myOwnership &&
      Owner.arrayEqual(a.shareholder, b.shareholder)
    );
  }
  export function optionalEqual(
    a: Optional<Ownership>,
    b: Optional<Ownership>
  ): boolean {
    if (a && b) {
      return (
        a.myOwnership === b.myOwnership &&
        Owner.arrayEqual(a.shareholder, b.shareholder)
      );
    } else {
      return a === b;
    }
  }
}

export type Beneficiary = Owner[];

export interface Owner {
  contactId: string; //ref to addr
  percent: number;
}
export namespace Owner {
  export function validate(reservedPercentage: number, owners: Owner[]) {
    if (reservedPercentage < 0 || reservedPercentage > 100) {
      throw new InvalidInput("My ownership should be between 0 and 100");
    }
    const total = owners.reduce(
      (acc, owner) => acc + owner.percent,
      reservedPercentage
    );
    if (total > 100) {
      throw new InvalidInput("Total percent should be less than 100");
    }
  }

  export function equal(a: Owner, b: Owner): boolean {
    return a.contactId === b.contactId && a.percent === b.percent;
  }
  export function arrayEqual(a: Owner[], b: Owner[]): boolean {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (!equal(a[i], b[i])) return false;
    }
    return true;
  }
  export function optionalEqual(
    a: Optional<Owner[]>,
    b: Optional<Owner[]>
  ): boolean {
    if (a && b) {
      return arrayEqual(a, b);
    } else {
      return a === b;
    }
  }
}

export interface AssetV2WithWineBottle extends AssetV2 {
  wineId?: string;
  purchaseId?: string;
}

export interface AssetV2WithCollectableAttributes extends AssetV2 {
  measurementUnits?: MeasurementUnits;
  height?: number;
  width?: number;
  depth?: number;
  weight?: number;
  dimensionsSummary?: string;
  attributeNotes?: string;
  creationCompletionYear?: Date;
  edition?: string;
  provenance?: string;
  geography?: string;
  medium?: string;
  inscription?: string;
  description?: string;
  catalogueText?: string;
  dateExecuted?: Date;
}

export namespace AssetV2WithCollectableAttributes {
  export const EncryptedFields: (keyof AssetV2WithCollectableAttributes)[] = [
    "notes",
    "attributeNotes",
  ];
  export type EncryptedKeys = AssetV2.EncryptedKeys | "attributeNotes";
  export type Encrypted = RequireEncryptionFields<
    EncryptedType<AssetV2WithCollectableAttributes, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;
  // for activity log (maybe can use decorator in the future)
  export const baseAttributeKeys: (keyof AssetV2WithCollectableAttributes)[] = [
    "measurementUnits",
    "height",
    "width",
    "depth",
    "weight",
    "dimensionsSummary",
    "attributeNotes",
    "creationCompletionYear",
    "edition",
    "provenance",
    "geography",
    "inscription",
    "description",
    "catalogueText",
  ];
}

export enum LocationType {
  MyProperty = "Property",
  Address = "AddressBook",
  NewAddress = "NewAddress",
}

export type SupportActivityType =
  | AssetType.Art
  | AssetType.WineAndSpirits
  | AssetType.Property
  | AssetType.Belonging
  | AssetType.OtherCollectables;

export const allocationTypesList: SupportLiabilityType[] = [
  AssetType.Art,
  AssetType.WineAndSpirits,
  AssetType.OtherCollectables,
  AssetType.Belonging,
  AssetType.Property,
  AssetType.OtherInvestment,
];
export const allocationTypesOptions = allocationTypesList.map((value) => ({
  label: value,
  value,
}));

export namespace AssetV2 {
  export const EncryptedFields: (keyof AssetV2)[] = ["notes"];
  export type EncryptedKeys = "notes";
  export type Encrypted = RequireEncryptionFields<
    EncryptedType<AssetV2, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;

  type AssetStates = Pick<AssetV2, "closedWith" | "readonly" | "archived">;
  export function checkDelete(data: AssetStates) {
    //#TODO not sure if this is possible
    if (data.readonly) {
      throw new InvalidInput("Can't update readonly asset");
    }
  }

  export function checkUpdate(data: AssetStates) {
    if (data.readonly) {
      throw new InvalidInput("Can't update readonly asset");
    }
    if (data.closedWith !== undefined) {
      throw new InvalidInput("Can't update closed/sold asset");
    }
    if (data.archived !== undefined) {
      throw new InvalidInput("Can't update archived asset");
    }
  }
}

export interface FavoriteFile {
  [assetId: string]: {
    [key: string]: null;
  };
}

export interface BalanceSheet {
  assets: Amount;
  liabilities: Amount;
  netValue: Amount;
}

export interface ExchangeRateData {
  rate: number;
  date: Date;
}

export interface TargetCurrencyExchangeRateDataMap {
  targetCurrency: Currency;
  rates: ExchangeRateDataMap;
}
export namespace TargetCurrencyExchangeRateDataMap {
  export function valueToCurrency(
    exchangeRate: TargetCurrencyExchangeRateDataMap,
    currency: Currency,
    value: Decimal.Value
  ): number {
    const rateData = exchangeRate.rates[currency];
    if (rateData === undefined) {
      throw new Error("Invalid currency");
    }
    return new Decimal(value)
      .mul(rateData.rate)
      .toDecimalPlaces(AllowedDecimalPlaces)
      .toNumber();
  }

  export function amountToCurrency(
    exchangeRate: TargetCurrencyExchangeRateDataMap,
    amount: Amount
  ): Amount {
    return {
      value: valueToCurrency(exchangeRate, amount.currency, amount.value),
      currency: exchangeRate.targetCurrency,
    };
  }
}

export interface MultipleCurrencyValuation {
  [currencyKind: string]: number;
}

export enum MeasurementUnits {
  Metric = "Metric",
  Imperial = "Imperial",
}

export const measurementUnitValues = Object.values(MeasurementUnits);

export enum Period {
  Day = "Day",
  Week = "Week",
  Month = "Month",
  Quarter = "Quarter",
  Year = "Year",
}

export const periodValues = Object.values(Period);

export class PeriodWithNumber {
  @NotEncrypted
  @IsEnum(Period)
  @IsOptionalOnUpdate()
  period!: Period;

  @NotEncrypted
  @IsPositive({ message: "Period number must be positive" })
  @IsOptionalOnUpdate()
  @SimpleUpdate
  num!: number;
}

export type WithId<T> = T & { id: string };

export type ChangeTypeOfKeys<
  T extends object,
  Keys extends keyof T,
  NewType
> = {
  [key in keyof T]: key extends Keys ? NewType : T[key];
};

export type PathsOfDateField<T extends object> = {
  [Key in keyof T & string]: NonNullable<T[Key]> extends Date
    ? `${Key}`
    : NonNullable<T[Key]> extends object
    ? `${Key}.${PathsOfDateField<NonNullable<T[Key]>>}`
    : never;
}[keyof T & string];

export type PathsOfAmountField<T extends object> = {
  [Key in keyof T & string]: NonNullable<T[Key]> extends Amount
    ? `${Key}`
    : never;
}[keyof T & string];

export type UpdateReqOf<T extends object, K extends keyof T = never> = Partial<
  OmitKeys<T, K>
>;

export type RawMapiSearchWithValueObject = {
  fireId: string;
  category: AssetType;
  subType: string;
  valueCurrency?: string;
  ownedValue?: string;
  excludeFromValue?: boolean;
  purchases?: { fireId: string }[];
};
export type MapiSearchWithValueObject = {
  fireId: string;
  category: AssetType;
  subType: string;
  ownedValue?: Amount;
  excludeFromValue?: boolean;
  purchases?: { fireId: string }[];
};
// | {
//     fireId: string;
//     category: Exclude<
//       AssetType,
//       AssetType.WinePurchases | AssetType.WineAndSpirits
//     >;
//     subType: string;
//     valueCurrency: Optional<string>;
//     ownedValue: Optional<number>;
//     excludeFromValue: boolean;
//   }
// | {
//     fireId: string;
//     category: AssetType.WineAndSpirits | AssetType.WinePurchases;
//     subType: string;
//     purchases: { fireId: string }[];
//   };

export function compareGroupUpdate(
  current: Optional<string[]>,
  update: Optional<string[]>
): {
  fieldUpdate?: Deletable<string[]>;
  groupChanges: {
    addedToGroup?: string[];
    removedFromGroup?: string[];
  };
} {
  const result: {
    fieldUpdate?: Deletable<string[]>;
    groupChanges: {
      addedToGroup?: string[];
      removedFromGroup?: string[];
    };
  } = { groupChanges: {} };
  const groupDifference = buildArrayUpdate(current, update, (v, arr) => {
    return CompDifference.fromBoolean(arr.includes(v));
  });
  if (groupDifference !== undefined) {
    if (groupDifference === null) {
      result.groupChanges.removedFromGroup = current;
      result.fieldUpdate = null;
    } else {
      if (groupDifference.added.length > 0) {
        result.groupChanges.addedToGroup = groupDifference.added;
      }
      if (groupDifference.removed.length > 0) {
        result.groupChanges.removedFromGroup = groupDifference.removed;
      }
      result.fieldUpdate = update;
    }
  }
  return result;
}

export function compareInsuredUpdate(
  current: Optional<Insured[]>,
  update: Optional<Insured[]>
): {
  fieldUpdate?: Deletable<Insured[]>;
  insuredChanges: {
    addedToInsured?: Insured[];
    removedFromInsured?: Insured[];
  };
} {
  const result: {
    fieldUpdate?: Deletable<Insured[]>;
    insuredChanges: {
      addedToInsured?: Insured[];
      removedFromInsured?: Insured[];
    };
  } = { insuredChanges: {} };
  const insuredDifference = buildArrayUpdate(current, update, (v, arr) => {
    return CompDifference.fromBoolean(
      arr.map((i) => i.targetId).includes(v.targetId)
    );
  });
  if (insuredDifference !== undefined) {
    if (insuredDifference === null) {
      result.insuredChanges.removedFromInsured = current;
      result.fieldUpdate = null;
    } else {
      if (insuredDifference.added.length > 0) {
        result.insuredChanges.addedToInsured = insuredDifference.added;
      }
      if (insuredDifference.removed.length > 0) {
        result.insuredChanges.removedFromInsured = insuredDifference.removed;
      }
      result.fieldUpdate = update;
    }
  }
  return result;
}

export type Optional<T> = T | undefined;
export type Deletable<T> = T | null;

export enum Categories {
  MyFinances = "MyFinances",
  MyProperties = "MyProperties",
  MyCollectables = "MyCollectables",
  MyBelongings = "MyBelongings",
}
export function categoriesFromAssetType(assetType: AssetType): Categories {
  switch (assetType) {
    case AssetType.CashAndBanking:
    case AssetType.TraditionalInvestments:
    case AssetType.OtherInvestment:
    case AssetType.Cryptocurrency:
    case AssetType.Insurance:
      return Categories.MyFinances;
    case AssetType.Property:
      return Categories.MyProperties;
    case AssetType.Art:
    case AssetType.WineAndSpirits:
    case AssetType.OtherCollectables:
      return Categories.MyCollectables;
    case AssetType.Belonging:
      return Categories.MyBelongings;
    default:
      throw new Error("Invalid asset type for Categories");
  }
}

export enum FinanceTypes {
  CashAndBanking = "CashAndBanking",
  TraditionalInvestments = "TraditionalInvestments",
  OtherInvestments = "OtherInvestments",
  Cryptocurrencies = "Cryptocurrencies",
  Insurance = "Insurance",
}

export type FileAssetType = AssetType | "Uncategorized";

export enum Currency {
  AED = "AED",
  AFN = "AFN",
  ARS = "ARS",
  AUD = "AUD",
  BDT = "BDT",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BSD = "BSD",
  CAD = "CAD",
  CHF = "CHF",
  CLP = "CLP",
  CNH = "CNH",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  EUR = "EUR",
  GBP = "GBP",
  GTQ = "GTQ",
  HKD = "HKD",
  HNL = "HNL",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  IQD = "IQD",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KRW = "KRW",
  KZT = "KZT",
  LBP = "LBP",
  MAD = "MAD",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MYR = "MYR",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NZD = "NZD",
  OMR = "OMR",
  PEN = "PEN",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  QAR = "QAR",
  RON = "RON",
  RUB = "RUB",
  SAR = "SAR",
  SCR = "SCR",
  SEK = "SEK",
  SGD = "SGD",
  SZL = "SZL",
  THB = "THB",
  TND = "TND",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  VND = "VND",
  XAF = "XAF",
  XCD = "XCD",
  XPF = "XPF",
  ZAR = "ZAR",
  ZMW = "ZMW",
}

export enum TimeZone {
  Africa_Abidjan = "Africa/Abidjan",
  Africa_Accra = "Africa/Accra",
  Africa_Addis_Ababa = "Africa/Addis_Ababa",
  Africa_Algiers = "Africa/Algiers",
  // Africa_Asmera = "Africa/Asmera",
  Africa_Bamako = "Africa/Bamako",
  Africa_Bangui = "Africa/Bangui",
  Africa_Banjul = "Africa/Banjul",
  Africa_Bissau = "Africa/Bissau",
  Africa_Blantyre = "Africa/Blantyre",
  Africa_Brazzaville = "Africa/Brazzaville",
  Africa_Bujumbura = "Africa/Bujumbura",
  Africa_Cairo = "Africa/Cairo",
  Africa_Casablanca = "Africa/Casablanca",
  Africa_Ceuta = "Africa/Ceuta",
  Africa_Conakry = "Africa/Conakry",
  Africa_Dakar = "Africa/Dakar",
  Africa_Dar_es_Salaam = "Africa/Dar_es_Salaam",
  Africa_Djibouti = "Africa/Djibouti",
  Africa_Douala = "Africa/Douala",
  Africa_El_Aaiun = "Africa/El_Aaiun",
  Africa_Freetown = "Africa/Freetown",
  Africa_Gaborone = "Africa/Gaborone",
  Africa_Harare = "Africa/Harare",
  Africa_Johannesburg = "Africa/Johannesburg",
  Africa_Juba = "Africa/Juba",
  Africa_Kampala = "Africa/Kampala",
  Africa_Khartoum = "Africa/Khartoum",
  Africa_Kigali = "Africa/Kigali",
  Africa_Kinshasa = "Africa/Kinshasa",
  Africa_Lagos = "Africa/Lagos",
  Africa_Libreville = "Africa/Libreville",
  Africa_Lome = "Africa/Lome",
  Africa_Luanda = "Africa/Luanda",
  Africa_Lubumbashi = "Africa/Lubumbashi",
  Africa_Lusaka = "Africa/Lusaka",
  Africa_Malabo = "Africa/Malabo",
  Africa_Maputo = "Africa/Maputo",
  Africa_Maseru = "Africa/Maseru",
  Africa_Mbabane = "Africa/Mbabane",
  Africa_Mogadishu = "Africa/Mogadishu",
  Africa_Monrovia = "Africa/Monrovia",
  Africa_Nairobi = "Africa/Nairobi",
  Africa_Ndjamena = "Africa/Ndjamena",
  Africa_Niamey = "Africa/Niamey",
  Africa_Nouakchott = "Africa/Nouakchott",
  Africa_Ouagadougou = "Africa/Ouagadougou",
  Africa_Porto_Novo = "Africa/Porto-Novo",
  Africa_Sao_Tome = "Africa/Sao_Tome",
  Africa_Tripoli = "Africa/Tripoli",
  Africa_Tunis = "Africa/Tunis",
  Africa_Windhoek = "Africa/Windhoek",
  America_Adak = "America/Adak",
  America_Anchorage = "America/Anchorage",
  America_Anguilla = "America/Anguilla",
  America_Antigua = "America/Antigua",
  America_Araguaina = "America/Araguaina",
  America_Argentina_La_Rioja = "America/Argentina/La_Rioja",
  America_Argentina_Rio_Gallegos = "America/Argentina/Rio_Gallegos",
  America_Argentina_Salta = "America/Argentina/Salta",
  America_Argentina_San_Juan = "America/Argentina/San_Juan",
  America_Argentina_San_Luis = "America/Argentina/San_Luis",
  America_Argentina_Tucuman = "America/Argentina/Tucuman",
  America_Argentina_Ushuaia = "America/Argentina/Ushuaia",
  America_Aruba = "America/Aruba",
  America_Asuncion = "America/Asuncion",
  America_Bahia = "America/Bahia",
  America_Bahia_Banderas = "America/Bahia_Banderas",
  America_Barbados = "America/Barbados",
  America_Belem = "America/Belem",
  America_Belize = "America/Belize",
  America_Blanc_Sablon = "America/Blanc-Sablon",
  America_Boa_Vista = "America/Boa_Vista",
  America_Bogota = "America/Bogota",
  America_Boise = "America/Boise",
  // America_Buenos_Aires = "America/Buenos_Aires",
  America_Cambridge_Bay = "America/Cambridge_Bay",
  America_Campo_Grande = "America/Campo_Grande",
  America_Cancun = "America/Cancun",
  America_Caracas = "America/Caracas",
  // America_Catamarca = "America/Catamarca",
  America_Cayenne = "America/Cayenne",
  America_Cayman = "America/Cayman",
  America_Chicago = "America/Chicago",
  America_Chihuahua = "America/Chihuahua",
  America_Ciudad_Juarez = "America/Ciudad_Juarez",
  // America_Coral_Harbour = "America/Coral_Harbour",
  // America_Cordoba = "America/Cordoba",
  America_Costa_Rica = "America/Costa_Rica",
  America_Creston = "America/Creston",
  America_Cuiaba = "America/Cuiaba",
  America_Curacao = "America/Curacao",
  America_Danmarkshavn = "America/Danmarkshavn",
  America_Dawson = "America/Dawson",
  America_Dawson_Creek = "America/Dawson_Creek",
  America_Denver = "America/Denver",
  America_Detroit = "America/Detroit",
  America_Dominica = "America/Dominica",
  America_Edmonton = "America/Edmonton",
  America_Eirunepe = "America/Eirunepe",
  America_El_Salvador = "America/El_Salvador",
  America_Fort_Nelson = "America/Fort_Nelson",
  America_Fortaleza = "America/Fortaleza",
  America_Glace_Bay = "America/Glace_Bay",
  America_Godthab = "America/Godthab",
  America_Goose_Bay = "America/Goose_Bay",
  America_Grand_Turk = "America/Grand_Turk",
  America_Grenada = "America/Grenada",
  America_Guadeloupe = "America/Guadeloupe",
  America_Guatemala = "America/Guatemala",
  America_Guayaquil = "America/Guayaquil",
  America_Guyana = "America/Guyana",
  America_Halifax = "America/Halifax",
  America_Havana = "America/Havana",
  America_Hermosillo = "America/Hermosillo",
  America_Indiana_Knox = "America/Indiana/Knox",
  America_Indiana_Marengo = "America/Indiana/Marengo",
  America_Indiana_Petersburg = "America/Indiana/Petersburg",
  America_Indiana_Tell_City = "America/Indiana/Tell_City",
  America_Indiana_Vevay = "America/Indiana/Vevay",
  America_Indiana_Vincennes = "America/Indiana/Vincennes",
  America_Indiana_Winamac = "America/Indiana/Winamac",
  // America_Indianapolis = "America/Indianapolis",
  America_Inuvik = "America/Inuvik",
  America_Iqaluit = "America/Iqaluit",
  America_Jamaica = "America/Jamaica",
  // America_Jujuy = "America/Jujuy",
  America_Juneau = "America/Juneau",
  America_Kentucky_Monticello = "America/Kentucky/Monticello",
  America_Kralendijk = "America/Kralendijk",
  America_La_Paz = "America/La_Paz",
  America_Lima = "America/Lima",
  America_Los_Angeles = "America/Los_Angeles",
  // America_Louisville = "America/Louisville",
  America_Lower_Princes = "America/Lower_Princes",
  America_Maceio = "America/Maceio",
  America_Managua = "America/Managua",
  America_Manaus = "America/Manaus",
  America_Marigot = "America/Marigot",
  America_Martinique = "America/Martinique",
  America_Matamoros = "America/Matamoros",
  America_Mazatlan = "America/Mazatlan",
  // America_Mendoza = "America/Mendoza",
  America_Menominee = "America/Menominee",
  America_Merida = "America/Merida",
  America_Metlakatla = "America/Metlakatla",
  America_Mexico_City = "America/Mexico_City",
  America_Miquelon = "America/Miquelon",
  America_Moncton = "America/Moncton",
  America_Monterrey = "America/Monterrey",
  America_Montevideo = "America/Montevideo",
  America_Montserrat = "America/Montserrat",
  America_Nassau = "America/Nassau",
  America_New_York = "America/New_York",
  America_Nipigon = "America/Nipigon",
  America_Nome = "America/Nome",
  America_Noronha = "America/Noronha",
  America_North_Dakota_Beulah = "America/North_Dakota/Beulah",
  America_North_Dakota_Center = "America/North_Dakota/Center",
  America_North_Dakota_New_Salem = "America/North_Dakota/New_Salem",
  America_Ojinaga = "America/Ojinaga",
  America_Panama = "America/Panama",
  America_Pangnirtung = "America/Pangnirtung",
  America_Paramaribo = "America/Paramaribo",
  America_Phoenix = "America/Phoenix",
  America_Port_au_Prince = "America/Port-au-Prince",
  America_Port_of_Spain = "America/Port_of_Spain",
  America_Porto_Velho = "America/Porto_Velho",
  America_Puerto_Rico = "America/Puerto_Rico",
  America_Punta_Arenas = "America/Punta_Arenas",
  America_Rainy_River = "America/Rainy_River",
  America_Rankin_Inlet = "America/Rankin_Inlet",
  America_Recife = "America/Recife",
  America_Regina = "America/Regina",
  America_Resolute = "America/Resolute",
  America_Rio_Branco = "America/Rio_Branco",
  America_Santa_Isabel = "America/Santa_Isabel",
  America_Santarem = "America/Santarem",
  America_Santiago = "America/Santiago",
  America_Santo_Domingo = "America/Santo_Domingo",
  America_Sao_Paulo = "America/Sao_Paulo",
  America_Scoresbysund = "America/Scoresbysund",
  America_Sitka = "America/Sitka",
  America_St_Barthelemy = "America/St_Barthelemy",
  America_St_Johns = "America/St_Johns",
  America_St_Kitts = "America/St_Kitts",
  America_St_Lucia = "America/St_Lucia",
  America_St_Thomas = "America/St_Thomas",
  America_St_Vincent = "America/St_Vincent",
  America_Swift_Current = "America/Swift_Current",
  America_Tegucigalpa = "America/Tegucigalpa",
  America_Thule = "America/Thule",
  America_Thunder_Bay = "America/Thunder_Bay",
  America_Tijuana = "America/Tijuana",
  America_Toronto = "America/Toronto",
  America_Tortola = "America/Tortola",
  America_Vancouver = "America/Vancouver",
  America_Whitehorse = "America/Whitehorse",
  America_Winnipeg = "America/Winnipeg",
  America_Yakutat = "America/Yakutat",
  America_Yellowknife = "America/Yellowknife",
  Antarctica_Casey = "Antarctica/Casey",
  Antarctica_Davis = "Antarctica/Davis",
  Antarctica_DumontDUrville = "Antarctica/DumontDUrville",
  Antarctica_Macquarie = "Antarctica/Macquarie",
  Antarctica_Mawson = "Antarctica/Mawson",
  Antarctica_McMurdo = "Antarctica/McMurdo",
  Antarctica_Palmer = "Antarctica/Palmer",
  Antarctica_Rothera = "Antarctica/Rothera",
  Antarctica_Syowa = "Antarctica/Syowa",
  Antarctica_Troll = "Antarctica/Troll",
  Antarctica_Vostok = "Antarctica/Vostok",
  Arctic_Longyearbyen = "Arctic/Longyearbyen",
  Asia_Aden = "Asia/Aden",
  Asia_Almaty = "Asia/Almaty",
  Asia_Amman = "Asia/Amman",
  Asia_Anadyr = "Asia/Anadyr",
  Asia_Aqtau = "Asia/Aqtau",
  Asia_Aqtobe = "Asia/Aqtobe",
  Asia_Ashgabat = "Asia/Ashgabat",
  Asia_Atyrau = "Asia/Atyrau",
  Asia_Baghdad = "Asia/Baghdad",
  Asia_Bahrain = "Asia/Bahrain",
  Asia_Baku = "Asia/Baku",
  Asia_Bangkok = "Asia/Bangkok",
  Asia_Barnaul = "Asia/Barnaul",
  Asia_Beirut = "Asia/Beirut",
  Asia_Bishkek = "Asia/Bishkek",
  Asia_Brunei = "Asia/Brunei",
  Asia_Calcutta = "Asia/Calcutta",
  Asia_Chita = "Asia/Chita",
  Asia_Choibalsan = "Asia/Choibalsan",
  Asia_Colombo = "Asia/Colombo",
  Asia_Damascus = "Asia/Damascus",
  Asia_Dhaka = "Asia/Dhaka",
  Asia_Dili = "Asia/Dili",
  Asia_Dubai = "Asia/Dubai",
  Asia_Dushanbe = "Asia/Dushanbe",
  Asia_Famagusta = "Asia/Famagusta",
  Asia_Gaza = "Asia/Gaza",
  Asia_Hebron = "Asia/Hebron",
  Asia_Hong_Kong = "Asia/Hong_Kong",
  Asia_Hovd = "Asia/Hovd",
  Asia_Irkutsk = "Asia/Irkutsk",
  Asia_Jakarta = "Asia/Jakarta",
  Asia_Jayapura = "Asia/Jayapura",
  Asia_Jerusalem = "Asia/Jerusalem",
  Asia_Kabul = "Asia/Kabul",
  Asia_Kamchatka = "Asia/Kamchatka",
  Asia_Karachi = "Asia/Karachi",
  Asia_Katmandu = "Asia/Katmandu",
  Asia_Khandyga = "Asia/Khandyga",
  Asia_Krasnoyarsk = "Asia/Krasnoyarsk",
  Asia_Kuala_Lumpur = "Asia/Kuala_Lumpur",
  Asia_Kuching = "Asia/Kuching",
  Asia_Kuwait = "Asia/Kuwait",
  Asia_Macau = "Asia/Macau",
  Asia_Magadan = "Asia/Magadan",
  Asia_Makassar = "Asia/Makassar",
  Asia_Manila = "Asia/Manila",
  Asia_Muscat = "Asia/Muscat",
  Asia_Nicosia = "Asia/Nicosia",
  Asia_Novokuznetsk = "Asia/Novokuznetsk",
  Asia_Novosibirsk = "Asia/Novosibirsk",
  Asia_Omsk = "Asia/Omsk",
  Asia_Oral = "Asia/Oral",
  Asia_Phnom_Penh = "Asia/Phnom_Penh",
  Asia_Pontianak = "Asia/Pontianak",
  Asia_Pyongyang = "Asia/Pyongyang",
  Asia_Qatar = "Asia/Qatar",
  Asia_Qostanay = "Asia/Qostanay",
  Asia_Qyzylorda = "Asia/Qyzylorda",
  Asia_Rangoon = "Asia/Rangoon",
  Asia_Riyadh = "Asia/Riyadh",
  // Asia_Saigon = "Asia/Saigon",
  Asia_Sakhalin = "Asia/Sakhalin",
  Asia_Samarkand = "Asia/Samarkand",
  Asia_Seoul = "Asia/Seoul",
  Asia_Shanghai = "Asia/Shanghai",
  Asia_Singapore = "Asia/Singapore",
  Asia_Srednekolymsk = "Asia/Srednekolymsk",
  Asia_Taipei = "Asia/Taipei",
  Asia_Tashkent = "Asia/Tashkent",
  Asia_Tbilisi = "Asia/Tbilisi",
  Asia_Tehran = "Asia/Tehran",
  Asia_Thimphu = "Asia/Thimphu",
  Asia_Tokyo = "Asia/Tokyo",
  Asia_Tomsk = "Asia/Tomsk",
  Asia_Ulaanbaatar = "Asia/Ulaanbaatar",
  Asia_Urumqi = "Asia/Urumqi",
  Asia_Ust_Nera = "Asia/Ust-Nera",
  Asia_Vientiane = "Asia/Vientiane",
  Asia_Vladivostok = "Asia/Vladivostok",
  Asia_Yakutsk = "Asia/Yakutsk",
  Asia_Yekaterinburg = "Asia/Yekaterinburg",
  Asia_Yerevan = "Asia/Yerevan",
  Atlantic_Azores = "Atlantic/Azores",
  Atlantic_Bermuda = "Atlantic/Bermuda",
  Atlantic_Canary = "Atlantic/Canary",
  Atlantic_Cape_Verde = "Atlantic/Cape_Verde",
  // Atlantic_Faeroe = "Atlantic/Faeroe",
  Atlantic_Madeira = "Atlantic/Madeira",
  Atlantic_Reykjavik = "Atlantic/Reykjavik",
  Atlantic_South_Georgia = "Atlantic/South_Georgia",
  Atlantic_St_Helena = "Atlantic/St_Helena",
  Atlantic_Stanley = "Atlantic/Stanley",
  Australia_Adelaide = "Australia/Adelaide",
  Australia_Brisbane = "Australia/Brisbane",
  Australia_Broken_Hill = "Australia/Broken_Hill",
  Australia_Currie = "Australia/Currie",
  Australia_Darwin = "Australia/Darwin",
  Australia_Eucla = "Australia/Eucla",
  Australia_Hobart = "Australia/Hobart",
  Australia_Lindeman = "Australia/Lindeman",
  Australia_Lord_Howe = "Australia/Lord_Howe",
  Australia_Melbourne = "Australia/Melbourne",
  Australia_Perth = "Australia/Perth",
  Australia_Sydney = "Australia/Sydney",
  Europe_Amsterdam = "Europe/Amsterdam",
  Europe_Andorra = "Europe/Andorra",
  Europe_Astrakhan = "Europe/Astrakhan",
  Europe_Athens = "Europe/Athens",
  Europe_Belgrade = "Europe/Belgrade",
  Europe_Berlin = "Europe/Berlin",
  Europe_Bratislava = "Europe/Bratislava",
  Europe_Brussels = "Europe/Brussels",
  Europe_Bucharest = "Europe/Bucharest",
  Europe_Budapest = "Europe/Budapest",
  Europe_Busingen = "Europe/Busingen",
  Europe_Chisinau = "Europe/Chisinau",
  Europe_Copenhagen = "Europe/Copenhagen",
  Europe_Dublin = "Europe/Dublin",
  Europe_Gibraltar = "Europe/Gibraltar",
  Europe_Guernsey = "Europe/Guernsey",
  Europe_Helsinki = "Europe/Helsinki",
  Europe_Isle_of_Man = "Europe/Isle_of_Man",
  Europe_Istanbul = "Europe/Istanbul",
  Europe_Jersey = "Europe/Jersey",
  Europe_Kaliningrad = "Europe/Kaliningrad",
  Europe_Kiev = "Europe/Kiev",
  Europe_Kirov = "Europe/Kirov",
  Europe_Lisbon = "Europe/Lisbon",
  Europe_Ljubljana = "Europe/Ljubljana",
  Europe_London = "Europe/London",
  Europe_Luxembourg = "Europe/Luxembourg",
  Europe_Madrid = "Europe/Madrid",
  Europe_Malta = "Europe/Malta",
  Europe_Mariehamn = "Europe/Mariehamn",
  Europe_Minsk = "Europe/Minsk",
  Europe_Monaco = "Europe/Monaco",
  Europe_Moscow = "Europe/Moscow",
  Europe_Oslo = "Europe/Oslo",
  Europe_Paris = "Europe/Paris",
  Europe_Podgorica = "Europe/Podgorica",
  Europe_Prague = "Europe/Prague",
  Europe_Riga = "Europe/Riga",
  Europe_Rome = "Europe/Rome",
  Europe_Samara = "Europe/Samara",
  Europe_San_Marino = "Europe/San_Marino",
  Europe_Sarajevo = "Europe/Sarajevo",
  Europe_Saratov = "Europe/Saratov",
  Europe_Simferopol = "Europe/Simferopol",
  Europe_Skopje = "Europe/Skopje",
  Europe_Sofia = "Europe/Sofia",
  Europe_Stockholm = "Europe/Stockholm",
  Europe_Tallinn = "Europe/Tallinn",
  Europe_Tirane = "Europe/Tirane",
  Europe_Ulyanovsk = "Europe/Ulyanovsk",
  Europe_Uzhgorod = "Europe/Uzhgorod",
  Europe_Vaduz = "Europe/Vaduz",
  Europe_Vatican = "Europe/Vatican",
  Europe_Vienna = "Europe/Vienna",
  Europe_Vilnius = "Europe/Vilnius",
  Europe_Volgograd = "Europe/Volgograd",
  Europe_Warsaw = "Europe/Warsaw",
  Europe_Zagreb = "Europe/Zagreb",
  Europe_Zaporozhye = "Europe/Zaporozhye",
  Europe_Zurich = "Europe/Zurich",
  Indian_Antananarivo = "Indian/Antananarivo",
  Indian_Chagos = "Indian/Chagos",
  Indian_Christmas = "Indian/Christmas",
  Indian_Cocos = "Indian/Cocos",
  Indian_Comoro = "Indian/Comoro",
  Indian_Kerguelen = "Indian/Kerguelen",
  Indian_Mahe = "Indian/Mahe",
  Indian_Maldives = "Indian/Maldives",
  Indian_Mauritius = "Indian/Mauritius",
  Indian_Mayotte = "Indian/Mayotte",
  Indian_Reunion = "Indian/Reunion",
  Pacific_Apia = "Pacific/Apia",
  Pacific_Auckland = "Pacific/Auckland",
  Pacific_Bougainville = "Pacific/Bougainville",
  Pacific_Chatham = "Pacific/Chatham",
  Pacific_Easter = "Pacific/Easter",
  Pacific_Efate = "Pacific/Efate",
  Pacific_Enderbury = "Pacific/Enderbury",
  Pacific_Fakaofo = "Pacific/Fakaofo",
  Pacific_Fiji = "Pacific/Fiji",
  Pacific_Funafuti = "Pacific/Funafuti",
  Pacific_Galapagos = "Pacific/Galapagos",
  Pacific_Gambier = "Pacific/Gambier",
  Pacific_Guadalcanal = "Pacific/Guadalcanal",
  Pacific_Guam = "Pacific/Guam",
  Pacific_Honolulu = "Pacific/Honolulu",
  Pacific_Johnston = "Pacific/Johnston",
  Pacific_Kiritimati = "Pacific/Kiritimati",
  Pacific_Kosrae = "Pacific/Kosrae",
  Pacific_Kwajalein = "Pacific/Kwajalein",
  Pacific_Majuro = "Pacific/Majuro",
  Pacific_Marquesas = "Pacific/Marquesas",
  Pacific_Midway = "Pacific/Midway",
  Pacific_Nauru = "Pacific/Nauru",
  Pacific_Niue = "Pacific/Niue",
  Pacific_Norfolk = "Pacific/Norfolk",
  Pacific_Noumea = "Pacific/Noumea",
  Pacific_Pago_Pago = "Pacific/Pago_Pago",
  Pacific_Palau = "Pacific/Palau",
  Pacific_Pitcairn = "Pacific/Pitcairn",
  Pacific_Ponape = "Pacific/Ponape",
  Pacific_Port_Moresby = "Pacific/Port_Moresby",
  Pacific_Rarotonga = "Pacific/Rarotonga",
  Pacific_Saipan = "Pacific/Saipan",
  Pacific_Tahiti = "Pacific/Tahiti",
  Pacific_Tarawa = "Pacific/Tarawa",
  Pacific_Tongatapu = "Pacific/Tongatapu",
  Pacific_Truk = "Pacific/Truk",
  Pacific_Wake = "Pacific/Wake",
  Pacific_Wallis = "Pacific/Wallis",
}

export enum Country {
  Andorra = "Andorra",
  United_Arab_Emirates = "United Arab Emirates",
  Afghanistan = "Afghanistan",
  Antigua_And_Barbuda = "Antigua and Barbuda",
  Anguilla = "Anguilla",
  Albania = "Albania",
  Armenia = "Armenia",
  Angola = "Angola",
  Antarctica = "Antarctica",
  Argentina = "Argentina",
  American_Samoa = "American Samoa",
  Austria = "Austria",
  Australia = "Australia",
  Aruba = "Aruba",
  Aland = "Aland",
  Azerbaijan = "Azerbaijan",
  Bosnia_And_Herzegovina = "Bosnia and Herzegovina",
  Barbados = "Barbados",
  Bangladesh = "Bangladesh",
  Belgium = "Belgium",
  Burkina_Faso = "Burkina Faso",
  Bulgaria = "Bulgaria",
  Bahrain = "Bahrain",
  Burundi = "Burundi",
  Benin = "Benin",
  Saint_Barthelemy = "Saint Barthelemy",
  Bermuda = "Bermuda",
  Brunei = "Brunei",
  Bolivia = "Bolivia",
  Bonaire = "Bonaire",
  Brazil = "Brazil",
  Bahamas = "Bahamas",
  Bhutan = "Bhutan",
  Bouvet_Island = "Bouvet Island",
  Botswana = "Botswana",
  Belarus = "Belarus",
  Belize = "Belize",
  Canada = "Canada",
  "Cocos_(Keeling)_Islands" = "Cocos (Keeling) Islands",
  Democratic_Republic_Of_The_Congo = "Democratic Republic of the Congo",
  Central_African_Republic = "Central African Republic",
  Republic_Of_The_Congo = "Republic of the Congo",
  Switzerland = "Switzerland",
  Ivory_Coast = "Ivory Coast",
  Cook_Islands = "Cook Islands",
  Chile = "Chile",
  Cameroon = "Cameroon",
  China = "China",
  Colombia = "Colombia",
  Costa_Rica = "Costa Rica",
  Cuba = "Cuba",
  Cape_Verde = "Cape Verde",
  Curacao = "Curacao",
  Christmas_Island = "Christmas Island",
  Cyprus = "Cyprus",
  Czech_Republic = "Czech Republic",
  Germany = "Germany",
  Djibouti = "Djibouti",
  Denmark = "Denmark",
  Dominica = "Dominica",
  Dominican_Republic = "Dominican Republic",
  Algeria = "Algeria",
  Ecuador = "Ecuador",
  Estonia = "Estonia",
  Egypt = "Egypt",
  Western_Sahara = "Western Sahara",
  Eritrea = "Eritrea",
  England = "England",
  Spain = "Spain",
  Ethiopia = "Ethiopia",
  Finland = "Finland",
  Fiji = "Fiji",
  Falkland_Islands = "Falkland Islands",
  Micronesia = "Micronesia",
  Faroe_Islands = "Faroe Islands",
  France = "France",
  Gabon = "Gabon",
  United_Kingdom = "United Kingdom",
  Grenada = "Grenada",
  Georgia = "Georgia",
  French_Guiana = "French Guiana",
  Guernsey = "Guernsey",
  Ghana = "Ghana",
  Gibraltar = "Gibraltar",
  Greenland = "Greenland",
  Gambia = "Gambia",
  Guinea = "Guinea",
  Guadeloupe = "Guadeloupe",
  Equatorial_Guinea = "Equatorial Guinea",
  Greece = "Greece",
  South_Georgia_And_The_South_Sandwich_Islands = "South Georgia and the South Sandwich Islands",
  Guatemala = "Guatemala",
  Guam = "Guam",
  "Guinea-Bissau" = "Guinea-Bissau",
  Guyana = "Guyana",
  Hong_Kong = "Hong Kong",
  Heard_Island_And_Mcdonald_Islands = "Heard Island and McDonald Islands",
  Honduras = "Honduras",
  Croatia = "Croatia",
  Haiti = "Haiti",
  Hungary = "Hungary",
  Indonesia = "Indonesia",
  Ireland = "Ireland",
  Israel = "Israel",
  Isle_Of_Man = "Isle of Man",
  India = "India",
  British_Indian_Ocean_Territory = "British Indian Ocean Territory",
  Iraq = "Iraq",
  Iran = "Iran",
  Iceland = "Iceland",
  Italy = "Italy",
  Jersey = "Jersey",
  Jamaica = "Jamaica",
  Jordan = "Jordan",
  Japan = "Japan",
  Kenya = "Kenya",
  Kyrgyzstan = "Kyrgyzstan",
  Cambodia = "Cambodia",
  Kiribati = "Kiribati",
  Comoros = "Comoros",
  Saint_Kitts_And_Nevis = "Saint Kitts and Nevis",
  North_Korea = "North Korea",
  South_Korea = "South Korea",
  Kuwait = "Kuwait",
  Cayman_Islands = "Cayman Islands",
  Kazakhstan = "Kazakhstan",
  Laos = "Laos",
  Lebanon = "Lebanon",
  Saint_Lucia = "Saint Lucia",
  Liechtenstein = "Liechtenstein",
  Sri_Lanka = "Sri Lanka",
  Liberia = "Liberia",
  Lesotho = "Lesotho",
  Lithuania = "Lithuania",
  Luxembourg = "Luxembourg",
  Latvia = "Latvia",
  Libya = "Libya",
  Morocco = "Morocco",
  Monaco = "Monaco",
  Moldova = "Moldova",
  Montenegro = "Montenegro",
  Saint_Martin = "Saint Martin",
  Madagascar = "Madagascar",
  Marshall_Islands = "Marshall Islands",
  Macedonia = "Macedonia",
  Mali = "Mali",
  Myanmar = "Myanmar",
  Mongolia = "Mongolia",
  Macau = "Macau",
  Northern_Mariana_Islands = "Northern Mariana Islands",
  Martinique = "Martinique",
  Mauritania = "Mauritania",
  Montserrat = "Montserrat",
  Malta = "Malta",
  Mauritius = "Mauritius",
  Maldives = "Maldives",
  Malawi = "Malawi",
  Mexico = "Mexico",
  Malaysia = "Malaysia",
  Mozambique = "Mozambique",
  Namibia = "Namibia",
  New_Caledonia = "New Caledonia",
  Niger = "Niger",
  Norfolk_Island = "Norfolk Island",
  Nigeria = "Nigeria",
  Nicaragua = "Nicaragua",
  Netherlands = "Netherlands",
  Netherlands_Antilles = "Netherlands Antilles",
  Northern_Cyprus = "Northern Cyprus",
  Norway = "Norway",
  Nepal = "Nepal",
  Nauru = "Nauru",
  Niue = "Niue",
  New_Zealand = "New Zealand",
  Northern_Ireland = "Northern Ireland",
  Oman = "Oman",
  Panama = "Panama",
  Peru = "Peru",
  French_Polynesia = "French Polynesia",
  Papua_New_Guinea = "Papua New Guinea",
  Philippines = "Philippines",
  Pakistan = "Pakistan",
  Poland = "Poland",
  Saint_Pierre_And_Miquelon = "Saint Pierre and Miquelon",
  Pitcairn_Islands = "Pitcairn Islands",
  Puerto_Rico = "Puerto Rico",
  Palestine = "Palestine",
  Portugal = "Portugal",
  Palau = "Palau",
  Paraguay = "Paraguay",
  Qatar = "Qatar",
  Reunion = "Reunion",
  Romania = "Romania",
  Serbia = "Serbia",
  Russia = "Russia",
  Rwanda = "Rwanda",
  Saudi_Arabia = "Saudi Arabia",
  Solomon_Islands = "Solomon Islands",
  Seychelles = "Seychelles",
  Sudan = "Sudan",
  Sweden = "Sweden",
  Singapore = "Singapore",
  Saint_Helena = "Saint Helena",
  Slovenia = "Slovenia",
  Svalbard_And_Jan_Mayen = "Svalbard and Jan Mayen",
  Slovakia = "Slovakia",
  Sierra_Leone = "Sierra Leone",
  San_Marino = "San Marino",
  Senegal = "Senegal",
  Somalia = "Somalia",
  Suriname = "Suriname",
  South_Sudan = "South Sudan",
  Sao_Tome_And_Principe = "Sao Tome and Principe",
  El_Salvador = "El Salvador",
  Sint_Maarten = "Sint Maarten",
  Syria = "Syria",
  Scotland = "Scotland",
  Eswatini = "Eswatini",
  Turks_And_Caicos_Islands = "Turks and Caicos Islands",
  Chad = "Chad",
  French_Southern_Territories = "French Southern Territories",
  Togo = "Togo",
  Thailand = "Thailand",
  Tajikistan = "Tajikistan",
  Tokelau = "Tokelau",
  East_Timor = "East Timor",
  Turkmenistan = "Turkmenistan",
  Tunisia = "Tunisia",
  Tonga = "Tonga",
  Turkey = "Turkey",
  Trinidad_And_Tobago = "Trinidad and Tobago",
  Tuvalu = "Tuvalu",
  Taiwan = "Taiwan",
  Tanzania = "Tanzania",
  Ukraine = "Ukraine",
  Uganda = "Uganda",
  "U.S._Minor_Outlying_Islands" = "U.S. Minor Outlying Islands",
  United_States = "United States",
  Uruguay = "Uruguay",
  Uzbekistan = "Uzbekistan",
  Vatican_City = "Vatican City",
  Saint_Vincent_And_The_Grenadines = "Saint Vincent and the Grenadines",
  Venezuela = "Venezuela",
  British_Virgin_Islands = "British Virgin Islands",
  "U.S._Virgin_Islands" = "U.S. Virgin Islands",
  Vietnam = "Vietnam",
  Vanuatu = "Vanuatu",
  Wallis_And_Futuna = "Wallis and Futuna",
  Wales = "Wales",
  Samoa = "Samoa",
  Kosovo = "Kosovo",
  Yemen = "Yemen",
  Mayotte = "Mayotte",
  South_Africa = "South Africa",
  Zambia = "Zambia",
  Zimbabwe = "Zimbabwe",
}

export enum DateFormat {
  MMMMDDYYYY = "MMM dd, yyyy",
  DDMMMMYYYY = "dd MMM, yyyy",
  YYYYMMMMDD = "yyyy, MMM dd",
  MMDDYYYY = "MM/dd/yyyy",
  DDMMYYYY = "dd/MM/yyyy",
  YYYYMMDD = "yyyy/MM/dd",
  DDMMYY = "dd/MM/yy",
}

export enum Language {
  EN = "en",
  JA = "ja",
  AR = "ar",
  ZH_HANT = "zh-Hant",
  ZH_HANS = "zh-Hans",
}

export enum NumberFormat {
  Comma = "#,###.#",
  Dot = "#.###,#",
  Space = "# ###.#",
  Apostrophe = "#'###.#",
}

export type SearchFilterResult = {
  name: string[];
  subtype: string[];
  value: {
    minimum: number;
    maximum: number;
  };
  purchaseAt: {
    minimum: Date;
    maximum: Date;
  };
  locationId: string[];
  masterVarietal: string[];
  artStyle: string[];
  country: string[];
  variety: string[];
  brand: string[];
  roomId: string[];
  bottlePrice: {
    minimum: number;
    maximum: number;
  };
};

export type PropertyAssets = {
  fireId: string;
  category: string;
  subType: string;
  purchaseId?: string;
  bottleId?: string;
};

export type SearchResult = {
  fireId: string;
  category: string;
  subType: string;
  name?: string;
  mainImage?: string;
  purchases?: {
    fireId: string;
  }[];
};

export type ActualAssetType = Exclude<
  AssetType,
  AssetType.BankOrInstitution | AssetType.WinePurchases
>;

export interface BackupCode {
  version: number;
  codes: string[];
}

export interface BackupCodeEncrypted {
  encrypted: string;
}
