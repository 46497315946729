import { CoreFirestore, WithFieldValue } from "../../../coreFirebase";
import {
  Amount,
  AssetType,
  AssetV2,
  compareGroupUpdate,
  Attachment,
  Currency,
  MultiCurrencyAmount,
} from "../common";
import { EncryptedType, RequireEncryptionFields } from "../../encryption/utils";
import { ErrorDataOutDated, DataPoisoned, InvalidInput } from "../error";
import {
  OptionalSimpleTypeKeysOf,
  SimpleTypeKeysOf,
  UpdateObject,
  buildObjectUpdate,
  optionalDateEqual,
  addDecimal,
  subDecimal,
  validateValueInEnum,
  deepCopy,
} from "../../utils";
import {
  Account,
  AccountState,
  AccountTransaction,
  Category,
  Command,
  Event,
  ToTagPairFunction,
} from "../cashAndBanking";
import {
  SavingAccountTypeVersion,
  VersionedType,
  VersionedTypeString,
  validateTypeUpToDate,
} from "../typeVersion";

export enum InterestFrequency {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Annually = "Annually",
}

export interface SavingAccount extends Account.Base {
  "@type": VersionedTypeString<VersionedType.SavingAccount, 2>;
  subtype: Account.Type.SavingAccount;
  //   @Encrypted
  accountNumber?: string;
  //#NOTE do not use value, it is not computed in saving accounts

  subAccounts: SavingAccount.SubAccount[];
  accountCurrency: Currency;
  interestFrequency: InterestFrequency;
  accountType: Account.AccountType;
  sortCode?: string;
  // @Encrypted
  SWIFT_BIC?: string;
  openingDate?: Date;
}
export namespace SavingAccount {
  export function assureVersion(
    input: SavingAccount | Encrypted,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      SavingAccountTypeVersion,
      errorOnCoreOutDated
    );
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.SavingAccount);
  }

  export interface SubAccount {
    id: string;
    balance: Amount;
    isDefault?: boolean;

    annualInterestRate: number;
  }
  export function SubAccountEqual(a: SubAccount, b: SubAccount): boolean {
    return (
      a.id === b.id &&
      Amount.equal(a.balance, b.balance) &&
      a.isDefault === b.isDefault &&
      a.annualInterestRate === b.annualInterestRate
    );
  }

  export type Create = Pick<
    SavingAccount,
    | "id"
    | "name"
    | "subtype"
    | "country"
    | "institution"
    | "accountType"
    | "subAccounts"
    | "accountNumber"
    | "SWIFT_BIC"
    | "sortCode"
    | "openingDate"
    | "notes"
    | "groupIds"
    | "interestFrequency"
    | "extSource"
    | "extSourceId"
    | "extId"
    | "attachments"
  >;
  export type Update = Pick<
    SavingAccount,
    | "name"
    | "country"
    | "institution"
    | "subAccounts"
    | "interestFrequency"
    | "openingDate"
    | "accountNumber"
    | "SWIFT_BIC"
    | "sortCode"
    | "notes"
    | "groupIds"
    | "attachments"
  >;
  export type UpdateEncrypted = RequireEncryptionFields<
    EncryptedType<Update, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;
  export type EncryptedKeys =
    | AssetV2.EncryptedKeys
    | "accountNumber"
    | "SWIFT_BIC";
  export type Encrypted = RequireEncryptionFields<
    EncryptedType<SavingAccount, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;
  export type EncryptedPart = Pick<SavingAccount, EncryptedKeys>;

  export function fromCreate(from: Create, ownerId: string): SavingAccount {
    if (from.accountType === Account.AccountType.SingleCurrency) {
      from.subAccounts[0].id = from.id;
    }
    const data: WithFieldValue<SavingAccount> = {
      ...from,
      version: 0,
      ownerId,
      createAt: CoreFirestore.serverTimestamp(),
      updateAt: CoreFirestore.serverTimestamp(),
      assetType: AssetType.CashAndBanking,
      accountCurrency:
        from.accountType === Account.AccountType.SingleCurrency
          ? from.subAccounts[0].balance.currency
          : from.subAccounts.find((s) => s.isDefault)!.balance.currency,
      value: <any>undefined,
      "@type": SavingAccountTypeVersion,
    };
    return data as SavingAccount;
  }

  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<Update>[] = [
    "name",
    "institution",
    "interestFrequency",
  ];
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<Update>[] = [
    "country",
    "accountNumber",
    "SWIFT_BIC",
    "sortCode",
    "notes",
  ];
  export function intoUpdate(
    current: SavingAccount,
    update: Update
  ): {
    updates: UpdateObject<Update>;
    metadata: {
      addedToGroup: AssetV2["groupIds"];
      removedFromGroup: AssetV2["groupIds"];
    };
  } {
    const metadata: any = {};
    const baseUpdateFields = buildObjectUpdate(
      current,
      update,
      NonOptionalSimpleTypeUpdatableKeys,
      OptionalSimpleTypeUpdatableKeys
    );

    if (!optionalDateEqual(current.openingDate, update.openingDate))
      if (update.openingDate) baseUpdateFields.openingDate = update.openingDate;
      else baseUpdateFields.openingDate = null;

    let isSubAccountUpdated = false;
    if (current.subAccounts.length == update.subAccounts.length) {
      for (let i = 0; i < current.subAccounts.length; i++) {
        if (!SubAccountEqual(current.subAccounts[i], update.subAccounts[i])) {
          isSubAccountUpdated = true;
          break;
        }
      }
    } else {
      isSubAccountUpdated = true;
    }

    metadata.shouldEncryptSubAccount = isSubAccountUpdated;
    if (isSubAccountUpdated) baseUpdateFields.subAccounts = update.subAccounts;

    const { fieldUpdate: groupIdUpdate, groupChanges } = compareGroupUpdate(
      current.groupIds,
      update.groupIds
    );
    if (groupIdUpdate !== undefined) {
      baseUpdateFields.groupIds = groupIdUpdate;
    }
    if (groupChanges.addedToGroup)
      metadata.addedToGroup = groupChanges.addedToGroup;
    if (groupChanges.removedFromGroup)
      metadata.removedFromGroup = groupChanges.removedFromGroup;
    const { attachments, newImages } = Attachment.compareUpdate(
      current,
      update
    );
    if (newImages.length > 0) metadata.newImages = newImages;
    if (attachments !== undefined) baseUpdateFields.attachments = attachments;

    return { updates: baseUpdateFields, metadata };
  }

  export function validateEncryptedPart(
    data: UpdateObject<EncryptedPart> & {
      attachments?: Attachment.EncryptedPart[];
    },
    _isCreate: boolean = false
  ) {
    if (data.attachments) {
      data.attachments.forEach((attachment) =>
        Attachment.validateEncryptedPart(attachment)
      );
    }
  }

  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean
  ) {
    if (isCreate || data.subAccounts) {
      data.subAccounts!.forEach((subAccount) => {
        Amount.validate("balance", subAccount.balance);
        if (subAccount.annualInterestRate < 0) {
          throw new InvalidInput("Annual interest rate cannot be negative");
        }
        if (subAccount.balance.value < 0) {
          throw new InvalidInput("Balance cannot be negative");
        }
      });
    }
    if (
      (isCreate || data.interestFrequency) &&
      !validateValueInEnum(data.interestFrequency!, InterestFrequency)
    ) {
      throw new InvalidInput("Invalid interest frequency");
    }
    if (
      (isCreate || data.accountType) &&
      !validateValueInEnum(data.accountType!, Account.AccountType)
    ) {
      throw new InvalidInput("Invalid account type");
    }
  }

  export function handle(
    state: AccountState<Encrypted>,
    command: Command,
    toTagPair: ToTagPairFunction<Encrypted>
  ): Event[] {
    const events: Event[] = [];
    switch (command.kind) {
      case Command.Kind.CreateAsset:
        {
          const asset = command.asset as Encrypted;
          events.push({
            kind: Event.Kind.AssetCreated,
            executerId: command.executerId,
            asset: command.asset,
            summaryData: [
              {
                prevOwnedValue: {},
                currOwnedValue: {},
                prevAssetNumber: 0,
                currAssetNumber: 1,
                prevItemNumber: 0,
                currItemNumber: asset.subAccounts.length,
                currTags: toTagPair(asset),
              },
            ],
          });
          if (asset.groupIds && asset.groupIds.length > 0) {
            events.push({
              kind: Event.Kind.GroupsUpdated,
              executerId: command.executerId,
              addIds: asset.groupIds,
              removedIds: [],
            });
          }

          if (asset.subAccounts.length === 0)
            throw new InvalidInput("subAccount cannot be empty");
          if (
            state.account.accountType === Account.AccountType.SingleCurrency
          ) {
            if (asset.subAccounts.length !== 1) {
              throw new InvalidInput(
                "Single currency account has multiple sub accounts"
              );
            }
            (<Encrypted>command.asset).accountCurrency =
              asset.subAccounts[0].balance.currency;
          }

          let prevOwnedValue: MultiCurrencyAmount = {};
          let foundDefault = false;
          asset.subAccounts.forEach((subAccount) => {
            if (subAccount.isDefault) {
              if (foundDefault)
                throw new InvalidInput("Multiple default sub accounts");
              else {
                (<Encrypted>command.asset).accountCurrency =
                  subAccount.balance.currency;
                foundDefault = true;
              }
            }
            const txId = subAccount.id;
            const txData = AccountTransaction.systemAccountCreation(
              asset.subtype,
              txId,
              asset.id,
              txId,
              command.executerId,
              subAccount.balance,
              //#HACK nothing encrypted, and this Transaction cannot update, the decryption will be skipped
              ""
            );
            const currOwnedValue = MultiCurrencyAmount.add(
              { ...prevOwnedValue },
              subAccount.balance
            );
            events.push({
              kind: Event.Kind.TransactionAdded,
              executerId: command.executerId,
              parentId: asset.id,
              id: txId,
              data: txData,
              subAccountId: txId,
              valueChange: txData.amount!,
              summaryData: [
                {
                  prevOwnedValue: { ...prevOwnedValue },
                  currOwnedValue: { ...currOwnedValue },
                  prevAssetNumber: 1,
                  currAssetNumber: 1,
                  prevItemNumber: asset.subAccounts.length,
                  currItemNumber: asset.subAccounts.length,
                  prevTags: toTagPair(asset),
                  currTags: toTagPair(asset),
                },
              ],
            });
            prevOwnedValue = { ...currOwnedValue };
            subAccount.balance.value = 0;
          });
        }
        break;
      case Command.Kind.UpdateAsset:
        {
          if (state.account.closedWith) {
            throw new InvalidInput("Account is already closed");
          }
          const { executerId, asset, addedToGroup, removedFromGroup } = command;
          const updateAsset = <UpdateObject<UpdateEncrypted>>asset;
          const maybeUpdateSubAccount = updateAsset.subAccounts;
          const assetUpdated: Event.AssetUpdated = {
            executerId,
            kind: Event.Kind.AssetUpdated,
            asset,
            previous: deepCopy(state.account),
            current: asset,
          };
          let prevOwnedValue = MultiCurrencyAmount.fromAmounts(
            ...state.account.subAccounts.map((s) => s.balance)
          );
          let prevTags = toTagPair(state.account);
          if (maybeUpdateSubAccount || updateAsset.institution) {
            const currTags = toTagPair(updateAsset, state.account);
            assetUpdated.summaryData = [
              {
                prevOwnedValue: { ...prevOwnedValue },
                currOwnedValue: { ...prevOwnedValue },
                prevAssetNumber: 1,
                currAssetNumber: 1,
                prevItemNumber: state.account.subAccounts.length,
                currItemNumber:
                  maybeUpdateSubAccount?.length ||
                  state.account.subAccounts.length,
                prevTags: deepCopy(prevTags),
                currTags: deepCopy(currTags),
              },
            ];
            if (updateAsset.institution) prevTags = deepCopy(currTags);
          }
          events.push(assetUpdated);

          if (addedToGroup || removedFromGroup) {
            events.push({
              executerId,
              kind: Event.Kind.GroupsUpdated,
              addIds: addedToGroup ?? [],
              removedIds: removedFromGroup ?? [],
            });
          }
          if (maybeUpdateSubAccount) {
            if (maybeUpdateSubAccount.length == 0)
              throw new InvalidInput("updated subAccount cannot be empty");
            else if (
              maybeUpdateSubAccount.length > 1 &&
              state.account.accountType == Account.AccountType.SingleCurrency
            )
              throw new InvalidInput(
                "SingleCurrency cannot have multi subAccount"
              );
            maybeUpdateSubAccount.forEach((subAccount) => {
              if (
                !state.account.subAccounts.some((s) => s.id === subAccount.id)
              ) {
                const txId = subAccount.id;
                const txData = AccountTransaction.systemAccountCreation(
                  state.account.subtype,
                  txId,
                  state.account.id,
                  txId,
                  command.executerId,
                  subAccount.balance,
                  //#HACK nothing encrypted, and this Transaction cannot update, the decryption will be skipped
                  ""
                );
                const currOwnedValue = MultiCurrencyAmount.add(
                  { ...prevOwnedValue },
                  subAccount.balance
                );
                events.push({
                  kind: Event.Kind.TransactionAdded,
                  executerId: command.executerId,
                  parentId: state.account.id,
                  id: txId,
                  data: txData,
                  subAccountId: txId,
                  valueChange: txData.amount!,
                  summaryData: [
                    {
                      prevOwnedValue: { ...prevOwnedValue },
                      currOwnedValue: { ...currOwnedValue },
                      prevAssetNumber: 1,
                      currAssetNumber: 1,
                      prevItemNumber: maybeUpdateSubAccount.length,
                      currItemNumber: maybeUpdateSubAccount.length,
                      prevTags: deepCopy(prevTags),
                      currTags: deepCopy(prevTags),
                    },
                  ],
                });
                prevOwnedValue = { ...currOwnedValue };
                subAccount.balance.value = 0;
              }
            });
          }
        }
        break;
      case Command.Kind.CloseAsset:
        if (state.account.subAccounts.some((s) => s.balance.value !== 0)) {
          throw new InvalidInput("Account balance is not zero");
        }
        events.push({
          kind: Event.Kind.AccountClosed,
          executerId: command.executerId,
          summaryData: [
            {
              prevOwnedValue: {},
              currOwnedValue: {},
              prevAssetNumber: 1,
              currAssetNumber: 0,
              prevItemNumber: state.account.subAccounts.length,
              currItemNumber: 0,
              prevTags: toTagPair(state.account),
              currTags: [],
            },
          ],
        });
        break;

      case Command.Kind.AddTransaction:
        if (state.account.closedWith) {
          throw new InvalidInput("Account is already closed");
        }
        if (state.account.extId || state.account.extSource) {
          throw new InvalidInput("CreditCard account is from external source");
        }
        const prevOwnedValue = MultiCurrencyAmount.fromAmounts(
          ...state.account.subAccounts.map((s) => s.balance)
        );
        const valueChange = AccountTransaction.getNumericSignedValue(
          command.data
        );
        //#NOTE value check, if required
        events.push({
          kind: Event.Kind.TransactionAdded,
          executerId: command.executerId,
          parentId: state.account.id,
          id: command.id,
          data: command.data,
          subAccountId: command.data.subAccountId,
          valueChange: { ...valueChange },
          summaryData: [
            {
              prevOwnedValue: { ...prevOwnedValue },
              currOwnedValue: MultiCurrencyAmount.add(
                { ...prevOwnedValue },
                valueChange
              ),
              prevAssetNumber: 1,
              currAssetNumber: 1,
              prevItemNumber: state.account.subAccounts.length,
              currItemNumber: state.account.subAccounts.length,
              prevTags: toTagPair(state.account),
              currTags: toTagPair(state.account),
            },
          ],
        });
        break;
      case Command.Kind.UpdateTransaction:
        {
          if (state.account.closedWith) {
            throw new InvalidInput("Account is already closed");
          }
          if (state.account.extId || state.account.extSource) {
            throw new InvalidInput(
              "CreditCard account is from external source"
            );
          }
          const currentTx = state.transactions[command.id];
          if (!currentTx) {
            throw new InvalidInput("Transaction not found");
          }
          if (currentTx.category === Category.SystemAccountCreation) {
            throw new InvalidInput(
              "Cannot update system account creation transaction"
            );
          }
          const prevOwnedValue = MultiCurrencyAmount.fromAmounts(
            ...state.account.subAccounts.map((s) => s.balance)
          );
          const valueChange = AccountTransaction.calculateValueChange(
            currentTx,
            command.update
          );
          events.push({
            kind: Event.Kind.TransactionUpdated,
            executerId: command.executerId,
            parentId: state.account.id,
            id: command.id,
            update: command.update,
            subAccountId: currentTx.subAccountId,
            valueChange: { ...valueChange },
            summaryData: [
              {
                prevOwnedValue: { ...prevOwnedValue },
                currOwnedValue: MultiCurrencyAmount.add(
                  { ...prevOwnedValue },
                  valueChange
                ),
                prevAssetNumber: 1,
                currAssetNumber: 1,
                prevItemNumber: state.account.subAccounts.length,
                currItemNumber: state.account.subAccounts.length,
                prevTags: toTagPair(state.account),
                currTags: toTagPair(state.account),
              },
            ],
          });
        }
        break;
      case Command.Kind.DeleteTransaction:
        {
          if (state.account.closedWith) {
            throw new InvalidInput("Account is already closed");
          }
          if (state.account.extId || state.account.extSource) {
            throw new InvalidInput(
              "CreditCard account is from external source"
            );
          }
          const currentTx = state.transactions[command.id];
          if (!currentTx) {
            throw new InvalidInput("Transaction not found");
          }
          if (currentTx.category === Category.SystemAccountCreation) {
            throw new InvalidInput(
              "Cannot update system account creation transaction"
            );
          }
          const prevOwnedValue = MultiCurrencyAmount.fromAmounts(
            ...state.account.subAccounts.map((s) => s.balance)
          );
          const valueChange = Amount.toNegative(
            AccountTransaction.getNumericSignedValue(currentTx)
          );
          events.push({
            kind: Event.Kind.TransactionDeleted,
            executerId: command.executerId,
            parentId: state.account.id,
            id: command.id,
            subAccountId: currentTx.subAccountId,
            valueChange: { ...valueChange },
            summaryData: [
              {
                prevOwnedValue: { ...prevOwnedValue },
                currOwnedValue: MultiCurrencyAmount.add(
                  { ...prevOwnedValue },
                  valueChange
                ),
                prevAssetNumber: 1,
                currAssetNumber: 1,
                prevItemNumber: state.account.subAccounts.length,
                currItemNumber: state.account.subAccounts.length,
                prevTags: toTagPair(state.account),
                currTags: toTagPair(state.account),
              },
            ],
          });
        }
        break;

      case Command.Kind.OverwriteValue: {
        if (
          state.account.extId === undefined ||
          state.account.extSource === undefined
        ) {
          throw new InvalidInput("Account is not from external source");
        }
        if (state.account.accountType === Account.AccountType.MultiCurrency) {
          throw new DataPoisoned(
            `found multi currency account from ${state.account.extSource} ${state.account.extId}`
          );
        }
        const subAccount = state.account.subAccounts[0];
        if (subAccount.balance.currency !== command.primaryValue.currency) {
          throw new InvalidInput("Currency mismatch");
        }
        const event: Event.ValueUpdated = {
          kind: Event.Kind.ValueUpdated,
          executerId: command.executerId,
          valueChange: {},
        };
        event.subAccountValues = [
          {
            id: subAccount.id,
            value: command.primaryValue,
          },
        ];
        event.valueChange[subAccount.balance.currency] = subDecimal(
          command.primaryValue.value,
          subAccount.balance.value
        );
        event.summaryData = [
          {
            prevOwnedValue: MultiCurrencyAmount.fromAmounts(subAccount.balance),
            currOwnedValue: MultiCurrencyAmount.fromAmounts(
              command.primaryValue
            ),
            prevAssetNumber: 1,
            currAssetNumber: 1,
            prevItemNumber: 1,
            currItemNumber: 1,
            prevTags: toTagPair(state.account),
            currTags: toTagPair(state.account),
          },
        ];

        events.push(event);
        break;
      }
      case Command.Kind.DeleteAsset:
        if (state.account.closedWith) {
          throw new InvalidInput("Account is already closed");
        }
        events.push({
          kind: Event.Kind.AssetDeleted,
          executerId: command.executerId,
          summaryData: [
            {
              prevOwnedValue: MultiCurrencyAmount.fromAmounts(
                ...state.account.subAccounts.map((s) => s.balance)
              ),
              currOwnedValue: {},
              prevAssetNumber: 1,
              currAssetNumber: 0,
              prevItemNumber: state.account.subAccounts.length,
              currItemNumber: 0,
              prevTags: toTagPair(state.account),
              currTags: [],
            },
          ],
        });
        break;
      default:
        throw new Error("unreachable");
    }
    return events;
  }

  export function updateValue(
    account: Encrypted,
    updates: Event.SubAccountUpdate[]
  ) {
    updates.forEach(({ id, value }) => {
      const subAccount = account.subAccounts.find((s) => s.id === id);
      if (!subAccount) {
        throw new DataPoisoned("Sub account not found");
      }
      if (subAccount.balance.currency !== value.currency)
        throw new InvalidInput("Currency mismatch");
      subAccount.balance.value = addDecimal(
        subAccount.balance.value,
        value.value
      );
    });
  }
}
