import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { isWithinInterval, set } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { Loader2Icon } from 'lucide-react'
import { Trans, useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { fetchProfile, userQuery } from '@/api/AccountService'
import { useAuthStore } from '@/store/authStore'
import { Button, Modal } from '@/components/base'
import { HubSpotContactForm, LiveChatForm, LiveChatValues } from '@/components/form'

export default function LiveChatModal({
  isOpen,
  setIsOpen,
  handleBackToCommonQuestions
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  handleBackToCommonQuestions: () => void
}) {
  const database = useAuthStore((state) => state.database)
  const { t } = useTranslation()
  const { data: profile } = useSWR([userQuery.profile], fetchProfile(database!))
  const [isLoading, setIsLoading] = useState(true)
  const formRef = useRef<HTMLDivElement>(null)

  const afterHour = useMemo(() => {
    const now = new Date()
    const ukTime = utcToZonedTime(now, 'Europe/London')

    const today = set(ukTime, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
    const startTime = set(today, { hours: 1, minutes: 0 }) // 1:00 AM UK time
    const endTime = set(today, { hours: 17, minutes: 30 }) // 5:30 PM UK time

    return !isWithinInterval(ukTime, { start: startTime, end: endTime })
  }, [])

  const handleFormReady = useCallback(() => {
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleBeforeUnload = () => {
        try {
          ;(window as any).Genesys('subscribe', 'MessagingService.ready', () => {
            ;(window as any).Genesys('command', 'MessagingService.clearConversation')
          })
          localStorage.removeItem('_e9166dec-4d8c-4670-bbd2-b6152c56aa85:gcmcopn')
          localStorage.removeItem('_e9166dec-4d8c-4670-bbd2-b6152c56aa85:gcmcsessionActive')
        } catch (e) {
          console.warn('failed to clear conversation:', e)
        }
      }

      window.addEventListener('beforeunload', handleBeforeUnload)

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }
    }
  }, [])

  //loading hubspot form when open modal
  useEffect(() => {
    if (isOpen) {
      setIsLoading(true)
      window.addEventListener('message', (event) => {
        if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
          handleFormReady()
        }
      })
    }

    return () => {
      window.removeEventListener('message', handleFormReady)
    }
  }, [isOpen, handleFormReady])

  const onSubmit = (data: LiveChatValues) => {
    const dataIncludeUsername = {
      username: profile?.name,
      email: profile?.email,
      category___postsale_contact_form: data.enquiryType,
      subject: data.subject,
      content: data.description,
      existingCustomer: true
    }
    if (typeof window !== 'undefined') {
      try {
        ;(window as any).Genesys('subscribe', 'Database.ready', function () {
          ;(window as any).Genesys(
            'command',
            'Database.set',
            {
              messaging: { customAttributes: dataIncludeUsername }
            },
            (data: any) => console.log('Database.set', data)
          )
        })
        ;(window as any).Genesys('subscribe', 'Launcher.ready', () => {
          try {
            ;(window as any).Genesys('command', 'Launcher.show')
            ;(window as any).Genesys('command', 'Messenger.open')
            ;(window as any).Genesys('subscribe', 'MessagingService.ready', () => {
              ;(window as any).Genesys('command', 'MessagingService.startConversation')
            })
            setIsOpen(false)
          } catch (e) {
            console.warn('Failed to subscribe to Genesys Database:', e)
          }
        })
      } catch (e) {
        console.warn('Failed to subscribe to Genesys Database:', e)
      }
    }
  }

  return (
    <>
      {isOpen && (
        <Modal className={'max-w-2xl'} onBackdropClick={() => setIsOpen(false)}>
          <Modal.Header className={'justify-end bg-primary'}>
            <Modal.CloseButton className={'text-white'} onClose={() => setIsOpen(false)} />
          </Modal.Header>
          <div className={'rounded-lg bg-white p-5 shadow-lg'}>
            <div className={'flex items-start gap-x-2'}>
              <Image src={'/images/LiveChatBotIcon.svg'} alt={'logo'} width={40} height={40} />
              <div className={'flex w-full flex-col gap-y-2.5 text-[#5F5F5F]'}>
                <div className={' w-full space-y-2.5 rounded bg-[#F2F2F2] p-3 text-sm/4'}>
                  <Trans
                    t={t}
                    i18nKey={'liveChat:WelcomeMessage'}
                    values={{
                      name: profile?.name
                    }}
                    components={[<span key={0} className={'font-bold'} />]}
                  />
                  <div className={'whitespace-pre-line leading-5'}>
                    <p>{t('liveChat:ChatAvailableTime')}</p>
                    {afterHour ? (
                      <Trans
                        t={t}
                        i18nKey={'liveChat:AfterHourMessage'}
                        components={[
                          <Button
                            key={0}
                            variant={'unstyled'}
                            className={'font-bold text-primary hover:text-primary-hover '}
                            onClick={() => {
                              setIsOpen(false)
                              handleBackToCommonQuestions()
                            }}
                          />
                        ]}
                      />
                    ) : (
                      <Trans
                        t={t}
                        i18nKey={'liveChat:WorkHourMessage'}
                        components={[
                          <Link
                            key={0}
                            className={'font-bold text-primary hover:text-primary-hover'}
                            target={'_blank'}
                            href={'https://myassets.com/terms-and-conditions/'}
                          />,
                          <Link
                            key={1}
                            className={'font-bold text-primary hover:text-primary-hover'}
                            target={'_blank'}
                            href={'https://myassets.com/privacy-policy/'}
                          />
                        ]}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={
                    'w-full rounded border-[1px] border-[#E0E0E0] bg-[#F2F2F2] p-2.5 shadow-black drop-shadow-md'
                  }
                >
                  {afterHour ? (
                    <div className={'max-h-[500px] overflow-y-auto'}>
                      {isLoading && (
                        <div className={'flex h-64 items-center justify-center'}>
                          <Loader2Icon className={'h-8 w-8 animate-spin text-primary'} />
                        </div>
                      )}
                      <div ref={formRef} className={isLoading ? 'invisible h-0' : 'visible'}>
                        <HubSpotContactForm
                          region={'eu1'}
                          portalId={'143983585'}
                          formId={'e2fc6950-4be9-4b94-b113-7e33c89c3017'}
                        />
                      </div>
                    </div>
                  ) : (
                    <LiveChatForm onSubmit={onSubmit} disabled={afterHour} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
