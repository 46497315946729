import { useCallback, useEffect, useId, useMemo, useState } from 'react'
import { addYears } from 'date-fns'
import { AlertCircleIcon, Loader2Icon } from 'lucide-react'
import {
  useController,
  useFieldArray,
  useForm,
  type Control,
  type RegisterOptions,
  type SubmitHandler
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { type ActionMeta, type InputActionMeta, type SingleValue } from 'react-select'
import useSWR, { mutate } from 'swr'

import { AttachmentKind, Currency, Period, periodValues, TimeZone } from 'core/remodel/types/common'
import { AssetType, CustomizedType } from 'core/remodel/types/enums'
import {
  GeneralInsuranceType,
  InsuranceKind,
  InsuranceStatus,
  InsuranceSubType,
  LifeInsuranceType,
  type Insurance,
  type InsuranceTarget,
  type Insured
} from 'core/remodel/types/insurance'
import {
  generalInsuranceTypeOptions,
  insuranceKindOptions,
  insuranceStatusOptions,
  lifeInsuranceTypeOptions
} from 'core/remodel/types/options'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { addCustomType, fetchCustomTypes, typeQuery } from '@/api/CommonService'
import { addContact, contactQuery, fetchContactOptions } from '@/api/ContactService'
import { fetchGroupOptions, groupQuery } from '@/api/GroupService'
import { fetchInsuredOptions, fetchInsuredSelectedOptions, insuranceQuery } from '@/api/InsuranceService'
import { fallbackCurrency } from '@/constants/preference'
import type { Option } from '@/types/common'
import { cn } from '@/utils/classnames'
import { makeOptions, startOfDayInTz } from '@/utils/formatter'
import { useDebounce } from '@/hooks/useDebounce'
import { useAuthStore } from '@/store/authStore'
import {
  Autocomplete,
  Button,
  CreatableAutocomplete,
  FormAutocomplete,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormInput,
  FormNumberInput,
  FormPercentInput,
  FormPriceInput,
  FormSelect,
  SelectItem
} from '@/components/base'
import AttachmentPanel from '@/components/AttachmentPanel'
import { AttachmentIcon, PlusIcon, StarIcon, XIcon } from '@/components/icon'
import { TabPanel, type Tab } from '@/components/TabPanel'

export type InsuranceValues = Insurance

const getDefaultValues = (
  subType: InsuranceSubType,
  currency: Currency = fallbackCurrency,
  timeZone: TimeZone
): Partial<InsuranceValues> => ({
  assetType: AssetType.Insurance,
  subtype: subType,
  // primary details
  insuranceCompany: '',
  name: '',
  insuranceType: subType === InsuranceSubType.Life ? LifeInsuranceType.TermLife : GeneralInsuranceType.Car,
  insured: [],
  region: '', // general (health)
  status: InsuranceStatus.InForce,
  personalRefNo: '',
  startDate: startOfDayInTz(new Date(), timeZone),
  endDate: addYears(startOfDayInTz(new Date(), timeZone), 1),
  coverageAmount: { currency, value: 0 },
  value: { currency, value: 0 }, // life (surrenderValue)?
  premium: { currency, value: 0 },
  frequencyOfPayment: { period: Period.Month, num: 1 },
  groupIds: [],
  notes: '',
  // attributes
  policyNumber: '',
  brokerId: '',
  specialistId: '',
  companyOrPersonal: InsuranceKind.Personal, // general
  lastPremiumDate: startOfDayInTz(new Date(), timeZone),
  premiumToDate: startOfDayInTz(new Date(), timeZone), // general
  // beneficiary
  beneficiary: [],
  // riders
  rider: [],
  // attachments
  attachments: []
})

const allCountries: {
  code: string
  label: string
  phone: string
}[] = require('core/remodel/types/countries.json')

const InsuranceRegionOptions = allCountries.map(({ label, code }) => ({
  label,
  value: code
}))

interface InsuranceFormProps {
  mode?: 'Create' | 'Edit'
  assetId: string | null
  subtype: InsuranceSubType
  values?: InsuranceValues
  onSubmit: SubmitHandler<InsuranceValues>
  onCancel: () => void
}

export function InsuranceForm({
  mode = 'Create',
  assetId,
  subtype: subType,
  values,
  onSubmit,
  onCancel
}: InsuranceFormProps) {
  const { t } = useTranslation()
  const isLife = subType === InsuranceSubType.Life
  const isGeneral = subType === InsuranceSubType.General
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const defaultValues = useMemo(
    () => getDefaultValues(subType, preferences.baseCurrency, preferences.timeZone),
    [preferences.baseCurrency, preferences.timeZone, subType]
  )
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    watch,
    reset,
    setValue
  } = useForm<InsuranceValues>({ defaultValues })
  const insuranceCompany = watch('insuranceCompany')
  const insuranceType = watch('insuranceType')
  const beneficiary = watch('beneficiary')
  const beneficiaryArray = useFieldArray({ control, name: 'beneficiary' })
  const controlledBeneficiary = beneficiaryArray.fields.map((f, i) => ({ ...f, ...beneficiary?.[i] }))
  const riderArray = useFieldArray({ control, name: 'rider' })
  const remainingBeneficiary = useMemo(() => {
    const total = controlledBeneficiary.reduce((acc, curr) => acc + curr.percent, 0)
    return 100 - total
  }, [controlledBeneficiary])
  // preferences and options
  const companySWR = useSWR([typeQuery.insuranceCompany, CustomizedType.InsuranceCompany], fetchCustomTypes(database!))
  const { data: companies, isValidating: loadingCompanies } = companySWR
  const companyOptions = companies?.map((item) => ({ label: item, value: item }))
  const policySWR = useSWR(
    [typeQuery.insurancePolicyName, CustomizedType.InsurancePolicyName],
    fetchCustomTypes(database!)
  )
  const { data: policyNames, isValidating: loadingPolicyNames } = policySWR
  const policyNameOptions = policyNames?.map((item) => ({ label: item, value: item }))
  const contactOptionsSWR = useSWR([contactQuery.options], fetchContactOptions(database!))
  const { data: contactOptions, isValidating: loadingContacts } = contactOptionsSWR
  const groupOptionsSWR = useSWR([groupQuery.options], fetchGroupOptions(database!))
  const { data: groupOptions, isValidating: loadingGroups } = groupOptionsSWR
  const periodOptions = makeOptions(periodValues, (key) => `PeriodOptions.${key}`)
  // tabs
  const tabs: Tab[] = [
    {
      key: 'primary',
      label: t('PrimaryDetails'),
      desc: t('Required'),
      icon: <StarIcon />
    },
    {
      key: 'additional',
      label: t('AdditionalDetails'),
      desc: t('Optional'),
      icon: <PlusIcon />,
      subTabs: [
        { key: 'attributes', label: t('Attributes') },
        { key: 'beneficiary', label: t('Beneficiary') },
        { key: 'riders', label: t('finances:Riders') }
      ]
    },
    {
      key: 'attachments',
      label: t('Attachments'),
      icon: <AttachmentIcon />
    }
  ]

  const beneficiaryUnselectContact = useCallback(
    (index: number) => {
      const otherBeneficiaries = [...controlledBeneficiary.slice(0, index), ...controlledBeneficiary.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherBeneficiaries?.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledBeneficiary]
  )

  const addNewContact = async (firstName: string) => await addContact(database!, { firstName })

  const handleInsuranceTypeChange = useCallback(
    (type: string) => {
      const currentInsured = watch('insured')
      if (isGeneral && currentInsured?.length) {
        setValue('insured', [])
      }
      if (isLife && type !== LifeInsuranceType.WholeOfLife) {
        setValue('value', {
          currency: preferences.baseCurrency ?? fallbackCurrency,
          value: 0
        })
      }
    },
    [isGeneral, isLife, preferences.baseCurrency, setValue, watch]
  )

  useEffect(() => {
    if (mode === 'Edit' && values !== undefined) {
      reset(values, { keepDirtyValues: true })
    }
  }, [mode, values, reset])

  return (
    <TabPanel defaultValue={tabs[0].key}>
      {/* left */}
      <TabPanel.SideNav tabs={tabs} />

      {/* right */}
      <form
        className={'flex h-[95vh] grow flex-col justify-between gap-4 md:h-[700px]'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={'grow overflow-auto px-4 pt-4'}>
          <TabPanel.Section value={'primary'}>
            <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
              <div className={'flex flex-col gap-4'}>
                {isGeneral && (
                  <FormSelect
                    control={control}
                    name={'insuranceType'}
                    label={t('Field.Type')}
                    rules={{ required: true }}
                    onChanged={(type) => handleInsuranceTypeChange(type)}
                  >
                    {generalInsuranceTypeOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {t(`finances:GeneralInsuranceType.${label}`)}
                      </SelectItem>
                    ))}
                  </FormSelect>
                )}
                <FormCreatableAutocomplete
                  control={control}
                  name={'insuranceCompany'}
                  label={t('finances:Field.InsuranceCompany')}
                  rules={{ required: true }}
                  placeholder={t('SearchOrCreate')}
                  options={companyOptions}
                  isLoading={loadingCompanies}
                  onCreate={async (name) => await addCustomType(database!, CustomizedType.InsuranceCompany, name)}
                />
                <FormCreatableAutocomplete
                  control={control}
                  name={'name'}
                  label={t('finances:Field.PolicyName')}
                  rules={{ required: true }}
                  placeholder={t('SearchOrCreate')}
                  options={policyNameOptions}
                  isLoading={loadingPolicyNames}
                  isDisabled={insuranceCompany.length === 0}
                  onCreate={async (name) => await addCustomType(database!, CustomizedType.InsurancePolicyName, name)}
                />
                {isLife && (
                  <FormSelect
                    control={control}
                    name={'insuranceType'}
                    label={t('finances:Field.InsuranceType')}
                    rules={{ required: true }}
                  >
                    {lifeInsuranceTypeOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {t(`finances:LifeInsuranceType.${label}`)}
                      </SelectItem>
                    ))}
                  </FormSelect>
                )}
                {isLife && <PersonInsured control={control} rules={{ required: true }} />}
                {isGeneral &&
                  (insuranceType === GeneralInsuranceType.Health ? (
                    <>
                      <PersonInsured control={control} rules={{ required: true }} />
                      <FormAutocomplete
                        control={control}
                        name={'region'}
                        label={t('finances:Field.Region')}
                        options={InsuranceRegionOptions}
                        placeholder={t('Search')}
                      />
                    </>
                  ) : (
                    <ItemsInsured control={control} />
                  ))}
                <FormSelect control={control} name={'status'} label={t('Field.Status')} rules={{ required: true }}>
                  {insuranceStatusOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {t(`finances:InsuranceStatus.${label}`)}
                    </SelectItem>
                  ))}
                </FormSelect>
                <FormInput
                  control={control}
                  name={'personalRefNo'}
                  label={t('Field.PersonalRefNo')}
                  placeholder={t('Field.AddRefNumber')}
                />
                {insuranceType === LifeInsuranceType.WholeOfLife && (
                  <FormAutocomplete
                    control={control}
                    name={'groupIds'}
                    label={t('Field.Groups')}
                    options={groupOptions}
                    isLoading={loadingGroups}
                    isMulti={true}
                    placeholder={t('TypeToSearch')}
                  />
                )}
              </div>
              <div className={'flex flex-col gap-4'}>
                {isLife && (
                  <>
                    <FormDatePicker
                      control={control}
                      name={'startDate'}
                      label={t('finances:Field.EffectiveDate')}
                      format={preferences.dateFormat}
                      timeZone={preferences.timeZone}
                    />
                    <FormDatePicker
                      control={control}
                      name={'endDate'}
                      label={t('finances:Field.PolicyEndDate')}
                      format={preferences.dateFormat}
                      timeZone={preferences.timeZone}
                    />
                  </>
                )}
                <FormPriceInput
                  control={control}
                  name={{ currency: 'coverageAmount.currency', value: 'coverageAmount.value' }}
                  label={t('finances:Field.CoverageAmount')}
                  rules={
                    isLife
                      ? {
                          value: {
                            required: true,
                            validate: { greaterThanZero: (value) => value > 0 }
                          }
                        }
                      : undefined
                  }
                  format={preferences.numberFormat}
                  digits={2}
                />
                {insuranceType === LifeInsuranceType.WholeOfLife && (
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'value.currency', value: 'value.value' }}
                    label={t('finances:Field.SurrenderValue')}
                    format={preferences.numberFormat}
                    digits={2}
                  />
                )}
                {isGeneral && (
                  <>
                    <FormDatePicker
                      control={control}
                      name={'startDate'}
                      label={t('finances:Field.CoveredFrom')}
                      format={preferences.dateFormat}
                      timeZone={preferences.timeZone}
                    />
                    <FormDatePicker
                      control={control}
                      name={'endDate'}
                      label={t('finances:Field.CoveredTo')}
                      format={preferences.dateFormat}
                      timeZone={preferences.timeZone}
                    />
                  </>
                )}
                <FormPriceInput
                  control={control}
                  name={{ currency: 'premium.currency', value: 'premium.value' }}
                  label={t('finances:Field.Premium')}
                  rules={
                    isLife
                      ? {
                          value: {
                            required: true,
                            validate: { greaterThanZero: (value) => value > 0 }
                          }
                        }
                      : undefined
                  }
                  format={preferences.numberFormat}
                  digits={2}
                />
                <div className={'flex flex-col gap-y-1'}>
                  <label className={'text-xs text-[#414554]'}>{t('finances:Field.FrequencyOfPayment')}</label>
                  <div className={'flex gap-x-1'}>
                    <FormNumberInput
                      control={control}
                      name={'frequencyOfPayment.num'}
                      format={preferences.numberFormat}
                      digits={2}
                    />
                    <FormSelect className={'basis-40'} control={control} name={'frequencyOfPayment.period'}>
                      {periodOptions.map(({ label, value }) => (
                        <SelectItem key={value} value={value}>
                          {label}
                        </SelectItem>
                      ))}
                    </FormSelect>
                  </div>
                </div>
                {insuranceType !== LifeInsuranceType.WholeOfLife && (
                  <FormAutocomplete
                    control={control}
                    name={'groupIds'}
                    label={t('Field.Groups')}
                    options={groupOptions}
                    isLoading={loadingGroups}
                    isMulti={true}
                    placeholder={t('TypeToSearch')}
                  />
                )}
              </div>
            </div>

            <div className={'col-span-2'}>
              <FormInput control={control} name={'notes'} label={t('Field.Notes')} type={'text'} />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.attributes'}>
            <div className={'flex flex-col gap-4'}>
              <FormInput control={control} name={'policyNumber'} label={t('finances:Field.PolicyNumber')} />
              <FormCreatableAutocomplete
                control={control}
                name={'brokerId'}
                label={t('finances:Field.Broker')}
                options={contactOptions}
                onCreate={addNewContact}
                isLoading={loadingContacts}
                placeholder={t('SearchOrCreate')}
              />
              <FormCreatableAutocomplete
                control={control}
                name={'specialistId'}
                label={t('finances:Field.Specialist')}
                options={contactOptions}
                onCreate={addNewContact}
                isLoading={loadingContacts}
                placeholder={t('SearchOrCreate')}
              />
            </div>
            <div className={'flex flex-col gap-4'}>
              {insuranceType === GeneralInsuranceType.Health && (
                <FormSelect control={control} name={'companyOrPersonal'} label={t('finances:Field.CompanyPersonal')}>
                  {insuranceKindOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {t(`finances:InsuranceKind.${label}`)}
                    </SelectItem>
                  ))}
                </FormSelect>
              )}
              <FormDatePicker
                control={control}
                name={'lastPremiumDate'}
                label={t('finances:Field.LastPremiumDate')}
                format={preferences.dateFormat}
                timeZone={preferences.timeZone}
              />
              {isGeneral && (
                <FormDatePicker
                  control={control}
                  name={'premiumToDate'}
                  label={t('finances:Field.PremiumToDate')}
                  format={preferences.dateFormat}
                  timeZone={preferences.timeZone}
                />
              )}
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.beneficiary'}>
            <div className={'col-span-2'}>
              <div className={'mb-2 flex items-center justify-between pr-9 text-sm'}>
                <span className={'text-sm'}>{t('Field.Beneficiary')}</span>
                {remainingBeneficiary === 0 && (
                  <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                )}
                {remainingBeneficiary > 0 && (
                  <span className={'text-xs text-[#d89423]'}>
                    {t('RemainingPercent', { percent: remainingBeneficiary })}
                  </span>
                )}
                {remainingBeneficiary < 0 && <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>}
              </div>
              {beneficiaryArray.fields.length > 0 && (
                <ul className={'space-y-2'}>
                  {beneficiaryArray.fields.map((beneficiary, index) => (
                    <li key={beneficiary.id} className={'flex items-center gap-x-0.5'}>
                      <FormCreatableAutocomplete
                        className={'w-full'}
                        control={control}
                        name={`beneficiary.${index}.contactId`}
                        options={beneficiaryUnselectContact(index)}
                        onCreate={addNewContact}
                        isLoading={loadingContacts}
                        placeholder={t('SearchOrCreate')}
                      />
                      <FormPercentInput
                        className={'basis-[72px]'}
                        control={control}
                        name={`beneficiary.${index}.percent`}
                      />
                      <Button
                        className={'shrink-0 rounded-full p-1 transition-colors hover:bg-grey/20'}
                        onClick={() => beneficiaryArray.remove(index)}
                      >
                        <XIcon className={'text-primary'} />
                      </Button>
                    </li>
                  ))}
                </ul>
              )}
              <Button
                className={'group justify-start gap-x-3 justify-self-start py-2'}
                onClick={() => beneficiaryArray.append({ contactId: '', percent: 0 })}
              >
                <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                  <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                </div>
                <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                  {t('AddBeneficiaries')}
                </span>
              </Button>
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.riders'}>
            <div className={'col-span-2'}>
              <Button
                className={'group justify-start gap-x-3 justify-self-start py-2'}
                onClick={() =>
                  riderArray.append({
                    name: '',
                    amount: { currency: Currency.USD, value: 0 },
                    effectiveDate: startOfDayInTz(new Date(), preferences.timeZone),
                    riderEndDate: addYears(startOfDayInTz(new Date(), preferences.timeZone), 1)
                  })
                }
              >
                <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                  <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                </div>
                <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                  {t('finances:Field.Rider')}
                </span>
              </Button>
              {riderArray.fields.length > 0 && (
                <ul className={'space-y-2'}>
                  {riderArray.fields.map((rider, index) => (
                    <li key={rider.id} className={'relative rounded border p-4'}>
                      <div className={'grid grid-cols-2 gap-4'}>
                        <div className={'flex flex-col gap-4'}>
                          <FormInput
                            control={control}
                            name={`rider.${index}.name`}
                            label={t('finances:Field.RiderName')}
                          />
                          <FormPriceInput
                            control={control}
                            name={{
                              currency: `rider.${index}.amount.currency`,
                              value: `rider.${index}.amount.value`
                            }}
                            label={t('finances:Field.CoverageAmount')}
                            format={preferences.numberFormat}
                            digits={2}
                          />
                        </div>
                        <div className={'flex flex-col gap-4'}>
                          <FormDatePicker
                            control={control}
                            name={`rider.${index}.effectiveDate`}
                            label={t('finances:Field.EffectiveDate')}
                            format={preferences.dateFormat}
                            timeZone={preferences.timeZone}
                          />
                          <FormDatePicker
                            control={control}
                            name={`rider.${index}.riderEndDate`}
                            label={t('finances:Field.RiderEndDate')}
                            format={preferences.dateFormat}
                            timeZone={preferences.timeZone}
                          />
                        </div>
                      </div>
                      <Button className={'absolute right-1 top-1'} onClick={() => riderArray.remove(index)}>
                        <XIcon className={'text-primary'} />
                      </Button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'attachments'} className={'h-full md:grid-cols-1'}>
            {assetId && (
              <AttachmentPanel
                assetId={assetId}
                control={control}
                name={{ mainImage: 'mainImage', attachments: 'attachments' }}
                widgetOptions={[AttachmentKind.PrimaryDetails]}
              />
            )}
          </TabPanel.Section>
        </div>
        <fieldset className={'flex justify-center gap-2 p-4 md:justify-end'} disabled={isSubmitting}>
          <Button
            id={'finances_add_insurance_cancel'}
            className={'min-w-[130px]'}
            variant={'outline'}
            size={'md'}
            onClick={onCancel}
          >
            {t('Cancel')}
          </Button>
          <Button
            id={'finances_add_insurance_create'}
            className={'group relative min-w-[130px]'}
            variant={'solid'}
            size={'md'}
            type={'submit'}
            disabled={!isValid}
          >
            {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
            <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
          </Button>
        </fieldset>
      </form>
    </TabPanel>
  )
}

export function InsuranceFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}

interface InsuredProps {
  className?: string
  control: Control<InsuranceValues>
  rules?: RegisterOptions<InsuranceValues, 'insured'>
}

function PersonInsured({ className, control, rules }: InsuredProps) {
  const id = useId()
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)

  const {
    field: { value, onChange, ref, ...rest },
    fieldState: { error }
  } = useController({ control, name: 'insured', rules })

  const contactOptionsSWR = useSWR([contactQuery.options], fetchContactOptions(database!))
  const { data: contactOptions, isValidating: loadingContacts } = contactOptionsSWR
  const displayOptions = useMemo(
    () => contactOptions?.filter((option) => !value?.some((v) => v.targetId === option.value)),
    [contactOptions, value]
  )

  const addNewContact = async (firstName: string) => await addContact(database!, { firstName })

  const handleChange = async (
    newValue: SingleValue<{ label: string; value: string }>,
    actionMeta: ActionMeta<{ label: string; value: string }>
  ) => {
    if (actionMeta.action === 'create-option') {
      const targetId = await addNewContact(actionMeta.option.value)
      onChange([...(value ?? []), { targetType: 'Person' as const, targetId }])
    } else if (newValue?.value) {
      onChange([...(value ?? []), { targetType: 'Person' as const, targetId: newValue.value }])
    }
  }

  const displayName = (id: string) => contactOptions?.find((option) => option.value === id)?.label ?? '-'
  const handleRemove = (id: string) => {
    const newValue = value?.filter((target) => target.targetId !== id)
    onChange(newValue)
  }

  return (
    <div className={'space-y-2'}>
      <div className={cn('grid grid-cols-1 gap-y-1', className)}>
        <label
          htmlFor={id}
          className={cn(
            'text-xs text-[#414554]',
            rules?.required !== undefined && 'after:ml-0.5 after:text-error after:content-["*"]'
          )}
        >
          {t('finances:Field.PersonInsured')}
        </label>
        <div
          className={'relative'}
          data-testId={'person-insured-creatable-autocomplete'}
          aria-label={'person insured creatable autocomplete'}
        >
          <CreatableAutocomplete
            innerRef={ref}
            inputId={id}
            instanceId={id}
            value={{ label: '', value: '' }}
            onChange={handleChange}
            components={{ IndicatorsContainer: () => null }}
            options={displayOptions}
            placeholder={t('SearchOrCreate')}
            noOptionsMessage={() => t('NoResultsFound')}
            isLoading={loadingContacts}
            isMulti={false}
            {...rest}
          />
          {error && <AlertCircleIcon className={'absolute right-0 top-0.5 mx-2 my-1.5 text-red-500'} />}
        </div>
        {error?.message && <p className={'text-xs text-red-500'}>{error.message}</p>}
      </div>
      {/* Contacts */}
      {value && value.length > 0 && (
        <ul className={'space-y-1'}>
          {value.map((target) => (
            <li key={target.targetId} className={'flex items-center justify-between'}>
              <p className={'text-sm'}>{displayName(target.targetId)}</p>
              <Button
                className={'rounded-full p-1 transition-colors hover:bg-grey/20'}
                onClick={() => handleRemove(target.targetId)}
              >
                <XIcon className={'text-primary'} size={20} />
              </Button>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

function ItemsInsured({ className, control, rules }: InsuredProps) {
  const id = useId()
  const { t } = useTranslation()
  const {
    field: { value, onChange, ref, ...rest },
    fieldState: { error }
  } = useController({ control, name: 'insured', rules })
  const database = useAuthStore((state) => state.database)
  const [keyword, setKeyword] = useState('')
  const keywordDebounced = useDebounce(keyword, 500)
  const passParams = useMemo(() => ({ limit: 30, keyword: keywordDebounced }), [keywordDebounced])
  const { data, isLoading } = useSWR([insuranceQuery.insuredOptions, passParams], fetchInsuredOptions(database!))
  const { data: selectedOptions, isLoading: loadingSelectedOptions } = useSWR(
    [insuranceQuery.insuredOptions, value ?? []],
    fetchInsuredSelectedOptions(database!)
  )
  const { assets, options } = data ?? {}
  const displayOptions = options?.filter((option) => value?.find((v) => v.targetId === option.value) === undefined)
  const displayName = (id: string) => selectedOptions?.find((option) => option.value === id)?.label ?? '-'
  const handleChange = (newValue: Option | null) => {
    if (!newValue) return
    const selectedAsset = assets?.find((asset) => asset.id === newValue.value)
    if (!selectedAsset) return

    const newInsuredItem: Insured = {
      targetId: selectedAsset.id,
      targetType: selectedAsset.assetType as InsuranceTarget
    }
    onChange([...(value ?? []), newInsuredItem])
  }
  const handleRemove = (id: string) => {
    const newValue = value?.filter((target) => target.targetId !== id)
    onChange(newValue)
  }

  const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'input-change' || actionMeta.action === 'input-blur') {
      setKeyword(newValue || '')
    }
  }

  return (
    <div className={'space-y-2'}>
      <div className={cn('grid grid-cols-1 gap-y-1', className)}>
        <label
          htmlFor={id}
          className={cn(
            'text-xs text-[#414554]',
            rules?.required !== undefined && 'after:ml-0.5 after:text-error after:content-["*"]'
          )}
        >
          {t('finances:Field.ItemsInsured')}
        </label>
        <div className={'relative'}>
          <Autocomplete
            innerRef={ref}
            inputId={id}
            instanceId={id}
            components={{ IndicatorsContainer: () => null }}
            value={{ label: '', value: '' }}
            onChange={handleChange}
            options={displayOptions}
            placeholder={t('Search')}
            noOptionsMessage={() => t('NoResultsFound')}
            isLoading={isLoading}
            isMulti={false}
            onInputChange={handleInputChange}
            {...rest}
          />
          {error && <AlertCircleIcon className={'absolute right-0 top-0.5 mx-2 my-1.5 text-red-500'} />}
        </div>
        {error?.message && <p className={'text-xs text-red-500'}>{error.message}</p>}
      </div>
      {/* Items */}
      {value && value.length > 0 && (
        <ul className={'space-y-1'}>
          {value.map((target) => (
            <li key={target.targetId} className={'flex items-center justify-between'}>
              <p className={'text-sm'}>{displayName(target.targetId)}</p>
              <Button
                className={'rounded-full p-1 transition-colors hover:bg-grey/20'}
                onClick={() => handleRemove(target.targetId)}
              >
                <XIcon className={'text-primary'} size={20} />
              </Button>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
