export enum SubscriptionPlan {
  Trial = "Trial",
  Subscribed = "Subscribed",
}

// active: Successful
// inactive: Canceled, Expired, Failed
export enum SubscriptionStatus {
  Canceled = "Canceled", // subscription is deleted at stripe
  Expired = "Expired", // subscription is paused or auto payment failed at the end of period
  Failed = "Failed", // manual payment failed or needs other actions to finish the payment
  Successful = "Successful", // payment successful
}

// initial state: subscription object is empty
export interface SubscriptionInfo {
  subscription?: {
    plan: SubscriptionPlan;
    status: SubscriptionStatus;
    // #NOTE: `expiryDate` will be used by userTaskUpdateCoordinator to determine if the user should be deleted
    expiryDate?: Date; // this will only exist after a successful trial / subscription
    delegates: {
      seats: number;
      requested: number;
      consumed: number;
    };
    // #NOTE: `updateAt` indicates the subscription update time of external subscription system
    // - For stripe, updateAt = event created time
    // Since stripe does not guarantee delivery of events in order, we store the event created time for checking.
    // Ignore the event if the event created time is earlier than the one we used to update the subscription
    updateAt?: Date;
  };
  version: number;
}

export const currentSubscriptionInfoVersion = 0;

export interface Integration {
  name: string;
  token: string;
  date: Date;
}
