import * as React from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { DayPicker, type DropdownProps } from 'react-day-picker'

import { cn } from '@/utils/classnames'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/base/Select'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

export function Calendar({
  className,
  classNames,
  fromYear = 1000,
  toYear = 2100,
  showOutsideDays = false,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      captionLayout={'dropdown-buttons'}
      fromYear={fromYear}
      toYear={toYear}
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'hidden',
        caption_dropdowns: 'flex items-center gap-x-2',
        dropdown: 'bg-transparent',
        vhidden: 'sr-only',
        nav: 'space-x-1 flex items-center',
        nav_button: 'h-7 w-7 bg-transparent inline-flex justify-center items-center p-0 opacity-50 hover:opacity-100',
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'text-center flex-1 text-sm p-0 relative first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: 'h-9 w-9 p-0 rounded-full font-normal aria-selected:opacity-100 transition-colors hover:bg-grey/20',
        day_selected: 'bg-primary text-white hover:bg-primary hover:bg-primary-hover',
        day_outside: 'text-grey opacity-50',
        day_disabled: 'text-grey opacity-50',
        ...classNames
      }}
      components={{
        IconLeft: () => <ChevronLeft className={'h-4 w-4'} />,
        IconRight: () => <ChevronRight className={'h-4 w-4'} />,
        Dropdown: ({ value, onChange, children }: DropdownProps) => {
          const options = React.Children.toArray(children) as React.ReactElement<React.HTMLProps<HTMLOptionElement>>[]
          const selected = options.find((child) => child.props.value === value)
          const handleChange = (value: string) => {
            const changeEvent = { target: { value } } as React.ChangeEvent<HTMLSelectElement>
            onChange?.(changeEvent)
          }
          return (
            <Select value={value?.toString()} onValueChange={(value) => handleChange(value)}>
              <SelectTrigger className={'pr-1.5 focus:ring-0'}>
                <SelectValue>{selected?.props?.children}</SelectValue>
              </SelectTrigger>
              <SelectContent position={'popper'} className={'max-h-80'}>
                {options.map((option, id: number) => (
                  <SelectItem key={`${option.props.value}-${id}`} value={option.props.value?.toString() ?? ''}>
                    {option.props.children}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )
        }
      }}
      {...props}
    />
  )
}
Calendar.displayName = 'Calendar'
