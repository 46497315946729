import * as React from 'react'
import { endOfDay, startOfDay } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { ar, enUS, ja } from 'date-fns/locale'
import { CalendarIcon } from 'lucide-react'
import { type DateRange } from 'react-day-picker'
import {
  useController,
  type Control,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type RegisterOptions
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DateFormat, type TimeZone } from 'core/remodel/types/common'
import { dateFormatOptions } from 'core/remodel/types/options'
import { cn } from '@/utils/classnames'
import { formatDate } from '@/utils/formatter'
import { Button, Calendar, CalendarProps, Popover, PopoverContent, PopoverTrigger } from '@/components/base'

export interface DatePickerProps {
  className?: string
  id?: string
  value?: Date
  range?: DateRange
  mode?: 'single' | 'range'
  onChange?: (date: Date) => void
  onRangeChange?: (range: DateRange) => void
  format?: DateFormat
  timeZone?: TimeZone
  disabled?: boolean
  calendarDisabled?: CalendarProps['disabled']
}

export const DatePicker = React.forwardRef<HTMLButtonElement, DatePickerProps>(
  (
    {
      className,
      id,
      mode = 'single',
      format,
      timeZone = '',
      disabled,
      value,
      onChange,
      range,
      onRangeChange,
      calendarDisabled,
      ...props
    },
    ref
  ) => {
    const { i18n } = useTranslation()
    const locale = React.useMemo(() => {
      switch (i18n.language) {
        case 'en':
          return enUS
        case 'ja':
          return ja
        case 'ar':
          return ar
        default:
          return enUS
      }
    }, [i18n.language])
    const placeholder = React.useMemo(
      () => dateFormatOptions.find(({ value }) => value === format)?.label ?? format,
      [format]
    )
    const startDate = range?.from ? formatDate(range.from, format, timeZone) : placeholder
    const endDate = range?.to ? formatDate(range.to, format, timeZone) : placeholder
    const displayValue =
      mode === 'single' ? (value ? formatDate(value, format, timeZone) : placeholder) : `${startDate} - ${endDate}`
    const selectedValue = value ? utcToZonedTime(value, timeZone) : value
    const selectedRange = range
      ? {
          from: range.from ? utcToZonedTime(range.from, timeZone) : range.from,
          to: range.to ? utcToZonedTime(range.to, timeZone) : range.to
        }
      : range

    const handleSelect = (date: Date | undefined) => {
      if (onChange && date) onChange(zonedTimeToUtc(date, timeZone))
    }
    const handleRangeSelect = (data: DateRange | undefined) => {
      if (onRangeChange && data) {
        const timezoneRange = {
          from: data.from ? zonedTimeToUtc(startOfDay(data.from), timeZone) : undefined,
          to: data.to ? zonedTimeToUtc(endOfDay(data.to), timeZone) : undefined
        }
        onRangeChange(timezoneRange)
      }
    }
    return (
      <Popover>
        <PopoverTrigger asChild={true}>
          <Button
            ref={ref}
            id={id}
            className={cn(
              'box-content h-9 justify-end gap-x-2 border border-gray-200 bg-grey-input px-3 text-sm text-text focus:border-primary',
              className
            )}
            disabled={disabled}
          >
            {displayValue}
            <CalendarIcon className={'text-primary'} />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={'w-auto p-0'}>
          {mode === 'single' ? (
            <Calendar
              mode={'single'}
              locale={locale}
              selected={selectedValue}
              onSelect={handleSelect}
              disabled={calendarDisabled}
              defaultMonth={value}
              {...props}
            />
          ) : (
            <Calendar
              mode={'range'}
              locale={locale}
              selected={selectedRange}
              onSelect={handleRangeSelect}
              disabled={calendarDisabled}
              defaultMonth={range?.from}
              {...props}
            />
          )}
        </PopoverContent>
      </Popover>
    )
  }
)
DatePicker.displayName = 'DatePicker'

interface FormDatePickerProps<Values extends FieldValues, Path extends FieldPath<Values>> {
  label?: string
  className?: string
  disabled?: boolean
  format: DateFormat | undefined
  timeZone: TimeZone | undefined
  errorClassName?: string
  onSelected?: (value: Date) => void
  calendarDisabled?: (date: Date) => boolean
  // controller props
  name: Path
  control: Control<Values>
  rules?: RegisterOptions<Values, Path>
  defaultValue?: FieldPathValue<Values, Path>
}

export function FormDatePicker<Values extends FieldValues, Path extends FieldPath<Values>>({
  label,
  className,
  name,
  control,
  rules,
  defaultValue,
  format,
  timeZone,
  disabled = false,
  errorClassName,
  onSelected,
  calendarDisabled
}: FormDatePickerProps<Values, Path>) {
  const id = React.useId()
  const {
    field,
    fieldState: { error }
  } = useController({ control, name, rules, defaultValue })
  const isRequired = rules?.required !== undefined

  const handleChange = (value: Date) => {
    field.onChange(value)
    onSelected?.(value)
  }

  return (
    <div className={cn('grid gap-y-1', className)}>
      {label && (
        <label
          htmlFor={id}
          className={cn('text-xs text-[#414554]', isRequired && 'after:ml-0.5 after:text-error after:content-["*"]')}
        >
          {label}
        </label>
      )}
      <DatePicker
        id={id}
        value={field.value}
        onChange={handleChange}
        format={format}
        timeZone={timeZone}
        disabled={disabled}
        calendarDisabled={calendarDisabled}
        data-testid={`${name}-date-picker`}
        aria-label={`${name}-date-picker`}
      />
      {error?.message && <p className={cn('text-xs text-red-500', errorClassName)}>{error.message}</p>}
    </div>
  )
}

interface FormDateRangePickerProps<Values extends FieldValues, Path extends FieldPath<Values>> {
  label: string
  className?: string
  disabled?: boolean
  format: DateFormat | undefined
  timeZone: TimeZone | undefined
  name: {
    startDate: Path
    endDate: Path
  }
  control: Control<Values>
  rules?: RegisterOptions<Values, Path>
  defaultValue?: FieldPathValue<Values, Path>
}

export function FormDateRangePicker<Values extends FieldValues, Path extends FieldPath<Values>>({
  label,
  className,
  name,
  control,
  rules,
  defaultValue,
  format,
  timeZone,
  disabled = false
}: FormDateRangePickerProps<Values, Path>) {
  const id = React.useId()
  const { field: startField } = useController({ control, name: name.startDate, rules, defaultValue })
  const { field: endField } = useController({ control, name: name.endDate, rules, defaultValue })
  const isRequired = rules?.required !== undefined

  const handleChange = (range: DateRange) => {
    startField.onChange(range.from)
    endField.onChange(range.to ?? range.from)
  }

  return (
    <div className={cn('grid gap-y-1', className)}>
      {label && (
        <label
          htmlFor={id}
          className={cn('text-xs text-[#414554]', isRequired && 'after:ml-0.5 after:text-error after:content-["*"]')}
        >
          {label}
        </label>
      )}
      <DatePicker
        id={id}
        mode={'range'}
        range={{ from: startField.value, to: endField.value }}
        onRangeChange={handleChange}
        format={format}
        timeZone={timeZone}
        disabled={disabled}
        data-testid={`${name.startDate}-date-range-picker`}
        aria-label={`${name.startDate}-date-range-picker`}
      />
    </div>
  )
}
