import { endOfMonth, startOfMonth, subMonths } from 'date-fns'
import { mutate, unstable_serialize } from 'swr'

import type { Database } from 'core/remodel/database'
import { ActionType } from 'core/remodel/types/actions/base'
import type { OtherCollectable } from 'core/remodel/types/belongings'
import type { BelongingSummary } from 'core/remodel/types/belongingSummary'
import { AssetType, AttachmentKind, Currency, LocationType } from 'core/remodel/types/common'
import { AcquisitionType } from 'core/remodel/types/common/acquisition'
import type { LocationInfo } from 'core/remodel/types/relations/locationInfo'
import { getKeyWithId } from '@/api/ActivityService'
import { AssociatedFile } from '@/api/CommonService'
import { SummaryStatistic } from '@/types/common'
import { delay } from '@/utils/delay'
import { getFullName } from '@/utils/formatter'
import { ImageSizes } from '@/utils/imageTools'
import { isFulfilled, isProperty } from '@/utils/predicate'
import type { ActionDetails } from '@/components/Actions'
import type { ValuationValues } from '@/components/form'

export const otherQuery = {
  summary: 'other-summary',
  list: 'other-list',
  info: 'other-info',
  filter: 'other-filter',
  action: 'other-action',
  location: 'location'
} as const

const mutateOtherCollectable = (id?: string) => {
  mutate((key) => Array.isArray(key) && Object.values(otherQuery).includes(key[0]))
  if (id) mutate(unstable_serialize(getKeyWithId(AssetType.OtherCollectables, id)))
}

export function fetchOtherSummary(database: Database) {
  return async ([_key, currency = database.ExRate.BaseCurrency]: [typeof otherQuery.summary, Currency?]) => {
    if (!currency) throw Error('No base currency')

    const [
      { assets, liabilities: _liabilities, netValue: _netValue, ...summary },
      { OtherCollectables: liabilities = { currency, value: 0 } },
      { subtype, brand },
      purchasedLM,
      brandNumber
    ] = await Promise.all([
      database.otherCollectable.getSyncedSummary(currency),
      database.cashAndBanking.getAssetTypeLiabilities([AssetType.OtherCollectables], currency),
      database.getFilterInfo(AssetType.OtherCollectables),
      database.getCategoryPurchaseLMCount(AssetType.OtherCollectables),
      database.getCategoryBrandCount(AssetType.OtherCollectables)
    ])

    const lastMonth = subMonths(Date.now(), 1)
    const lastMonthStart = startOfMonth(lastMonth).toISOString()
    const lastMonthEnd = endOfMonth(lastMonth).toISOString()
    const statistics: SummaryStatistic[] = [
      {
        key: 'Collectables',
        value: summary.totalNumber,
        queryString: new URLSearchParams([]).toString()
      },
      {
        key: 'Purchased',
        value: purchasedLM,
        queryString: new URLSearchParams([
          ['purchaseDateLowerBound', lastMonthStart],
          ['purchaseDateUpperBound', lastMonthEnd]
        ]).toString()
      },
      {
        key: 'Types',
        value: summary.typesNumber,
        queryString: new URLSearchParams(subtype.map((item) => ['subtype', item])).toString()
      },
      {
        key: 'Brand',
        value: brandNumber,
        queryString: new URLSearchParams(brand.map((item) => ['brand', item])).toString()
      },
      // FIXME wishlist
      {
        key: 'Wishlist',
        value: 0,
        queryString: new URLSearchParams([]).toString()
      }
    ]

    const values: Pick<BelongingSummary.Display, 'assets' | 'liabilities' | 'netValue'> = {
      assets,
      liabilities,
      netValue: { currency, value: assets.value - liabilities.value }
    }
    const subtypeItems = await Promise.all(
      summary.subtypeItems.map(async (item) => {
        const query = { limit: 1, offset: 0, subType: [item.label] } as any
        const { list } = await database.getAssetsByTypes([AssetType.OtherCollectables], query)
        const [{ id: assetId, mainImage: imageId }] = list
        return { ...item, mainImage: { assetId, imageId } }
      })
    )
    return { ...values, ...summary, subtypeItems, statistics }
  }
}

export type OtherCollectableExtra = OtherCollectable & {
  extraData?: {
    locationName: string
    roomName: string | null
  }
}

export function fetchOthers(database: Database) {
  return async ([_key, query]: [typeof otherQuery.list, Record<string, any>]) => {
    const { list: listRaw, totalCount } = await database.getAssetsByTypes([AssetType.OtherCollectables], query)

    const list = await Promise.all(
      listRaw.map(async (other): Promise<OtherCollectableExtra> => {
        try {
          const { locationType, locationId, roomId } = other.location
          let extraData: OtherCollectableExtra['extraData']
          switch (locationType) {
            case LocationType.MyProperty: {
              const property = await database.property.getById(locationId)
              const { bedroom = [], bathroom = [], otherRoom = [], carPark = [] } = property.configuration ?? {}
              const rooms = [bedroom, bathroom, otherRoom, carPark].flat()
              extraData = {
                locationName: property.name,
                roomName: roomId ? rooms.find(({ id }) => id === roomId)?.name ?? null : null
              }
              break
            }
            case LocationType.Address:
            case LocationType.NewAddress: {
              const contact = await database.Account.getContact(locationId)
              const rooms = contact.room ?? []
              extraData = {
                locationName: getFullName(contact),
                roomName: roomId ? rooms.find(({ id }) => id === roomId)?.name ?? null : null
              }
              break
            }
          }
          return { ...other, extraData }
        } catch {
          return other
        }
      })
    )

    return { list, totalCount }
  }
}

export function fetchOtherInfo(database: Database) {
  return async ([_key, id]: [typeof otherQuery.info, string]) => {
    const { acquisition: acquisitionRaw, ...asset } = await database.otherCollectable.getById(id)
    const acquisition: OtherCollectable['acquisition'] = {
      ...acquisitionRaw,
      acquisitionType: acquisitionRaw?.acquisitionType ?? AcquisitionType.Direct,
      priceAsValue: acquisitionRaw?.priceAsValue ?? false
    }
    return { ...asset, acquisition } as OtherCollectable
  }
}

export function fetchOtherFilter(database: Database) {
  return async ([_key]: [typeof otherQuery.filter]) => {
    const { subtype, value, locationId, brand, name, purchaseAt } = await database.getFilterInfo(
      AssetType.OtherCollectables
    )

    const responses = await Promise.allSettled([
      ...locationId.map((id) => database.property.getById(id)),
      ...locationId.map((id) => database.Account.getContact(id))
    ])
    const location = responses.filter(isFulfilled).map(({ value }) => ({
      label: isProperty(value) ? value.name : getFullName(value),
      value: value.id
    }))
    return { subtype, value, location, brand, name, purchaseAt }
  }
}

export function fetchOtherLocation(database: Database) {
  return async ([_key, currency = database.ExRate.BaseCurrency]: [typeof otherQuery.location, Currency?]) => {
    if (!currency) throw Error('No base currency')

    const { rate } = await database.ExRate.getExchangeRate(database.ExRate.BaseCurrency!, currency)
    const collectables = await database.otherCollectable.getAll()
    const locations = Array.from(
      new Map(
        collectables.map(({ location: { locationId, locationType } }) => [locationId, { locationId, locationType }])
      ).values()
    )

    return await Promise.all(
      locations.map(async (location) => {
        switch (location.locationType) {
          case LocationType.MyProperty: {
            const property = await database.property.getById(location.locationId)
            const assets = collectables.filter(({ location: { locationId } }) => locationId === property.id)
            return {
              item: { id: property.id, latLng: property.location.center },
              info: {
                name: property.name,
                type: location.locationType,
                address: property.location.address,
                imageId: property.mainImage,
                number: assets.length,
                value: {
                  currency: currency ?? database.ExRate.BaseCurrency!,
                  value: assets.reduce((acc, cur) => (acc + database.ExRate.amountToBase(cur.value).value) * rate, 0)
                }
              }
            }
          }
          case LocationType.Address:
          default: {
            const addressBook = await database.Account.getContact(location.locationId)
            const assets = collectables.filter(({ location: { locationId } }) => locationId === addressBook.id)
            return {
              item: { id: addressBook.id, latLng: addressBook.center },
              info: {
                name: getFullName(addressBook),
                type: location.locationType,
                address: addressBook.address,
                imageId: undefined,
                number: assets.length,
                value: {
                  currency: currency ?? database.ExRate.BaseCurrency!,
                  value: assets.reduce((acc, cur) => (acc + database.ExRate.amountToBase(cur.value).value) * rate, 0)
                }
              }
            }
          }
        }
      })
    )
  }
}

export async function addOtherCollectable(database: Database, data: OtherCollectable) {
  if (data.location.locationType === LocationType.NewAddress) {
    data.location.locationType = LocationType.Address
  }

  await database.otherCollectable.add(data)

  await delay()
  mutateOtherCollectable()
}

export async function updateOtherCollectable(database: Database, data: OtherCollectable) {
  const { attachments: newAttach = [] } = data
  const { attachments: oldAttach = [] } = await database.otherCollectable.getById(data.id)

  if (data.location.locationType === LocationType.NewAddress) {
    data.location.locationType = LocationType.Address
  }

  await database.otherCollectable.update(data)

  Promise.all(
    oldAttach
      // filter deleted attachments
      .filter(({ key }) => !newAttach.some((attach) => attach.key === key))
      // remove file and thumbnails from storage
      .map(async ({ key }) => {
        try {
          const { contentType = '' } = await database.Attachments.getMeta(data.id, key)
          const isImage = contentType.startsWith('image')
          const thumbnails = isImage ? Object.values(ImageSizes).map((size) => `${key}_${size}`) : []
          return await Promise.all([
            database.Attachments.delete(data.id, key),
            ...thumbnails.map((thumb) => database.Attachments.delete(data.id, thumb))
          ])
        } catch (e) {
          console.log(e)
        }
      })
  )

  await delay()
  mutateOtherCollectable()
}

export async function relocateOtherCollectables(
  database: Database,
  ids: string[],
  newLocation: LocationInfo,
  callback?: (percentage: number) => void
) {
  let progress: Record<'current' | 'total', number> = { current: 0, total: ids.length }

  const isNewAddress = newLocation.locationType === LocationType.NewAddress
  if (isNewAddress) newLocation.locationType = LocationType.Address
  for (const id of ids) {
    const otherCollectables = await database.otherCollectable.getById(id)
    const oldLocation = otherCollectables.location
    if (oldLocation.locationId === newLocation.locationId) continue

    await database.otherCollectable.update({ ...otherCollectables, location: newLocation, id })
    progress = { current: progress.current + 1, total: progress.total }
    callback && callback(Math.round((progress.current / progress.total) * 100))
  }

  await delay()
  mutateOtherCollectable()
}

export async function deleteOtherCollectable(database: Database, id: string) {
  await database.otherCollectable.delete(id)
  await delay()
  mutateOtherCollectable(id)
}

export async function deleteOtherCollectables(
  database: Database,
  ids: string[],
  callback?: (percentage: number) => void
) {
  let progress: Record<'current' | 'total', number> = { current: 0, total: ids.length }

  for (const id of ids) {
    await database.otherCollectable.delete(id)
    progress = { current: progress.current + 1, total: progress.total }
    callback && callback(Math.round((progress.current / progress.total) * 100))
  }

  await delay()
  mutateOtherCollectable()
}

export async function updateAssetImages(database: Database, id: string, images: AssociatedFile[]) {
  const other = await database.otherCollectable.getById(id)
  const oldAttach = other.attachments ?? []
  const newAttach = [
    ...images,
    ...(other.attachments?.filter((attach) => attach.type !== AttachmentKind.AssetImage) ?? [])
  ]

  await database.otherCollectable.update({ ...other, attachments: newAttach, mainImage: images[0]?.key })

  Promise.all(
    oldAttach
      // filter deleted attachments
      .filter(({ key }) => !newAttach.some((attach) => attach.key === key))
      // remove file and thumbnails from storage
      .map(async ({ key }) => {
        try {
          const { contentType = '' } = await database.Attachments.getMeta(id, key)
          const isImage = contentType.startsWith('image')
          const thumbnails = isImage ? Object.values(ImageSizes).map((size) => `${key}_${size}`) : []
          return await Promise.all([
            database.Attachments.delete(id, key),
            ...thumbnails.map((thumb) => database.Attachments.delete(id, thumb))
          ])
        } catch (e) {
          console.log(e)
        }
      })
  )

  await delay()
  mutateOtherCollectable(id)
}

// actions
export function fetchActions(database: Database) {
  return async ([_key, id]: [typeof otherQuery.action, string]) => {
    const raw = await database.otherCollectable.getAllActions(id)
    const actions: ActionDetails[] = []
    for (const action of raw) {
      switch (action.actionType) {
        case ActionType.AddValuation:
          try {
            if (!action.valuedById) {
              actions.push(action)
              break
            }
            const valueBy = await database.Account.getContact(action.valuedById)
            const valueByName = getFullName(valueBy)
            actions.push({ ...action, valueByName })
          } catch (e) {
            console.log(e)
            actions.push({ ...action, valuedById: '', valueByName: 'DeletedContact' })
          }
          break
        default:
          actions.push(action)
      }
    }
    return actions
  }
}

export async function addValuation(database: Database, id: string, data: ValuationValues) {
  const actionId = database.genAssetId()
  await database.otherCollectable.addValuation(id, { ...data, id: actionId })
  await delay()
  mutateOtherCollectable(id)
}

export async function updateValuation(database: Database, id: string, data: ValuationValues) {
  await database.otherCollectable.updateValuation(id, data)
  await delay()
  mutateOtherCollectable(id)
}

export async function deleteValuation(database: Database, id: string, valuationId: string) {
  await database.otherCollectable.deleteValuation(id, valuationId)
  await delay()
  mutateOtherCollectable(id)
}
