import { useCallback, useEffect, useMemo, useState } from 'react'
import { Loader2Icon } from 'lucide-react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { LocationType } from 'core/remodel/types/common'
import { locationTypeValues } from 'core/remodel/types/options'
import type { LocationInfo } from 'core/remodel/types/relations/locationInfo'
import {
  addContactPosition,
  addContactRoom,
  contactQuery,
  fetchContactOptions,
  fetchContactRoomOptions,
  fetchContactRoomPositionOptions
} from '@/api/ContactService'
import {
  addPropertyPosition,
  addPropertyRoom,
  fetchPropertyOptions,
  fetchPropertyPositionOptions,
  fetchPropertyRoomOptions,
  propertyQuery
} from '@/api/PropertyService'
import { cn } from '@/utils/classnames'
import { makeOptions } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  FormAutocomplete,
  FormCreatableAutocomplete,
  FormSelect,
  FormTextarea,
  Progress,
  SelectItem
} from '@/components/base'
import { PlusIcon, XIcon } from '@/components/icon'
import NewAddress from '@/components/NewAddress'

const defaultValues: LocationInfo = {
  locationType: LocationType.MyProperty,
  locationId: '',
  roomId: '',
  position: '',
  notes: ''
}

interface RelocateFormProps {
  values?: LocationInfo
  onSubmit: SubmitHandler<LocationInfo>
  onCancel: () => void
  percentage?: number
  currentLocationId?: string
  isWine?: boolean
}

export function RelocateForm({
  values,
  onSubmit,
  onCancel,
  percentage = NaN,
  currentLocationId,
  isWine = false
}: RelocateFormProps) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  // form
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm<LocationInfo>({ values, defaultValues })
  const locationType = watch('locationType')
  const locationId = watch('locationId')
  const roomId = watch('roomId')
  // Derived values
  const isProperty = locationType === LocationType.MyProperty
  const isAddress = locationType === LocationType.Address
  const isNewAddress = locationType === LocationType.NewAddress

  // preferences and options
  const database = useAuthStore((state) => state.database)
  const { data: propertyOptions, isLoading: loadingProperties } = useSWR(
    [propertyQuery.options],
    fetchPropertyOptions(database!)
  )
  const { data: contactOptions, isValidating: loadingContacts } = useSWR(
    [contactQuery.options],
    fetchContactOptions(database!)
  )
  const { data: propertyRoomOptions, isValidating: loadingPropertyRooms } = useSWR(
    locationId && [propertyQuery.rooms, locationId],
    fetchPropertyRoomOptions(database!)
  )
  const { data: propertyPositionOptions, isValidating: loadingPropertyPositions } = useSWR(
    locationId && roomId && [propertyQuery.positions, locationId, roomId],
    fetchPropertyPositionOptions(database!)
  )
  const { data: contactRoomOptions, isValidating: loadingContactRooms } = useSWR(
    locationId && [contactQuery.rooms, locationId],
    fetchContactRoomOptions(database!)
  )
  const { data: contactPositionOptions, isValidating: loadingContactPositions } = useSWR(
    locationId && roomId && [contactQuery.positions, locationId, roomId],
    fetchContactRoomPositionOptions(database!)
  )
  // Create options
  const relocatePropertyOptions = useMemo(
    () => propertyOptions?.filter((option) => option.value !== currentLocationId),
    [propertyOptions, currentLocationId]
  )
  const relocateContactOptions = useMemo(
    () => contactOptions?.filter((option) => option.value !== currentLocationId),
    [contactOptions, currentLocationId]
  )
  const locationTypeOptions = makeOptions(locationTypeValues, (key) => `LocationTypeOptions.${key}`)
  const roomOptions = isProperty ? propertyRoomOptions : contactRoomOptions
  const loadingRooms = isProperty ? loadingPropertyRooms : loadingContactRooms
  const positionOptions = isProperty ? propertyPositionOptions : contactPositionOptions
  const loadingPositions = isProperty ? loadingPropertyPositions : loadingContactPositions

  const resetLocation = useCallback(
    (resetId: boolean) => {
      resetId && setValue('locationId', '')
      setValue('roomId', undefined)
      setValue('position', undefined)
      setValue('notes', undefined)
    },
    [setValue]
  )

  const addRoom = async (roomName: string) => {
    switch (locationType) {
      case LocationType.MyProperty:
        const PropertyRoomId = await addPropertyRoom(database!, locationId, roomName)
        return PropertyRoomId
      case LocationType.Address:
      case LocationType.NewAddress:
        const ContactRoomId = await addContactRoom(database!, locationId, roomName)
        return ContactRoomId
    }
  }

  const addPosition = async (positionName: string) => {
    switch (locationType) {
      case LocationType.MyProperty:
        await addPropertyPosition(database!, locationId, roomId!, positionName)
        return positionName
      case LocationType.Address:
      case LocationType.NewAddress:
        await addContactPosition(database!, locationId, roomId!, positionName)
        return positionName
    }
  }

  useEffect(() => {
    if (values !== undefined) {
      reset({ ...values }, { keepDirtyValues: true })
    }
  }, [values, reset])

  return (
    <>
      {isSubmitting && !isNaN(percentage!) ? (
        <div className={'flex h-52 flex-col items-center justify-center gap-3 p-4'}>
          <p>{t('RelocatingAssets')}</p>
          <p className={'text-center'}>{`${t('DoNotCloseWindow')} ${t('properties:MoveNeedResumed')}`}</p>
          <Progress value={percentage} />
          <p>{`${percentage} %`}</p>
        </div>
      ) : (
        <form className={'space-y-4 p-4'} onSubmit={handleSubmit(onSubmit)}>
          <div className={'grid grid-cols-2 gap-4'}>
            <div className={'flex flex-col gap-4'}>
              <FormSelect
                control={control}
                name={'locationType'}
                label={t('LocationType')}
                onSelected={() => {
                  resetLocation(true)
                }}
              >
                {locationTypeOptions.map(({ label, value }) => (
                  <SelectItem key={value} value={value}>
                    {label}
                  </SelectItem>
                ))}
              </FormSelect>
              {isProperty && (
                <FormAutocomplete
                  control={control}
                  name={'locationId'}
                  label={t('Field.LocationOfAsset')}
                  options={relocatePropertyOptions}
                  isLoading={loadingProperties}
                  rules={{ required: true }}
                  placeholder={t('TypeToSearch')}
                  onChanged={() => resetLocation(false)}
                />
              )}
              {isAddress && (
                <FormAutocomplete
                  control={control}
                  name={'locationId'}
                  label={t('Field.LocationOfAsset')}
                  options={relocateContactOptions}
                  isLoading={loadingContacts}
                  rules={{ required: true }}
                  placeholder={t('TypeToSearch')}
                  onChanged={() => resetLocation(false)}
                />
              )}
              {isNewAddress && (
                <>
                  <Button
                    className={'group justify-start gap-x-3 justify-self-start'}
                    onClick={() => setIsOpen(true)}
                    disabled={locationId.length > 0}
                  >
                    <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                      <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                    </div>
                    <span
                      className={
                        'whitespace-nowrap text-sm text-text transition-colors ease-out group-hover:text-primary'
                      }
                    >
                      {t('CreateNewAddress')}
                    </span>
                  </Button>
                  {locationId.length > 0 && (
                    <div className={'flex items-center justify-between'}>
                      <p className={'text-sm'}>
                        {contactOptions?.find(({ value }) => value === locationId)?.label ?? '-'}
                      </p>
                      <Button
                        className={'rounded-full transition-colors hover:bg-grey/20'}
                        onClick={() => setValue('locationId', '')}
                      >
                        <XIcon className={'text-primary'} />
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={'flex flex-col gap-4'}>
              <FormCreatableAutocomplete
                control={control}
                name={'roomId'}
                label={isWine ? t('collectables:RoomOrBin') : t('Field.Room')}
                options={roomOptions}
                onCreate={(name) => addRoom(name)}
                placeholder={t('SearchOrCreate')}
                isLoading={loadingRooms}
                isDisabled={!locationId}
                onChanged={() => setValue('position', undefined)}
              />
              <FormCreatableAutocomplete
                control={control}
                name={'position'}
                label={isWine ? t('collectables:PositionOrShelf') : t('Field.Position')}
                options={positionOptions}
                onCreate={(name) => addPosition(name)}
                placeholder={t('SearchOrCreate')}
                isLoading={loadingPositions}
                isDisabled={!roomId}
              />
            </div>
            <div className={'col-span-2'}>
              <FormTextarea control={control} name={'notes'} label={t('Field.Notes')} />
            </div>
          </div>
          <fieldset className={'flex items-center justify-end gap-x-2 [&>button]:flex-1'} disabled={isSubmitting}>
            <Button variant={'outline'} size={'md'} onClick={onCancel}>
              {t('Cancel')}
            </Button>
            <Button
              className={'group relative'}
              variant={'solid'}
              size={'md'}
              type={'submit'}
              disabled={!isValid || !locationId}
            >
              {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
              <span className={cn({ 'opacity-0': isSubmitting })}>{values ? t('RelocateAssets') : t('Relocate')}</span>
            </Button>
          </fieldset>
        </form>
      )}
      <NewAddress
        isOpen={isOpen}
        onSubmitted={(id) => {
          resetLocation(false)
          setValue('locationId', id)
        }}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}
