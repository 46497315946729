import {
  AuthErrorCodes,
  EmailAuthProvider,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  multiFactor,
  reauthenticateWithCredential,
  updatePhoneNumber,
  updatePassword,
  type MultiFactorInfo,
  type MultiFactorSession,
  type TotpSecret,
  TotpMultiFactorGenerator,
} from "firebase/auth";
import {
  Auth,
  CoreAuth,
  CoreFirestore,
  DocumentReference,
  Unsubscribe,
  checkAndGetData,
  checkDuplicated,
  getQueriedData,
} from "../../coreFirebase";
import { EncryptionFieldKey } from "../encryption/utils";
import { FullRefs, Refs } from "../refs";
import {
  AsyncTask,
  AsyncTaskExecutor,
  IAsyncTaskExecutor,
} from "../types/asyncTask";
import { SubscriptionInfo } from "../types/billing";
import { AssetType, Optional } from "../types/common";
import { Contact } from "../types/contact";
import { DbSharedFields } from "../types/database";
import { CanNotDelete, DataPoisoned, InvalidInput } from "../types/error";
import {
  AllContactRoles,
  AssetIdAndRoles,
  RoleToAsset,
  WineIdAndRoles,
} from "../types/relations";
import { SummaryManager } from "../types/summaryManager";
import { TypeResult } from "../types/typeVersion";
import { DownloadableAssetType, Preferences, Profile } from "../types/user";
import {
  applyUpdateToObject,
  convertAllTimestamp,
  deepCopy,
  UpdateObject,
} from "../utils";
import { ArtsRepo } from "./arts";
import { BelongingsRepo } from "./belongings";
import { CashAndBankingRepo } from "./cashAndBanking";
import { CryptocurrencyRepo } from "./cryptocurrencies";
import { EncryptionManager } from "./encryption";
import { ExchangeRate } from "./exchangeRate";
import { InsuranceRepo } from "./insurance";
import { OtherInvestmentRepo } from "./otherInvestments";
import { PropertiesRepo } from "./properties";
import { TraditionalInvestmentRepo } from "./traditionalInvestments";
import { WineAndSpiritsRepo } from "./wineAndSprits";
import { Room } from "../types/properties";
import { LocationInfo } from "../types/relations/locationInfo";

export class AccountData {
  protected readonly refs: FullRefs;
  protected readonly auth: Auth;
  protected readonly summaryManager: SummaryManager;

  protected readonly userId: string;

  readonly Encryption: EncryptionManager;
  readonly exRate: ExchangeRate;

  private preferences: {
    self: Optional<Preferences>;
    current: Optional<Preferences>;
    currentUserId: Optional<string>;
  };
  private loadPreferencesPromise: {
    self: Optional<Promise<void>>;
    current: Optional<Promise<void>>;
  };
  private _unsubscribe: {
    self: Optional<Unsubscribe>;
    current: Optional<Unsubscribe>;
  };

  constructor(shared: DbSharedFields, userId: string, auth: Auth) {
    this.exRate = shared.exRate;
    this.refs = shared.refs;
    this.Encryption = shared.encryption;
    this.summaryManager = shared.summaryManager;
    this.userId = userId;
    this.auth = auth;

    this.preferences = {
      self: undefined,
      current: undefined,
      currentUserId: undefined,
    };
    this.loadPreferencesPromise = {
      self: undefined,
      current: undefined,
    };
    this._unsubscribe = {
      self: undefined,
      current: undefined,
    };
  }

  /******* Preferences Cache ********/
  private async setupPreferencesSnapshot(
    target: "self" | "current",
    docRef: DocumentReference<Preferences>
  ) {
    if (this._unsubscribe[target] === undefined) {
      if (target === "current") {
        this.preferences.currentUserId = this.refs.currentRefs.userId;
      }
      // Resolve until preferences are written by function trigger
      this.loadPreferencesPromise[target] = new Promise<void>((resolve) => {
        this._unsubscribe[target] = CoreFirestore.onSnapshot(
          docRef,
          (doc) => {
            if (doc.exists()) {
              this.preferences[target] = convertAllTimestamp(doc.data());
              resolve();
            }
          },
          (e) => {
            if (e.code !== "permission-denied") {
              console.error("onSnapshot", e);
            }
          }
        );
      });
    }
    // await if the getDoc promise is not yet resolved
    if (this.loadPreferencesPromise[target]) {
      await this.loadPreferencesPromise[target];
    }
  }

  async loadPreferences(): Promise<void> {
    await Promise.all([
      this.setupPreferencesSnapshot(
        "self",
        this.refs.selfRefs.UserPreferencesDoc
      ),
      this.setupPreferencesSnapshot(
        "current",
        this.refs.currentRefs.UserPreferencesDoc
      ),
    ]);
  }

  unsubscribePreferences(): void {
    if (this._unsubscribe.self) this._unsubscribe.self();
    if (this._unsubscribe.current) this._unsubscribe.current();
  }

  setCurrentPreferences() {
    if (this.preferences.currentUserId === this.refs.currentRefs.userId) {
      console.log("Skip current preferences update. Same user.");
      return;
    }
    if (this._unsubscribe.current) {
      this._unsubscribe.current();
    }
    this._unsubscribe.current = undefined;
    this.preferences.current = undefined;
    this.preferences.currentUserId = undefined;
    // #NOTE: We don't await this function here.
    // This function creates a promise, and other get preferences functions will await it when they need it.
    this.setupPreferencesSnapshot(
      "current",
      this.refs.currentRefs.UserPreferencesDoc
    );
  }

  /***** UserProfiles *****/
  getUser(): Profile {
    const user = this.auth.currentUser;
    if (!user) throw new DataPoisoned("User not found");
    if (!user.displayName || !user.email)
      throw new DataPoisoned("User displayName or email not initialized");
    const profile: Profile = {
      name: user.displayName,
      email: user.email,
    };
    if (user.photoURL) profile.photo = user.photoURL;
    return profile;
  }

  async getSubscription(): Promise<SubscriptionInfo> {
    const doc = await CoreFirestore.getDoc(
      this.refs.currentRefs.UserSubscriptionInfoDoc
    ).then(checkAndGetData);
    CoreFirestore.convertTimestampToDate(doc.subscription, "expiryDate");
    CoreFirestore.convertTimestampToDate(doc.subscription, "updateAt");
    return doc;
  }

  async updateUser(data: Partial<Profile>): Promise<void> {
    const currentUser = this.auth.currentUser;
    if (!currentUser) throw new DataPoisoned("User not found");
    const update: {
      displayName?: string | null | undefined;
      photoURL?: string | null | undefined;
    } = {};
    if (data.name) {
      update.displayName = data.name;
    }
    if (data.photo || data.photo?.length === 0) {
      update.photoURL = data.photo;
    }
    if (Object.keys(update).length > 0) {
      await CoreAuth.updateProfile(this.auth.currentUser, update);
    }
    if (data.email) {
      await CoreAuth.updateEmail(this.auth.currentUser, data.email);
    }
  }

  async updateUserPhone(
    verificationId: string,
    verificationCode: string
  ): Promise<void> {
    const user = this.auth.currentUser;
    if (!user) throw new DataPoisoned("User not found");
    const credential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    await updatePhoneNumber(user as any, credential);
  }

  async uploadUserPhoto(
    data: Blob | Uint8Array | ArrayBuffer,
    iv: Uint8Array
  ): Promise<void> {
    const encryptedData = await this.Encryption.current.encryptBytes(data, iv);
    await CoreFirestore.uploadBytes(
      this.refs.currentRefs.UserPhotoReference,
      encryptedData
    );

    // await uploadBytes(this.refs.currentRefs.UserPhotoReference, data);
    const url = await CoreFirestore.getDownloadURL(
      this.refs.currentRefs.UserPhotoReference
    );
    await this.updateUser({ photo: url });
  }

  async updateUserPassword(newPassword: string): Promise<void> {
    await updatePassword(this.auth.currentUser as any, newPassword);
  }

  async deleteUserPhoto(): Promise<void> {
    await this.updateUser({ photo: "" });
  }

  async hasPasswordProvider(): Promise<boolean> {
    const user = this.auth.currentUser as any;
    if (!user) throw new DataPoisoned("User not found");
    const providerData = user.providerData as any[];
    return providerData.some(({ providerId }) => providerId === "password");
  }

  async reauthenticatePassword(password: string): Promise<void> {
    const user = this.auth.currentUser as any;
    if (!user) throw new DataPoisoned("User not found");
    const cred = EmailAuthProvider.credential(user.email, password);
    try {
      await reauthenticateWithCredential(user, cred);
    } catch (e: any) {
      if (e.code !== AuthErrorCodes.MFA_REQUIRED) throw e;
    }
  }

  /***** UserPreferences *****/
  async setPreferences(data: Preferences): Promise<void> {
    await CoreFirestore.setDoc(this.refs.selfRefs.UserPreferencesDoc, data);
    if (data.baseCurrency) {
      await this.exRate.getAndSetBaseExRate(data.baseCurrency);
    }
  }

  async getPreferences(): Promise<Preferences> {
    if (
      this._unsubscribe.self === undefined ||
      this.loadPreferencesPromise.self
    )
      await this.loadPreferences();
    if (!this.preferences.self) throw new DataPoisoned("Preferences not found");
    return deepCopy(this.preferences.self);
  }

  async getCurrentPreferences(): Promise<Preferences> {
    if (
      this._unsubscribe.current === undefined ||
      this.loadPreferencesPromise.current
    )
      await this.loadPreferences();
    if (!this.preferences.current)
      throw new DataPoisoned("Current preferences not found");
    return deepCopy(this.preferences.current);
  }

  async tryGetPreferences(): Promise<Optional<Preferences>> {
    if (
      this._unsubscribe.self === undefined ||
      this.loadPreferencesPromise.self
    )
      await this.loadPreferences();
    return deepCopy(this.preferences.self);
  }

  async tryGetCurrentPreferences(): Promise<Optional<Preferences>> {
    if (
      this._unsubscribe.current === undefined ||
      this.loadPreferencesPromise.current
    )
      await this.loadPreferences();
    return deepCopy(this.preferences.current);
  }

  currentPreferencesSnapshot(
    cb: (preferences: Optional<Preferences>) => void
  ): Unsubscribe {
    return CoreFirestore.onSnapshot(
      this.refs.currentRefs.UserPreferencesDoc,
      (doc) => {
        let data = doc.data();
        if (data) {
          cb(convertAllTimestamp(data));
        } else {
          cb(data);
        }
      },
      (e) => {
        if (e.code != "permission-denied") {
          console.error("onSnapshot", e);
          return;
        }
      }
    );
  }

  preferencesSnapshot(
    cb: (preferences: Optional<Preferences>) => void
  ): Unsubscribe {
    return CoreFirestore.onSnapshot(
      this.refs.selfRefs.UserPreferencesDoc,
      (doc) => {
        let data = doc.data();
        if (data) {
          cb(convertAllTimestamp(data));
        } else {
          cb(data);
        }
      },
      (e) => {
        if (e.code != "permission-denied") {
          console.error("onSnapshot", e);
          return;
        }
      }
    );
  }

  async updatePreferences(data: Partial<Preferences>): Promise<void> {
    await CoreFirestore.updateDoc(this.refs.selfRefs.UserPreferencesDoc, data);
    if (data.baseCurrency) {
      await this.exRate.getAndSetBaseExRate(data.baseCurrency);
    }
  }

  async startCloseAccount(): Promise<void> {
    await CoreFirestore.runTransaction(async (tx) => {
      const data = await tx
        .get(this.refs.selfRefs.UserPreferencesDoc)
        .then(checkAndGetData);
      if (data.closeAccountData !== undefined) {
        throw new Error("Close account requested");
      }
      const closeAccountData = {
        requestDate: new Date(),
      };
      tx.update(this.refs.selfRefs.UserPreferencesDoc, {
        closeAccountData,
      });
    });
  }

  async revokeCloseAccount(): Promise<void> {
    await CoreFirestore.runTransaction(async (tx) => {
      const data = await tx
        .get(this.refs.selfRefs.UserPreferencesDoc)
        .then(checkAndGetData);
      if (data.closeAccountData === undefined) {
        return;
      }
      let convertedDate = CoreFirestore.checkAndConvertTimestampToDate(
        data.closeAccountData.requestDate
      );
      // close date not due
      if (
        convertedDate &&
        convertedDate.getTime() + 90 * 24 * 60 * 60 * 1000 > Date.now()
      ) {
        tx.update(this.refs.selfRefs.UserPreferencesDoc, {
          closeAccountData: CoreFirestore.deleteField(),
        });
      }
    });
  }

  async setAssetTypeDownloaded(
    dataTypes: DownloadableAssetType[]
  ): Promise<void> {
    await CoreFirestore.runTransaction(async (tx) => {
      const data = await tx
        .get(this.refs.selfRefs.UserPreferencesDoc)
        .then(checkAndGetData);
      if (data.closeAccountData === undefined) {
        throw new Error("Close account not requested");
      }
      const closeAccountData = {
        ...data.closeAccountData,
      };
      const needUpdate = dataTypes.filter(
        (v) => closeAccountData[v] === undefined
      );
      for (const dataType of needUpdate) {
        closeAccountData[dataType] = new Date();
      }
      if (needUpdate.length > 0) {
        tx.update(this.refs.selfRefs.UserPreferencesDoc, {
          closeAccountData,
        });
      }
    });
  }

  /**** Security *****/
  getMultiFactorSession(): Promise<MultiFactorSession> {
    const user = this.auth.currentUser;
    if (!user) throw new DataPoisoned("User not found");
    return multiFactor(user as any).getSession();
  }

  async getMultiFactorList(): Promise<MultiFactorInfo[]> {
    const user = this.auth.currentUser as any;
    if (!user) throw new DataPoisoned("User not found");
    await user.reload();
    return multiFactor(user).enrolledFactors;
  }

  async enrollMultiFactorSms(verificationId: string, verificationCode: string) {
    const user = this.auth.currentUser;
    if (!user) throw new DataPoisoned("User not found");
    const credential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    const assertion = PhoneMultiFactorGenerator.assertion(credential);
    await multiFactor(user as any).enroll(assertion);
  }

  async enrollTotp(secret: TotpSecret, code: string) {
    const user = this.auth.currentUser;
    if (!user) throw new DataPoisoned("User not found");
    const assertion = TotpMultiFactorGenerator.assertionForEnrollment(
      secret,
      code
    );
    await multiFactor(user as any).enroll(assertion, "MyAssets");
  }

  async unenrollMultiFactor(info: MultiFactorInfo) {
    const user = this.auth.currentUser;
    if (!user) throw new DataPoisoned("User not found");
    await multiFactor(user as any).unenroll(info);
  }

  /**** Address book *****/
  async addContact(req: Contact.CreateFields): Promise<string> {
    const newDocRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.AddressBook,
      req.id
    );
    Contact.validateEncryptedPart(req);

    await CoreFirestore.runTransaction(async (transaction) => {
      await transaction.get(newDocRef).then(checkDuplicated);

      const contact = Contact.fromCreate(req, this.userId);
      Contact.validateEncryptedPart(contact);
      const encrypted = await Contact.encrypt(contact, this.Encryption.current);
      Contact.validateEncryptedObj(encrypted);
      transaction.set(newDocRef, encrypted);
    });
    return newDocRef.id;
  }

  async updateContact(req: Contact.UpdateFields) {
    const docRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.AddressBook,
      req.id
    );
    Contact.validateEncryptedPart(req);

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Contact.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) Contact.handleOutDated();

      const currentData = await Contact.decrypt(
        currentUndecrypted,
        this.Encryption.current
      );
      const updates = Contact.intoUpdate(currentData, req);

      const encryptedUpdate: UpdateObject<Contact.Encrypted> =
        Contact.removeEncryptedFields(updates);
      let shouldEncrypt = false;
      const encryptedFieldsInUpdate = Contact.encryptedKeysArray.reduce(
        (obj, key) => {
          const encryptField = (<any>updates)[key];
          if (encryptField) {
            obj[key] = encryptField;
            shouldEncrypt = true;
          } else if (encryptField === null) {
            shouldEncrypt = true;
          } else if ((<any>currentData)[key]) {
            obj[key] = (<any>currentData)[key];
          }
          return obj;
        },
        {} as any
      );
      if (shouldEncrypt) {
        const encrypted = await Contact.encrypt(
          encryptedFieldsInUpdate,
          this.Encryption.current
        );
        encryptedUpdate[EncryptionFieldKey] = encrypted[EncryptionFieldKey];
      }
      Contact.validateEncryptedObj(encryptedUpdate);
      applyUpdateToObject(currentUndecrypted, encryptedUpdate);
      transaction.set(docRef, currentUndecrypted);
    });
  }

  async deleteContact(
    contactId: string,
    relocateTo?: LocationInfo
  ): Promise<IAsyncTaskExecutor> {
    if (this.summaryManager.isDelegate)
      throw new Error("Delegates cannot delete contacts");
    const docRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.AddressBook,
      contactId
    );
    await CoreFirestore.getDoc(docRef).then(checkAndGetData);
    const contactRelations = await getContactRelationsOfContact(
      this.refs.currentRefs,
      contactId
    );
    checkDeletable(contactRelations);
    return new DeleteContactHandler(
      this.refs,
      contactRelations,
      contactId,
      relocateTo
        ? await LocationInfo.encrypt(relocateTo, this.Encryption.current)
        : undefined
    );
  }

  async getContact(contactId: string): Promise<Contact> {
    const docRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.AddressBook,
      contactId
    );
    return CoreFirestore.getDoc(docRef)
      .then(checkAndGetData)
      .then((v) => Contact.decrypt(v, this.Encryption.current));
  }

  async listContact(): Promise<Contact[]> {
    const collectionRef = this.refs.currentRefs.AddressBook;
    return Promise.all(
      (
        await CoreFirestore.getDocsFromCollection(collectionRef).then(
          getQueriedData
        )
      ).map((v) => Contact.decrypt(v, this.Encryption.current))
    );
  }

  async addRoom(contactId: string, roomName: string) {
    const docRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.AddressBook,
      contactId
    );
    const newRoomId = CoreFirestore.genAssetId();

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Contact.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) Contact.handleOutDated();

      const encryptedRoom = await Room.encrypt(
        {
          id: newRoomId,
          name: roomName,
          position: [],
        },
        this.Encryption.current
      );
      if (!currentUndecrypted.room) currentUndecrypted.room = [];
      currentUndecrypted.room.push(encryptedRoom);

      Contact.validateEncryptedObj(currentUndecrypted);
      transaction.update(docRef, {
        room: currentUndecrypted.room,
      });
    });
    return newRoomId;
  }

  async addPosition(contactId: string, roomId: string, position: string) {
    const docRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.AddressBook,
      contactId
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Contact.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) Contact.handleOutDated();

      if (!currentUndecrypted.room)
        throw new InvalidInput("Room does not exist");
      const roomIndex = currentUndecrypted.room.findIndex(
        (v) => v.id === roomId
      );
      if (roomIndex === -1) {
        throw new Error("Room id does not exist");
      }

      if (currentUndecrypted.room[roomIndex].position.includes(position))
        throw new Error("Position already exists");
      currentUndecrypted.room[roomIndex].position.push(position);
      transaction.update(docRef, {
        room: currentUndecrypted.room,
      });
    });
  }

  async getContactRelationsOfContact(
    contactId: string
  ): Promise<AllContactRoles> {
    return getContactRelationsOfContact(this.refs.currentRefs, contactId);
  }
}

class DeleteContactHandler implements IAsyncTaskExecutor {
  private refs: FullRefs;
  private engine: AsyncTaskExecutor;
  private contactId: string;
  private relocateTo?: LocationInfo.Encrypted;

  totalTasks: number;
  finished: number = 0;

  constructor(
    refs: FullRefs,
    relations: AllContactRoles,
    contactId: string,
    relocateTo?: LocationInfo.Encrypted
  ) {
    this.refs = refs;
    this.contactId = contactId;
    this.relocateTo = relocateTo;

    const docRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.AddressBook,
      contactId
    );
    const tasks: AsyncTask[] = this.generateTask(relations);
    tasks.push(this.doDelete(docRef));
    this.engine = new AsyncTaskExecutor(tasks);
    this.totalTasks = tasks.length;
  }

  private doDelete(docRef: DocumentReference): AsyncTask {
    return AsyncTask.retry(async () => {
      const result = await CoreFirestore.runTransaction(async (tx) => {
        const relations = await getContactRelationsOfContact(
          this.refs.currentRefs,
          this.contactId
        );
        checkDeletable(relations);
        const tasks = this.generateTask(relations);
        if (tasks.length > 0) {
          return tasks;
        }

        //do delete
        tx.delete(docRef);

        return undefined;
      });

      if (result) {
        await new AsyncTaskExecutor(result).run();
        return true;
      } else {
        return false;
      }
    });
  }

  private generateTask(relations: AllContactRoles): AsyncTask[] {
    const tasks: AsyncTask[] = [
      this.processArt(relations.art),
      this.processBelonging(relations.belonging, AssetType.Belonging),
      this.processCashAndBanking(relations.cashAndBanking),
      this.processCryptocurrency(relations.cryptocurrency),
      this.processInsurance(relations.insurance),
      this.processBelonging(
        relations.otherCollectable,
        AssetType.OtherCollectables
      ),
      this.processOtherInvestment(relations.otherInvestment),
      this.processProperty(relations.property),
      this.processTraditionalInvestment(relations.traditionalInvestment),
      this.processWine(relations.wine),
    ].reduce((acc, v) => acc.concat(v), []);
    return tasks;
  }

  private checkAndGetLocation() {
    if (this.relocateTo === undefined) {
      throw new InvalidInput("RelocateTo is not set");
    }
    return this.relocateTo;
  }

  private processArt(relations: AssetIdAndRoles[]): AsyncTask[] {
    const tasks: AsyncTask[] = [];
    for (const { assetId, roles } of relations) {
      if (roles.length > 0) {
        const locationIdx = roles.findIndex(
          (v) => v === RoleToAsset.AssetLocation
        );
        if (locationIdx != -1) {
          tasks.push(
            AsyncTask.once(() =>
              ArtsRepo.relocate(
                this.refs.currentRefs,
                this.refs.selfRefs.userId,
                assetId,
                this.contactId,
                this.checkAndGetLocation()
              )
            )
          );
          roles.splice(locationIdx, 1);
          if (roles.length == 0) continue;
        }
        tasks.push(
          AsyncTask.once(() =>
            ArtsRepo.removeContactRelation(
              this.refs.currentRefs,
              this.refs.selfRefs.userId,
              assetId,
              this.contactId,
              roles
            )
          )
        );
      }
    }
    return tasks;
  }

  private processBelonging(
    relations: AssetIdAndRoles[],
    assetType: AssetType.Belonging | AssetType.OtherCollectables
  ): AsyncTask[] {
    const tasks: AsyncTask[] = [];
    for (const { assetId, roles } of relations) {
      if (roles.length > 0) {
        const locationIdx = roles.findIndex(
          (v) => v === RoleToAsset.AssetLocation
        );
        if (locationIdx != -1) {
          tasks.push(
            AsyncTask.once(() =>
              BelongingsRepo.relocate(
                this.refs.currentRefs,
                this.refs.selfRefs.userId,
                assetId,
                assetType,
                this.contactId,
                this.checkAndGetLocation()
              )
            )
          );
          roles.splice(locationIdx, 1);
          if (roles.length == 0) continue;
        }
        tasks.push(
          AsyncTask.once(() =>
            BelongingsRepo.removeContactRelation(
              this.refs.currentRefs,
              this.refs.selfRefs.userId,
              assetId,
              assetType,
              this.contactId,
              roles
            )
          )
        );
      }
    }
    return tasks;
  }

  private processCashAndBanking(relations: AssetIdAndRoles[]): AsyncTask[] {
    const tasks: AsyncTask[] = [];
    for (const { assetId, roles } of relations) {
      if (roles.length > 0) {
        tasks.push(
          AsyncTask.once(() =>
            CashAndBankingRepo.removeContactRelation(
              this.refs.currentRefs,
              this.refs.selfRefs.userId,
              assetId,
              this.contactId,
              roles
            )
          )
        );
      }
    }
    return tasks;
  }

  private processCryptocurrency(relations: AssetIdAndRoles[]): AsyncTask[] {
    const tasks: AsyncTask[] = [];
    for (const { assetId, roles } of relations) {
      if (roles.length > 0) {
        tasks.push(
          AsyncTask.once(() =>
            CryptocurrencyRepo.removeContactRelation(
              this.refs.currentRefs,
              this.refs.selfRefs.userId,
              assetId,
              this.contactId,
              roles
            )
          )
        );
      }
    }
    return tasks;
  }

  private processInsurance(relations: AssetIdAndRoles[]): AsyncTask[] {
    const tasks: AsyncTask[] = [];
    for (const { assetId, roles } of relations) {
      if (roles.length > 0) {
        tasks.push(
          AsyncTask.once(() =>
            InsuranceRepo.removeContactRelation(
              this.refs.currentRefs,
              this.refs.selfRefs.userId,
              assetId,
              this.contactId,
              roles
            )
          )
        );
      }
    }
    return tasks;
  }

  private processOtherInvestment(relations: AssetIdAndRoles[]): AsyncTask[] {
    const tasks: AsyncTask[] = [];
    for (const { assetId, roles } of relations) {
      if (roles.length > 0) {
        tasks.push(
          AsyncTask.once(() =>
            OtherInvestmentRepo.removeContactRelation(
              this.refs.currentRefs,
              this.refs.selfRefs.userId,
              assetId,
              this.contactId,
              roles
            )
          )
        );
      }
    }
    return tasks;
  }

  private processProperty(relations: AssetIdAndRoles[]): AsyncTask[] {
    const tasks: AsyncTask[] = [];
    for (const { assetId, roles } of relations) {
      if (roles.length > 0) {
        tasks.push(
          AsyncTask.once(() =>
            PropertiesRepo.removeContactRelation(
              this.refs.currentRefs,
              this.refs.selfRefs.userId,
              assetId,
              this.contactId,
              roles
            )
          )
        );
      }
    }
    return tasks;
  }

  private processTraditionalInvestment(
    relations: AssetIdAndRoles[]
  ): AsyncTask[] {
    const tasks: AsyncTask[] = [];
    for (const { assetId, roles } of relations) {
      if (roles.length > 0) {
        tasks.push(
          AsyncTask.once(() =>
            TraditionalInvestmentRepo.removeContactRelation(
              this.refs.currentRefs,
              this.refs.selfRefs.userId,
              assetId,
              this.contactId,
              roles
            )
          )
        );
      }
    }
    return tasks;
  }

  private processWine(relations: WineIdAndRoles[]): AsyncTask[] {
    const tasks: AsyncTask[] = [];
    for (const { wineId, purchaseId, roles } of relations) {
      if (roles.length > 0) {
        const locationIdx = roles.findIndex(
          (v) => v === RoleToAsset.AssetLocation
        );
        if (locationIdx != -1) {
          tasks.push(
            AsyncTask.once(() =>
              WineAndSpiritsRepo.relocate(
                this.refs.currentRefs,
                this.refs.selfRefs.userId,
                wineId,
                purchaseId,
                this.checkAndGetLocation(),
                this.contactId
              )
            )
          );
          roles.splice(locationIdx, 1);
          if (roles.length == 0) continue;
        }
        tasks.push(
          AsyncTask.once(() =>
            WineAndSpiritsRepo.removeContactRelation(
              this.refs.currentRefs,
              this.refs.selfRefs.userId,
              wineId,
              purchaseId,
              this.contactId,
              roles
            )
          )
        );
      }
    }
    return tasks;
  }

  setCallback(callback: (...args: any) => any): void {
    this.engine.setCallback(callback);
    this.finished = this.engine.finished;
  }
  run(): Promise<void> {
    return this.engine.run();
  }
}

export async function getContactRelationsOfContact(
  refs: Refs,
  contactId: string
) {
  const relationsOfAssets = await CoreFirestore.getDocsFromCollection(
    refs.Relations,
    CoreFirestore.orderBy(contactId, "desc")
  ).then(getQueriedData);
  const result: AllContactRoles = {
    art: [],
    belonging: [],
    cashAndBanking: [],
    cryptocurrency: [],
    insurance: [],
    otherCollectable: [],
    otherInvestment: [],
    property: [],
    traditionalInvestment: [],
    wine: [],
  };
  relationsOfAssets.map((relation) => {
    switch (relation.assetType) {
      case AssetType.Art:
        result.art.push({
          assetId: relation.id,
          roles: Object.keys(relation[contactId].relations) as RoleToAsset[],
        });
        break;
      case AssetType.Belonging:
        result.belonging.push({
          assetId: relation.id,
          roles: Object.keys(relation[contactId].relations) as RoleToAsset[],
        });
        break;
      case AssetType.CashAndBanking:
        result.cashAndBanking.push({
          assetId: relation.id,
          roles: Object.keys(relation[contactId].relations) as RoleToAsset[],
        });
        break;
      case AssetType.Cryptocurrency:
        result.cryptocurrency.push({
          assetId: relation.id,
          roles: Object.keys(relation[contactId].relations) as RoleToAsset[],
        });
        break;
      case AssetType.Insurance:
        result.insurance.push({
          assetId: relation.id,
          roles: Object.keys(relation[contactId].relations) as RoleToAsset[],
        });
        break;
      case AssetType.OtherCollectables:
        result.otherCollectable.push({
          assetId: relation.id,
          roles: Object.keys(relation[contactId].relations) as RoleToAsset[],
        });
        break;
      case AssetType.OtherInvestment:
        result.otherInvestment.push({
          assetId: relation.id,
          roles: Object.keys(relation[contactId].relations) as RoleToAsset[],
        });
        break;
      case AssetType.Property:
        result.property.push({
          assetId: relation.id,
          roles: Object.keys(relation[contactId].relations) as RoleToAsset[],
        });
        break;
      case AssetType.TraditionalInvestments:
        result.traditionalInvestment.push({
          assetId: relation.id,
          roles: Object.keys(relation[contactId].relations) as RoleToAsset[],
        });
        break;
      case AssetType.WineAndSpirits:
        result.wine.push({
          wineId: relation.secondaryId!,
          purchaseId: relation.id,
          roles: Object.keys(relation[contactId].relations) as RoleToAsset[],
        });
        break;
    }
  });
  return result;
}

export function checkDeletable(contactRelations: AllContactRoles) {
  for (const relation of contactRelations.insurance) {
    if (
      relation.roles.some((v) =>
        [
          RoleToAsset.Insured,
          RoleToAsset.Broker,
          RoleToAsset.Specialist,
        ].includes(v)
      )
    ) {
      throw new CanNotDelete(
        "Can not delete contact as a role of insured, broker or specialist"
      );
    }
  }
}
