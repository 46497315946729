import * as React from 'react'
import {
  useController,
  type Control,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type RegisterOptions
} from 'react-hook-form'
import PhoneNumberInput, { Country, getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input'

import { cn } from '@/utils/classnames'
import { Input, type InputProps } from '@/components/base/Input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/base/Select'

interface FormPhoneInputProps<TValues extends FieldValues, TPath extends FieldPath<TValues>> {
  className?: string
  label?: string
  // controller props
  control: Control<TValues>
  name: TPath
  rules?: RegisterOptions<TValues, TPath>
  defaultValue?: FieldPathValue<TValues, TPath>
  defaultCountry?: Country
}

export function FormPhoneInput<TValues extends FieldValues, TPath extends FieldPath<TValues>>({
  className,
  label,
  control,
  name,
  rules: rulesFromProps,
  defaultValue,
  defaultCountry = 'US'
}: FormPhoneInputProps<TValues, TPath>) {
  const id = React.useId()
  const rules: RegisterOptions<TValues, TPath> = {
    ...rulesFromProps,
    validate: {
      valid: (value) => isValidPhoneNumber(value) || 'Invalid phone number format.',
      ...rulesFromProps?.validate
    }
  }
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({ control, name, rules, defaultValue })
  const isRequired = rules?.required !== undefined
  const hasError = error?.message !== undefined

  return (
    <div className={cn('grid grid-cols-1 gap-y-1', className)}>
      {label && (
        <label
          htmlFor={id}
          className={cn('text-xs text-[#414554]', isRequired && 'after:ml-0.5 after:text-error after:content-["*"]')}
        >
          {label}
        </label>
      )}
      <PhoneNumberInput
        className={'flex items-center gap-x-2'}
        international={false}
        addInternationalOption={false}
        defaultCountry={defaultCountry}
        countrySelectComponent={CountrySelectComponent}
        inputComponent={InputComponent}
        value={value}
        onChange={onChange}
        data-testid={`${name}-phone-input`}
        aria-label={`${name}-phone-input`}
      />
      {hasError && <p className={'text-xs text-red-500'}>{error.message}</p>}
    </div>
  )
}

interface CountryOption {
  label: string
  value: Country
}

interface CountrySelectComponentProps {
  value: Country
  onChange: (value: Country) => void
  options: CountryOption[]
  disabled?: boolean
}

const CountrySelectComponent = React.forwardRef<React.ElementRef<typeof SelectTrigger>, CountrySelectComponentProps>(
  ({ value, onChange, options, disabled }, ref) => {
    const callingCode = getCountryCallingCode(value)
    return (
      <Select value={value} onValueChange={onChange} disabled={disabled}>
        <SelectTrigger
          ref={ref}
          className={'w-20 flex-row-reverse border border-[#E6E7EB] bg-[#FBFCFE] font-bold text-primary [&>span]:grow'}
        >
          <SelectValue>{`+${callingCode}`}</SelectValue>
        </SelectTrigger>
        <SelectContent className={'max-h-[200px]'}>
          {options.map((option, index) => (
            <SelectItem key={index} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    )
  }
)
CountrySelectComponent.displayName = 'CountrySelectComponent'

const InputComponent = React.forwardRef<HTMLInputElement, InputProps>(({ className, ...props }, ref) => (
  <Input ref={ref} className={cn('border border-[#E6E7EB] bg-[#FBFCFE]', className)} {...props} />
))
InputComponent.displayName = 'InputComponent'
