import React, { useEffect, useMemo, useState } from 'react'
import { AlertCircleIcon } from 'lucide-react'
import {
  RegisterOptions,
  useController,
  useWatch,
  type Control,
  type FieldPath,
  type FieldValues
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { type ActionMeta, type SingleValue } from 'react-select'
import useSWR from 'swr'

import { CustomizedType } from 'core/remodel/types/enums'
import { artQuery, fetchArtistFull, searchArtistsBasic } from '@/api/ArtService'
import { addCustomType, fetchCustomTypes, typeQuery } from '@/api/CommonService'
import { type Option } from '@/types/common'
import { cn } from '@/utils/classnames'
import { useDebounce } from '@/hooks/useDebounce'
import { toast } from '@/hooks/useToast'
import { useAuthStore } from '@/store/authStore'
import { CreatableAutocomplete } from '@/components/base'

type ArtistOption = Option & { id?: string; birthYear?: string }

interface ArtistAutocompleteProps<Values extends FieldValues, Path extends FieldPath<Values>> {
  className?: string
  control: Control<Values>
  name: Path
  rules?: RegisterOptions<Values, Path>
  label?: string
  hint?: string
  artistId?: string
  onSelectId?: (id: string | undefined) => void
}

export function ArtistAutocomplete<Values extends FieldValues, Path extends FieldPath<Values>>({
  className,
  control,
  name,
  rules,
  label,
  hint,
  artistId,
  onSelectId
}: ArtistAutocompleteProps<Values, Path>) {
  const database = useAuthStore((state) => state.database)
  const componentId = React.useId()
  const { t } = useTranslation()
  const { data: artistInfo } = useSWR(artistId && [artQuery.artistInfo, artistId], fetchArtistFull(database!), {
    keepPreviousData: false
  })
  const {
    field,
    fieldState: { error }
  } = useController({ control, name, rules })
  // --- two main value ---
  // 1. artist name: valueName
  // 2. artist id: valueId
  // updated by onChangeName and onSelectId
  const valueName: string | undefined = useWatch({ control, name })
  const { value: _, onChange: onChangeName, ref, ...fieldProps } = field

  const displayOption: ArtistOption | null = useMemo(() => {
    if (!valueName) return null
    const artistBirthYear = artistInfo?.birthYear
    const birthYearLabel = artistBirthYear ? `(b. ${artistBirthYear})` : ''
    const label = `${valueName} ${birthYearLabel}`
    return {
      label,
      value: valueName,
      id: artistId,
      birthYear: artistBirthYear
    }
  }, [valueName, artistId, artistInfo?.birthYear])

  const [inputValue, setInputValue] = useState<string>(valueName ?? '')
  const keyword = useDebounce(inputValue, 500)
  const { data: catalogue = [] } = useSWR(keyword && [artQuery.artistList, keyword], searchArtistsBasic(database!))
  const { data: custom = [] } = useSWR(
    [typeQuery.artistName, CustomizedType.ArtArtistName],
    fetchCustomTypes(database!)
  )

  const options: ArtistOption[] = useMemo(() => {
    const currentOption: ArtistOption[] = displayOption ? [displayOption] : []
    const customOptions: ArtistOption[] = custom
      .map((name) => ({ label: name, value: name, id: undefined, birthYear: undefined })) // custom artistName has no artist id
      .filter(({ value, id }) => value !== currentOption[0]?.value && id !== currentOption[0]?.id)
    const catalogueOptions: ArtistOption[] = catalogue
      .map(({ name, birthYear, id }) => {
        const isUnknownBirthYear = birthYear === 'Unknown' || !birthYear
        const birthYearLabel = isUnknownBirthYear ? '' : `(b. ${birthYear})`
        const label = `${name} ${birthYearLabel}`
        return { label, value: name, id, birthYear: isUnknownBirthYear ? undefined : birthYear }
      })
      .filter(({ value, id }) => value !== currentOption[0]?.value && id !== currentOption[0]?.id)
    return [...currentOption, ...customOptions, ...catalogueOptions]
  }, [custom, catalogue, displayOption])

  const handleChange = (newValue: SingleValue<ArtistOption>, actionMeta: ActionMeta<any>) => {
    if ([newValue, actionMeta.option?.value].some((v) => /<[^>]*>/.test(v))) {
      toast({ variant: 'error', description: t('Toast.Error.InvalidInput') })
      return
    }
    if (newValue) {
      if (actionMeta.action === 'create-option') {
        addCustomType(database!, CustomizedType.ArtArtistName, newValue.value)
      }
      setInputValue(newValue.value)
      onChangeName(newValue.value)
      onSelectId && onSelectId(newValue.id)
    }
  }

  return (
    <div className={cn('grid grid-cols-1 gap-y-1', className)}>
      {label && (
        <label
          htmlFor={componentId}
          className={cn(
            'text-xs text-[#414554]',
            rules?.required !== undefined && 'after:ml-0.5 after:text-error after:content-["*"]'
          )}
        >
          {label}
        </label>
      )}
      <div
        className={'relative'}
        data-testid={`${name}-artist-creatable-autocomplete`}
        aria-label={`${name} artist creatable autocomplete`}
      >
        <CreatableAutocomplete
          innerRef={ref}
          inputId={componentId}
          instanceId={componentId}
          isMulti={false}
          onInputChange={(value) => setInputValue(value)}
          value={displayOption}
          onChange={handleChange}
          components={{ IndicatorsContainer: () => null }}
          options={options}
          noOptionsMessage={() => t('NoResultsFound')}
          {...fieldProps}
          placeholder={t('SearchOrCreate')}
        />
        {error && <AlertCircleIcon className={'absolute right-0 top-0.5 mx-2 my-1.5 text-red-500'} />}
      </div>
      {error?.message && <p className={'text-xs text-red-500'}>{error.message}</p>}
      {hint && <p className={'text-xs text-white'}>{hint}</p>}
    </div>
  )
}
