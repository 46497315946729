import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { cn } from '@/utils/classnames'
import { Button, FormInsurancePicker } from '@/components/base'

export type InsuranceAssetValues = {
  insuranceIds: string[]
}

const defaultValues: InsuranceAssetValues = {
  insuranceIds: []
}

interface InsuranceAssetFormProps {
  onSubmit: SubmitHandler<InsuranceAssetValues>
  onCancel: () => void
  submitText?: string
}

export function InsuranceAssetForm({ onCancel, onSubmit, submitText }: InsuranceAssetFormProps) {
  const { t } = useTranslation()
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit
  } = useForm<InsuranceAssetValues>({ defaultValues })

  return (
    <form className={' w-full gap-2 p-4 sm:flex sm:flex-col '} onSubmit={handleSubmit(onSubmit)}>
      <FormInsurancePicker control={control} name={'insuranceIds'} label={t('Field.Insurances')} />
      <fieldset className={'flex flex-col gap-2 sm:flex-row sm:justify-between'} disabled={isSubmitting}>
        <Button className={'w-full'} variant={'outline'} size={'md'} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button className={'group relative w-full '} variant={'solid'} size={'md'} type={'submit'} disabled={!isValid}>
          {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
          <span className={cn({ 'opacity-0': isSubmitting })}>{submitText || t('Update')}</span>
        </Button>
      </fieldset>
    </form>
  )
}
