import {
  Cryptocurrency,
  Command,
  Event,
  MultiCoinUnit,
} from "../types/cryptocurrencies";

import {
  AssetRelationStateWriter,
  Repo,
  newRepo as newAggregateRepo,
} from "../types/aggregate";
import {
  Amount,
  AssetType,
  Attachment,
  Currency,
  MultiCurrencyAmount,
  TargetCurrencyExchangeRateDataMap,
} from "../types/common";
import { EncryptionFieldKey } from "../encryption/utils";
import { addDecimal, UpdateObject } from "../utils";
import { ExchangeRate } from "../database/exchangeRate";
import { Valuation } from "../types/actions/valuation";
import { FullRefs, Refs, getAssetsByIds } from "../refs";
import { EncryptionManager } from "./encryption";
import { RoleToAsset } from "../types/relations";
import { DataPoisoned } from "../types/error";
import { DbSharedFields } from "../types/database";
import { MockPriceSource, PriceSource } from "./priceSource";
import { SummaryManager } from "../types/summaryManager";
import {
  CoreFirestore,
  Transaction,
  checkAndGetData,
  checkDuplicated,
  getQueriedData,
} from "../../coreFirebase";
import { TypeResult } from "../types/typeVersion";

export class CryptocurrencyRepo {
  protected readonly refs: FullRefs;
  protected readonly exRate: ExchangeRate;
  protected readonly summaryManager: SummaryManager;

  readonly Encryption: EncryptionManager;

  constructor(shared: DbSharedFields) {
    this.exRate = shared.exRate;
    this.refs = shared.refs;
    this.Encryption = shared.encryption;
    this.summaryManager = shared.summaryManager;
  }

  async getBaseOwnedValue(
    cryptos?: Pick<Cryptocurrency, "value" | "ownership">[],
    currency?: Currency
  ): Promise<Amount> {
    if (cryptos === undefined)
      cryptos = await getAllRawCryptocurrency(this.refs.currentRefs);
    this.exRate.checkInitialized();
    const exchangeRate = await this.exRate.getToTargetExchangeRates(
      currency || this.exRate.BaseCurrency!
    );
    return await calculateCryptocurrencyBaseOwnedValue(
      cryptos,
      exchangeRate,
      new MockPriceSource()
    );
  }

  private actionDocRef(id: string, valuationId: string) {
    return CoreFirestore.docFromCollection(
      this.refs.currentRefs.getActionCollectionRef<Valuation.Encrypted>(
        AssetType.Cryptocurrency,
        id
      ),
      valuationId
    );
  }

  async getAll(): Promise<Cryptocurrency[]> {
    const assets = await Promise.all(
      (
        await getAllRawCryptocurrency(this.refs.currentRefs)
      ).map((v) =>
        Cryptocurrency.decryptAndConvertDate(v, this.Encryption.current)
      )
    );
    return assets.sort((a, b) => b.updateAt.getTime() - a.updateAt.getTime());
  }

  async getByIds(ids: string[]): Promise<Cryptocurrency[]> {
    const result = await getAssetsByIds<Cryptocurrency.Encrypted>(
      this.refs.currentRefs,
      AssetType.Cryptocurrency,
      ids
    );
    return Promise.all(
      result.map((v) =>
        Cryptocurrency.decryptAndConvertDate(v, this.Encryption.current)
      )
    );
  }

  async getById(id: string): Promise<Cryptocurrency> {
    const docRef =
      this.refs.currentRefs.getAssetDocRef<Cryptocurrency.Encrypted>(
        AssetType.Cryptocurrency,
        id
      );
    return await CoreFirestore.getDoc(docRef)
      .then(checkAndGetData)
      .then((v) =>
        Cryptocurrency.decryptAndConvertDate(v, this.Encryption.current)
      );
  }

  async add(req: Cryptocurrency.CreateFields) {
    const newDocRef =
      this.refs.currentRefs.getAssetDocRef<Cryptocurrency.Encrypted>(
        AssetType.Cryptocurrency,
        req.id
      );
    Cryptocurrency.validateEncryptedPart(req);

    await CoreFirestore.runTransaction(async (transaction) => {
      await transaction.get(newDocRef).then(checkDuplicated);

      const repo = await CryptocurrencyRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const account = Cryptocurrency.fromCreate(
        req,
        this.refs.currentRefs.userId
      );
      Cryptocurrency.validateEncryptedPart(account, true);
      const encrypted = await Cryptocurrency.encrypt(
        account,
        this.Encryption.current
      );

      const ar = Cryptocurrency.newAggregateRoot(
        Cryptocurrency.defaultStateValue()
      );
      ar.handle(Command.createAsset(this.refs.selfRefs.userId, encrypted));
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async update(req: Cryptocurrency) {
    const docRef =
      this.refs.currentRefs.getAssetDocRef<Cryptocurrency.Encrypted>(
        AssetType.Cryptocurrency,
        req.id
      );
    Cryptocurrency.validateEncryptedPart(req);

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Cryptocurrency.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) Cryptocurrency.handleOutDated();

      const currentData = await Cryptocurrency.decryptAndConvertDate(
        currentUndecrypted,
        this.Encryption.current
      );
      const repo = await CryptocurrencyRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const {
        updates,
        metadata: { addedToGroup, removedFromGroup },
      } = Cryptocurrency.intoUpdate(currentData, req);
      const nameUpdated = updates.name;

      const encryptedUpdate: UpdateObject<Cryptocurrency.Encrypted> =
        Cryptocurrency.removeEncryptedFields(updates);

      if (nameUpdated) {
        const encryptionPart: Cryptocurrency.EncryptedPart = {
          name: nameUpdated,
        };
        const newEncrypted = await Cryptocurrency.encryptPartial(
          encryptionPart,
          this.Encryption.current
        );
        encryptedUpdate[EncryptionFieldKey] = newEncrypted[EncryptionFieldKey];
      }
      if (updates.attachments && updates.attachments !== null) {
        encryptedUpdate.attachments = await Attachment.encryptArray(
          updates.attachments,
          this.Encryption.current
        );
      }

      const ar = Cryptocurrency.newAggregateRoot(currentUndecrypted);
      ar.handle(
        Command.updateAsset(
          this.refs.selfRefs.userId,
          encryptedUpdate,
          addedToGroup,
          removedFromGroup
        )
      );
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async delete(id: string) {
    const docRef =
      this.refs.currentRefs.getAssetDocRef<Cryptocurrency.Encrypted>(
        AssetType.Cryptocurrency,
        id
      );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Cryptocurrency.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Cryptocurrency.handleOutDated();
      const repo = await CryptocurrencyRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );
      const ar = Cryptocurrency.newAggregateRoot(currentData);
      ar.handle(Command.deleteAsset(this.refs.selfRefs.userId));
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }
}

export namespace CryptocurrencyRepo {
  export async function removeContactRelation(
    refs: Refs,
    executerId: string,
    id: string,
    contactId: string,
    roles: RoleToAsset[]
  ) {
    const docRef = refs.getAssetDocRef<Cryptocurrency.Encrypted>(
      AssetType.Cryptocurrency,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Cryptocurrency.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) Cryptocurrency.handleOutDated();
      const encryptedUpdate: UpdateObject<Cryptocurrency.Encrypted> = {};
      roles.map((role) => {
        switch (role) {
          case RoleToAsset.Shareholder: {
            if (!currentUndecrypted.ownership)
              throw new DataPoisoned("ownership not found");
            const updateShareholder =
              currentUndecrypted.ownership.shareholder.filter(
                (s) => s.contactId !== contactId
              );
            if (
              updateShareholder.length ===
              currentUndecrypted.ownership.shareholder.length
            )
              throw new DataPoisoned("shareholderId not found");
            encryptedUpdate.ownership = {
              myOwnership: currentUndecrypted.ownership.myOwnership,
              shareholder: updateShareholder,
            };
            break;
          }
          case RoleToAsset.Beneficiary: {
            if (!currentUndecrypted.beneficiary)
              throw new DataPoisoned("beneficiary not found");
            const updateBeneficiary = currentUndecrypted.beneficiary.filter(
              (s) => s.contactId !== contactId
            );
            if (
              updateBeneficiary.length === currentUndecrypted.beneficiary.length
            )
              throw new DataPoisoned("beneficiaryId not found");
            encryptedUpdate.beneficiary = updateBeneficiary;
            break;
          }
        }
      });

      const repo = await newRepo(refs, transaction);
      const ar = Cryptocurrency.newAggregateRoot(currentUndecrypted);
      ar.handle(Command.updateAsset(executerId, encryptedUpdate));
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  export async function newRepo(
    refs: Refs,
    transaction: Transaction
  ): Promise<Repo<Cryptocurrency.Encrypted, Command, Event>> {
    return newAggregateRepo(
      transaction,
      refs.userId,
      AssetType.Cryptocurrency,
      new AssetRelationStateWriter(
        refs.getAssetCollectionRef(AssetType.Cryptocurrency),
        refs.Relations
      )
    );
  }
}

export async function getAllRawCryptocurrency(
  refs: Refs
): Promise<Cryptocurrency.Encrypted[]> {
  const collectionRef = refs.getAssetCollectionRef<Cryptocurrency.Encrypted>(
    AssetType.Cryptocurrency
  );
  return await CoreFirestore.getDocsFromCollection(
    collectionRef,
    CoreFirestore.where("ownerId", "==", refs.userId)
  ).then(getQueriedData);
}

export async function calculateCryptocurrencyBaseOwnedValue(
  cryptos: Pick<Cryptocurrency, "value" | "ownership">[],
  exchangeRate: TargetCurrencyExchangeRateDataMap,
  priceSource: PriceSource
): Promise<Amount> {
  const cryptoNames = new Set<string>();
  cryptos.forEach((c) => {
    Object.keys(c.value).forEach((k) => cryptoNames.add(k));
  });
  const priceMap = await priceSource.getCryptoPrice(Array.from(cryptoNames));

  let ownedValue = Amount.zero(exchangeRate.targetCurrency);
  cryptos.forEach((c) => {
    const value = MultiCoinUnit.toMultiCurrencyAmount(c.value, priceMap);
    ownedValue.value = addDecimal(
      MultiCurrencyAmount.calculateTargetCurrencyAmount(value, exchangeRate)
        .value,
      ownedValue.value
    );
  });
  return ownedValue;
}
