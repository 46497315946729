import { useCallback, useMemo } from 'react'
import { Loader2Icon } from 'lucide-react'
import { useFieldArray, useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { addContact, contactQuery, fetchContactOptions } from '@/api/ContactService'
import { cn } from '@/utils/classnames'
import { useAuthStore } from '@/store/authStore'
import { Button, FormCreatableAutocomplete, FormPercentInput } from '@/components/base'
import { PlusIcon, XIcon } from '@/components/icon'

export type BeneficiaryValues = {
  beneficiary: {
    contactId: string
    percent: number
  }[]
}

const defaultValues: BeneficiaryValues = {
  beneficiary: []
}

interface BeneficiaryFormProps {
  onSubmit: SubmitHandler<BeneficiaryValues>
  onCancel: () => void
  submitText?: string
}

export function BeneficiaryForm({ onCancel, onSubmit, submitText }: BeneficiaryFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)

  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    watch
  } = useForm<BeneficiaryValues>({ defaultValues })
  const beneficiary = watch('beneficiary')
  const { data: contactOptions, isValidating: loadingContacts } = useSWR(
    [contactQuery.options],
    fetchContactOptions(database!)
  )
  const beneficiaryArray = useFieldArray({
    control,
    name: 'beneficiary',
    rules: {
      validate: {
        percent: (owners) => owners.reduce((prev, { percent }) => prev + percent, 0) <= 100,
        notEmpty: (owners) => owners.every((owner) => owner.contactId !== '')
      }
    }
  })
  const controlledBeneficiary = beneficiaryArray.fields.map((f, i) => ({ ...f, ...beneficiary?.[i] }))
  const remainingBeneficiary = useMemo(() => {
    const total = controlledBeneficiary.reduce((acc, curr) => acc + curr.percent, 0)
    return 100 - total
  }, [controlledBeneficiary])
  const beneficiaryUnselectContact = useCallback(
    (index: number) => {
      const otherBeneficiaries = [...controlledBeneficiary.slice(0, index), ...controlledBeneficiary.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherBeneficiaries?.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledBeneficiary]
  )
  const addNewContact = async (firstName: string) => await addContact(database!, { firstName })

  return (
    <form className={' w-full gap-2 p-4 sm:flex sm:flex-col '} onSubmit={handleSubmit(onSubmit)}>
      <div className={'space-y-1'}>
        <div className={'flex items-center justify-between pr-10'}>
          <span className={'text-sm'}>{t('Field.Beneficiary')}</span>
          {remainingBeneficiary === 0 && <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>}
          {remainingBeneficiary > 0 && (
            <span className={'text-xs text-[#d89423]'}>{t('RemainingPercent', { percent: remainingBeneficiary })}</span>
          )}
          {remainingBeneficiary < 0 && <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>}
        </div>
        <ul className={'space-y-2 empty:hidden'}>
          {beneficiaryArray.fields.map((beneficiary, index) => (
            <li key={beneficiary.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
              <FormCreatableAutocomplete
                control={control}
                name={`beneficiary.${index}.contactId`}
                options={beneficiaryUnselectContact(index)}
                onCreate={addNewContact}
                isLoading={loadingContacts}
                placeholder={t('SearchOrCreate')}
              />
              <FormPercentInput control={control} name={`beneficiary.${index}.percent`} />
              <Button
                className={'rounded-full transition-colors hover:bg-grey/20'}
                onClick={() => beneficiaryArray.remove(index)}
              >
                <XIcon className={'text-primary'} />
              </Button>
            </li>
          ))}
        </ul>
        <Button
          className={'group justify-start gap-x-3 justify-self-start py-2'}
          onClick={() => beneficiaryArray.append({ contactId: '', percent: 0 })}
        >
          <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
            <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
          </div>
          <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
            {t('AddBeneficiaries')}
          </span>
        </Button>
      </div>
      <fieldset className={'flex flex-col gap-2 sm:flex-row sm:justify-between'} disabled={isSubmitting}>
        <Button className={'w-full'} variant={'outline'} size={'md'} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button className={'group relative w-full '} variant={'solid'} size={'md'} type={'submit'} disabled={!isValid}>
          {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
          <span className={cn({ 'opacity-0': isSubmitting })}>{submitText || t('Update')}</span>
        </Button>
      </fieldset>
    </form>
  )
}
