import { useState, type ComponentPropsWithoutRef } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { TotpMultiFactorGenerator, type TotpSecret } from 'firebase/auth'
import { Loader2Icon } from 'lucide-react'
import QRCode from 'qrcode'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { reauthenticatePassword } from '@/api/AccountService'
import { cn } from '@/utils/classnames'
import { isCredentialRelated, parseErrorMessage } from '@/utils/parseErrorMessages'
import { useToast } from '@/hooks/useToast'
import { useAuthStore } from '@/store/authStore'
import ProfilePage from '@/pages/account/profile'
import { Button, FormInput, Modal } from '@/components/base'

const authenticators = [
  'https://www.authy.com/download/',
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_CA&gl=US',
  'https://www.microsoft.com/en-us/security/mobile-authenticator-app'
]

export const authenticatorComponents = authenticators.map((href, index) => <ExternalLink key={index} href={href} />)

type TotpStep = 'verify' | 'qrcode' | 'enroll' | 'unenroll'

type TotpValues = {
  password: string
  code: string
}

const defaultValues: TotpValues = {
  password: '',
  code: ''
}

export default function Totp() {
  const { t } = useTranslation()
  const router = useRouter()
  const { toast } = useToast()
  const [dataUrl, setDataUrl] = useState<string | null>(null)
  const [secret, setSecret] = useState<TotpSecret | null>(null)
  const [step, setStep] = useState<TotpStep>('verify')
  const database = useAuthStore((state) => state.database)
  const form = useForm<TotpValues>({ defaultValues })

  const handleClose = () => router.push('/account/profile/')

  const handleContinue = () => setStep('enroll')

  const handleBack = () => setStep('qrcode')

  const onVerify = async ({ password }: TotpValues) => {
    try {
      await reauthenticatePassword(database!, password)
      const list = await database!.Account.getMultiFactorList()
      const totpList = list.filter(({ factorId }) => factorId === TotpMultiFactorGenerator.FACTOR_ID)
      const hasTotp = totpList.length > 0
      if (hasTotp) {
        setStep('unenroll')
      } else {
        setStep('qrcode')
        const user = await database!.Account.getUser()
        const session = await database!.Account.getMultiFactorSession()
        const secret = await TotpMultiFactorGenerator.generateSecret(session)
        const uri = secret.generateQrCodeUrl(user.email, 'MyAssets')
        const dataUrl = await QRCode.toDataURL(uri, { margin: 0 })
        setSecret(secret)
        setDataUrl(dataUrl)
      }
    } catch (e) {
      if (isCredentialRelated(e)) {
        toast({ variant: 'error', description: t('auth:ResetFormConfirmPassword') })
      } else {
        const errorMessage = parseErrorMessage(e, 'Unknown error: Verify Password')
        toast({ variant: 'error', description: errorMessage })
      }
    }
  }

  const onEnroll = async ({ code }: TotpValues) => {
    try {
      if (secret === null) throw Error('No totp secret')
      // enroll
      await database!.Account.enrollTotp(secret, code)
      handleClose()
    } catch (e) {
      const errorMessage = parseErrorMessage(e, 'Unknown error: Enroll Totp')
      toast({ variant: 'error', description: errorMessage })
    }
  }

  const onUnenroll = async ({ password }: TotpValues) => {
    try {
      const list = await database!.Account.getMultiFactorList()
      const totpList = list.filter(({ factorId }) => factorId === TotpMultiFactorGenerator.FACTOR_ID)
      await Promise.all(totpList.map((info) => database!.Account.unenrollMultiFactor(info)))
      toast({ variant: 'success', description: t('account:Disabled2FA') })
      handleClose()
    } catch (e) {
      const errorMessage = parseErrorMessage(e, 'Unknown error: Unenroll Totp')
      toast({ variant: 'error', description: errorMessage })
    }
  }

  return (
    <>
      <ProfilePage />

      <Modal
        className={cn(step === 'verify' && 'max-w-[460px]', step === 'enroll' && 'max-w-sm')}
        onBackdropClick={handleClose}
      >
        <Modal.Header className={'bg-primary'}>
          <label className={'text-sm font-medium uppercase text-white'}>{t('account:2FA')}</label>
          <Modal.CloseButton className={'text-white'} onClose={handleClose} />
        </Modal.Header>
        <Modal.Content>
          <>
            {step === 'verify' && (
              <form className={'space-y-5'} onSubmit={form.handleSubmit(onVerify)}>
                <div className={'space-y-3'}>
                  <p className={'text-sm text-[#6F7EA6]'}>{t('account:ProvidePassword')}</p>
                  <FormInput
                    control={form.control}
                    label={t('account:EnterYourPassword')}
                    name={'password'}
                    type={'password'}
                    // disable autofill
                    autoComplete={'new-password'}
                    placeholder={t('auth:Field.Password')}
                    rules={{ required: t('validation:Required') }}
                    data-testid={'totp-modal-password-input'}
                    aria-label={'totp-modal-password-input'}
                  />
                </div>
                <fieldset
                  className={'flex items-center justify-end gap-x-2 [&>button]:basis-[130px]'}
                  disabled={form.formState.isSubmitting}
                >
                  <Button
                    variant={'outline'}
                    size={'md'}
                    onClick={handleClose}
                    data-testid={'totp-modal-cancel-button'}
                    aria-label={'totp-modal-cancel-button'}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    variant={'solid'}
                    size={'md'}
                    type={'submit'}
                    data-testid={'totp-modal-verify-button'}
                    aria-label={'totp-modal-verify-button'}
                  >
                    <Loader2Icon className={'absolute animate-spin opacity-0 group-disabled:opacity-100'} />
                    <span className={'group-disabled:opacity-0'}>{t('Confirm')}</span>
                  </Button>
                </fieldset>
              </form>
            )}
            {step === 'qrcode' && (
              <form className={'space-y-4'} onSubmit={handleContinue}>
                <div className={'space-y-2.5 py-5'}>
                  <h4 className={'text-3xl font-semibold'}>{t('account:Setup2FAHint')}</h4>
                  <Trans
                    i18nKey={'account:ScanQRCode'}
                    parent={(props: any) => <p className={'text-sm text-text'} {...props} />}
                    components={authenticatorComponents}
                  />
                  <div className={'flex flex-col items-center gap-y-4 pt-5'}>
                    {dataUrl ? (
                      <Image src={dataUrl} alt={'QR Code'} width={152} height={152} />
                    ) : (
                      <div className={'h-[152px] w-[152px] animate-pulse bg-gray-200'} />
                    )}
                    <div className={'space-y-1'}>
                      <p className={'text-center text-xs text-text'}>{t('account:OrSecretKey')}</p>
                      <div
                        className={cn(
                          'grid h-8 w-[300px] place-content-center border-b border-primary bg-[#F6F6F6]',
                          !secret?.secretKey && 'animate-pulse'
                        )}
                      >
                        <span className={'text-sm text-black/50'}>{secret?.secretKey}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <fieldset className={'flex items-center gap-x-2 [&>button]:flex-1'}>
                  <Button variant={'outline'} size={'md'} onClick={handleClose}>
                    {t('Cancel')}
                  </Button>
                  <Button variant={'solid'} size={'md'} type={'submit'}>
                    {t('Continue')}
                  </Button>
                </fieldset>
              </form>
            )}
            {step === 'enroll' && (
              <form className={'space-y-4'} onSubmit={form.handleSubmit(onEnroll)}>
                <div className={'space-y-2.5'}>
                  <p className={'text-sm text-[#6F7EA6]'}>{t('account:ProvidePassword')}</p>
                  <FormInput
                    control={form.control}
                    label={t('account:EnterCode')}
                    name={'code'}
                    // disable autofill
                    autoComplete={'off'}
                    placeholder={t('account:Placeholder.EnterCode')}
                    rules={{ required: t('validation:Required') }}
                  />
                </div>
                <fieldset
                  className={'flex items-center gap-x-2 [&>button]:flex-1'}
                  disabled={form.formState.isSubmitting}
                >
                  <Button variant={'outline'} size={'md'} onClick={handleBack}>
                    {t('GoBack')}
                  </Button>
                  <Button className={'group'} variant={'solid'} size={'md'} type={'submit'}>
                    <Loader2Icon className={'absolute animate-spin opacity-0 group-disabled:opacity-100'} />
                    <span className={'group-disabled:opacity-0'}>{t('Verify')}</span>
                  </Button>
                </fieldset>
              </form>
            )}
            {step === 'unenroll' && (
              <form className={'space-y-4'} onSubmit={form.handleSubmit(onUnenroll)}>
                <div className={'space-y-2.5'}>
                  <p className={'whitespace-pre-line text-sm text-[#6F7EA6]'}>{t('account:Remove2FAHint')}</p>
                </div>
                <fieldset
                  className={'flex items-center gap-x-2 [&>button]:flex-1'}
                  disabled={form.formState.isSubmitting}
                >
                  <Button variant={'outline'} size={'md'} onClick={handleClose}>
                    {t('Cancel')}
                  </Button>
                  <Button className={'group'} variant={'solid'} size={'md'} type={'submit'}>
                    <Loader2Icon className={'absolute animate-spin opacity-0 group-disabled:opacity-100'} />
                    <span className={'group-disabled:opacity-0'}>{t('Confirm')}</span>
                  </Button>
                </fieldset>
              </form>
            )}
          </>
        </Modal.Content>
      </Modal>
    </>
  )
}

function ExternalLink({ children, href }: ComponentPropsWithoutRef<'a'>) {
  return (
    <a
      className={'text-sm font-bold text-primary hover:underline'}
      href={href}
      target={'_blank'}
      rel={'noopener noreferrer'}
    >
      {children}
    </a>
  )
}
