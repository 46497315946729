import * as React from 'react'
import { useController, type Control, type FieldPath, type FieldValues, type RegisterOptions } from 'react-hook-form'

import { cn } from '@/utils/classnames'
import { Button } from '@/components/base'

type Option = {
  label: string
  value: string
}

interface TypePickerProps<T extends FieldValues, U extends FieldPath<T>> {
  label?: string
  options: Option[]
  control: Control<T>
  name: U
  rules?: RegisterOptions<T>
  onChanged?: (prev: string, next: string) => void
}

export function TypePicker<T extends FieldValues, U extends FieldPath<T>>({
  label,
  options,
  control,
  name,
  rules,
  onChanged
}: TypePickerProps<T, U>): JSX.Element {
  const id = React.useId()
  const { field } = useController({ control, name, rules })

  return (
    <div className={'grid gap-y-1'}>
      {label && (
        <label
          htmlFor={id}
          className={cn(
            'text-xs text-[#414554]',
            rules?.required !== undefined && 'after:ml-0.5 after:text-error after:content-["*"]'
          )}
        >
          {label}
        </label>
      )}
      <div id={id} className={'flex h-[38px] flex-nowrap items-stretch overflow-hidden rounded border border-primary'}>
        {options.map((option, index) => (
          <Button
            key={index}
            className={cn(
              'flex-1 whitespace-nowrap rounded-none text-sm font-light text-text',
              option.value === field.value ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'
            )}
            onClick={() => {
              const newValue = option.value
              field.onChange(newValue)
              onChanged?.(field.value, newValue)
            }}
            data-testid={`${name}-type-picker`}
            aria-label={`${name}-type-picker`}
          >
            {option.label}
          </Button>
        ))}
      </div>
    </div>
  )
}
