import { useId } from 'react'
import { AlertCircleIcon, SearchIcon } from 'lucide-react'
import { useController, type Control, type FieldPath, type FieldValues, type RegisterOptions } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { InsuranceSubType } from 'core/remodel/types/insurance'
import { fetchInsurancesByType, insuranceQuery } from '@/api/InsuranceService'
import { cn } from '@/utils/classnames'
import { useAuthStore } from '@/store/authStore'
import { Autocomplete, Button } from '@/components/base'
import { XIcon } from '@/components/icon'

interface FormInsurancePickerProps<Values extends FieldValues, Path extends FieldPath<Values>> {
  label: string
  control: Control<Values>
  name: Path
  rules?: RegisterOptions<Values, Path>
}

export function FormInsurancePicker<Values extends FieldValues, Path extends FieldPath<Values>>({
  label,
  control,
  name,
  rules
}: FormInsurancePickerProps<Values, Path>) {
  const { t } = useTranslation()
  const id = useId()
  const {
    field,
    fieldState: { error }
  } = useController({ control, name, rules })
  const { value = [] as string[], onChange, ref, ...fieldProps } = field
  const database = useAuthStore((state) => state.database)
  const { data: insurances, isLoading } = useSWR(
    [insuranceQuery.list, InsuranceSubType.General],
    fetchInsurancesByType(database!)
  )
  const insuranceOptions = insurances?.map((insurance) => ({ label: insurance.name, value: insurance.id }))
  const displayOptions = insuranceOptions?.filter((option) => !value.includes(option.value))
  const isRequired = rules?.required !== undefined
  const hasError = error !== undefined

  const getLabel = (id: string) => insuranceOptions?.find((option) => option.value === id)?.label ?? '-'
  const handleChange = (newValue: any) => {
    const newInsuranceId = newValue.value
    onChange([...value, newInsuranceId])
  }
  const handleRemove = (id: string) => {
    const newValue = value.filter((v: string) => v !== id)
    onChange(newValue)
  }

  return (
    <div className={'space-y-2'}>
      {/* Search Insurance */}
      <div className={'grid grid-cols-1 gap-y-1'}>
        <label
          htmlFor={id}
          className={cn('text-xs text-[#414554]', { 'after:ml-0.5 after:text-error after:content-["*"]': isRequired })}
        >
          {label}
        </label>
        <div className={'relative'}>
          <Autocomplete
            innerRef={ref}
            inputId={id}
            instanceId={id}
            components={{ IndicatorsContainer: () => <SearchIcon className={'mr-2 text-primary'} size={20} /> }}
            value={{ label: '', value: '' }}
            onChange={handleChange}
            options={displayOptions}
            placeholder={t('Search')}
            noOptionsMessage={() => t('NoResultsFound')}
            isLoading={isLoading}
            {...fieldProps}
            data-testid={`${name}-insurance-picker`}
            aria-label={`${name}-insurance-picker`}
          />
          {hasError && <AlertCircleIcon className={'absolute right-0 top-0.5 mx-2 my-1.5 text-red-500'} />}
        </div>
        {hasError && <p className={'text-xs text-red-500'}>{error.message}</p>}
      </div>
      {/* Insurances */}
      {value && value.length > 0 && (
        <ul className={'space-y-1'}>
          {value.map((id: string) => (
            <li key={id} className={'flex items-center justify-between'}>
              <p className={'text-sm'}>{getLabel(id)}</p>
              <Button
                className={'rounded-full p-1 transition-colors hover:bg-grey/20'}
                onClick={() => handleRemove(id)}
              >
                <XIcon className={'text-primary'} size={20} />
              </Button>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
