import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check, Minus } from 'lucide-react'
import { useController, type Control, type FieldPath, type FieldValues, type RegisterOptions } from 'react-hook-form'

import { cn } from '@/utils/classnames'

export const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'peer h-5 w-5 shrink-0 rounded-sm border-2 border-primary text-primary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
      {props.checked === true && <Check className={'h-4 w-4'} />}
      {props.checked === 'indeterminate' && <Minus className={'h-4 w-4'} />}
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

interface FormCheckboxProps<T extends FieldValues, U extends FieldPath<T>>
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  label: string
  name: U
  control: Control<T>
  rules?: RegisterOptions<T, U>
  labelClassName?: string
  onChanged?: (newVal: boolean) => void
}

export function FormCheckbox<T extends FieldValues, U extends FieldPath<T>>({
  label,
  name,
  control,
  rules,
  className,
  labelClassName,
  onChanged,
  ...props
}: FormCheckboxProps<T, U>) {
  const id = React.useId()
  const { field } = useController({ name, rules, control })
  const { value, onChange, ...fieldProps } = field

  const handleOnCheckedChange = (checked: boolean) => {
    onChange(checked)
    onChanged?.(checked)
  }
  return (
    <div className={cn('flex items-center gap-x-2', className)}>
      <Checkbox
        id={id}
        checked={field.value}
        onCheckedChange={handleOnCheckedChange}
        {...props}
        {...fieldProps}
        data-testid={`${name}-checkbox`}
        aria-label={`${name}-checkbox`}
      />
      <label className={cn('cursor-pointer select-none text-sm text-[#414554]', labelClassName)} htmlFor={id}>
        {label}
      </label>
    </div>
  )
}
