import * as React from 'react'
import {
  useController,
  useWatch,
  type Control,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type RegisterOptions
} from 'react-hook-form'
import { NumericFormat, SourceInfo, type NumberFormatValues } from 'react-number-format'

import { NumberFormat } from 'core/remodel/types/common'
import { cn } from '@/utils/classnames'
import { resolveNumberFormat } from '@/utils/formatter'
import { Input } from '@/components/base'

interface FormNumberInputProps<Values extends FieldValues, Path extends FieldPath<Values>> {
  className?: string
  label?: string
  prefix?: string
  disabled?: boolean
  format: string | undefined
  digits?: number
  allowNegative?: boolean
  placeholder?: string
  isAllowed?: (values: NumberFormatValues) => boolean
  maxValue?: number
  loading?: boolean
  // controller props
  control: Control<Values>
  name: Path
  rules?: RegisterOptions<Values, Path>
  defaultValue?: FieldPathValue<Values, Path>
  errorClassName?: string
  onChanged?: (value: number) => void
  onFocus?: () => void
}

export function FormNumberInput<T extends FieldValues, U extends FieldPath<T>>({
  className,
  label,
  prefix,
  format,
  digits = 0,
  allowNegative = false,
  placeholder = '0.00',
  isAllowed,
  maxValue = 999999999999, // 12 digits
  name,
  control,
  rules,
  defaultValue,
  errorClassName,
  onChanged,
  onFocus,
  loading,
  ...props
}: FormNumberInputProps<T, U>) {
  const id = React.useId()
  const {
    field,
    fieldState: { error }
  } = useController({ control, name, rules, defaultValue })
  const value = useWatch({ control, name })
  const { value: _value, onChange, ref, ...fieldProps } = field
  const formatConfig = resolveNumberFormat(format as NumberFormat)
  const isRequired = rules?.required !== undefined

  // https://s-yadav.github.io/react-number-format/docs/props#onvaluechange-values-sourceinfo--
  const handleChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
    const value = values.floatValue ?? 0
    onChange(value)
    sourceInfo.source === 'event' && onChanged?.(value)
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    e.target.select()
    onFocus?.()
  }

  const combinedIsAllowed = (values: NumberFormatValues) => {
    const { floatValue } = values
    if (floatValue === undefined) return true
    if (floatValue > maxValue) return false
    return isAllowed?.(values) ?? true
  }

  return (
    <div className={cn('grid gap-y-1', className)}>
      {label && (
        <label
          htmlFor={id}
          className={cn('text-xs text-[#414554]', isRequired && 'after:ml-0.5 after:text-error after:content-["*"]')}
        >
          {label}
        </label>
      )}
      <div className={'flex items-center rounded border bg-white focus-within:border-primary'}>
        {prefix && (
          <div
            className={
              'flex h-full basis-16 items-center justify-center border-r bg-grey-input font-light text-gray-400'
            }
          >
            {prefix}
          </div>
        )}
        {loading ? (
          <div className={'flex h-9 w-full items-center rounded bg-grey-input px-3'}>
            <div className={'h-5 w-full animate-pulse rounded bg-grey'}></div>
          </div>
        ) : (
          <NumericFormat
            id={id}
            className={cn('text-right focus:ring-0', className)}
            getInputRef={ref}
            value={value || ''}
            onValueChange={handleChange}
            onFocus={handleFocus}
            customInput={Input}
            decimalScale={digits}
            decimalSeparator={formatConfig.decimalCharacter}
            thousandSeparator={formatConfig.digitGroupSeparator}
            allowNegative={allowNegative}
            allowLeadingZeros={false}
            isAllowed={combinedIsAllowed}
            placeholder={placeholder}
            {...fieldProps}
            {...props}
            data-testid={`${name}-number-input`}
            aria-label={`${name}-number-input`}
          />
        )}
      </div>
      {error?.message && <p className={cn('text-xs text-red-500', errorClassName)}>{error.message}</p>}
    </div>
  )
}
