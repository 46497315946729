import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { AuthErrorCodes } from 'firebase/auth'
import { motion } from 'framer-motion'
import { Loader2Icon } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { PopupMethod } from '@/types/common'
import { parseErrorMessage } from '@/utils/parseErrorMessages'
import { useToast } from '@/hooks/useToast'
import { useTrackedCallback } from '@/hooks/useTracking'
import { useAuthStore } from '@/store/authStore'
import { LoginForm, type LoginValues } from '@/components/form'

export default function LoginPage() {
  const router = useRouter()
  const searchParams = useSearchParams()
  const kbArticleId = searchParams.get('kb-articleId')
  const invite = searchParams.get('invite')
  const { t } = useTranslation()
  const { toast } = useToast()
  const status = useAuthStore((state) => state.status)
  const login = useAuthStore((state) => state.login)
  const [submitting, setSubmitting] = useState(false)
  const loginWithPopup = useAuthStore((state) => state.loginWithPopup)
  const values: LoginValues | undefined = useMemo(() => {
    try {
      if (!invite) throw new Error('Empty invite')
      const { id, name, email } = JSON.parse(Buffer.from(invite, 'base64').toString('utf-8'))
      if ([id, name, email].some((v) => !v)) throw new Error('Invalid invite')
      return { email, password: '' }
    } catch {
      return undefined
    }
  }, [invite])

  useEffect(() => {
    switch (status) {
      case 'ready': {
        toast({ variant: 'success', description: t('auth:Toast.Success.SignIn') })
        router.push('/identity/')
        break
      }
      case 'unverified': {
        router.push('/auth/verify-email/')
        break
      }
      case 'closing': {
        router.push('/auth/reactivate/')
        break
      }
    }
  }, [router, status, t, toast])

  const handleSubmit = useTrackedCallback(
    async (data: LoginValues) => {
      try {
        setSubmitting(true)
        await login(data)
      } catch (e: any) {
        if (e.code === AuthErrorCodes.MFA_REQUIRED) {
          router.push('/auth/two-factor')
        } else {
          const errorMessage = parseErrorMessage(e, 'Unknown error: login')
          toast({ variant: 'error', description: errorMessage })
          setSubmitting(false)
        }
      }
    },
    [login, router, toast],
    'login-via-email'
  )

  const handlePopupLogin = useTrackedCallback(
    async (method: PopupMethod) => {
      try {
        setSubmitting(true)
        await loginWithPopup(method)
      } catch (e: any) {
        if (e.code === AuthErrorCodes.MFA_REQUIRED) {
          router.push('/auth/two-factor')
        } else {
          const errorMessage = parseErrorMessage(e, 'Unknown error: login')
          toast({ variant: 'error', description: errorMessage })
          setSubmitting(false)
        }
      }
    },
    [loginWithPopup, router, toast],
    'login-via-popup'
  )

  useEffect(() => {
    if (kbArticleId) {
      console.log('kbArticleId', kbArticleId)
      localStorage.setItem('kb-articleId', kbArticleId)
    }
  }, [kbArticleId])

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={'relative grid h-screen w-full place-items-center'}
    >
      {submitting ? (
        <Loader2Icon className={'h-8 w-8 animate-spin text-primary'} />
      ) : (
        <div>
          <LoginForm onSubmit={handleSubmit} onPopupLogin={handlePopupLogin} values={values} />
          <Policies />
        </div>
      )}
    </motion.main>
  )
}

export function Policies() {
  const { t } = useTranslation()
  return (
    <div className={'mt-5 flex justify-center gap-4 text-sm/4 text-[#629EA7]'}>
      <a
        className={'hover:underline'}
        href={'https://myassets.com/privacy-policy'}
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        {t('auth:PrivacyPolicy')}
      </a>
      <a
        className={'hover:underline'}
        href={'https://myassets.com/terms-and-conditions'}
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        {t('auth:TermsAndConditions')}
      </a>
      <a
        className={'hover:underline'}
        href={'https://myassets.com/cookie-policy'}
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        {t('auth:CookiePolicy')}
      </a>
      <a
        className={'hover:underline'}
        href={'https://myassets.com/support'}
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        {t('auth:NeedHelp')}
      </a>
    </div>
  )
}

LoginPage.isAuthRoute = true
