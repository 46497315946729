import Decimal from "decimal.js";
import { exRate } from "../../mockExRate";
import {
  Amount,
  Currency,
  ExchangeRateData,
  MultipleCurrencyValuation,
  TargetCurrencyExchangeRateDataMap,
  Optional,
} from "../types/common";
import { ExRateNotSet } from "../types/error";
import { ExchangeRateResult } from "../types/postgres";

const AllowedDecimalPlaces = 2;
interface AmountWithRateData {
  amount: Amount;
  rateData: ExchangeRateData;
}

type WithFetchDate<T> = T & { fetchedAt: number };
export interface ExchangeRateDataMap {
  [currencyKind: string]: ExchangeRateData;
}

export interface ExchangeRateDataStoreMap {
  [currencyKind: string]: WithFetchDate<ExchangeRateData>;
}

//#NOTE since there's a difference in rate between rate(A to B) and 1 / rate(B to A), we must "multiply" amount with the right conversion rate if it exist and cannot do any "division".
// - the store will be grouped by quoteCurrency(we need every conversion rate to specific currency so it'll be every currency as BaseCurrency and the target as quoteCurrency)
type ExchangeRateDataStore = {
  version?: number;
  rates: {
    [quoteCurrency: string]: {
      data: ExchangeRateDataStoreMap;
      fetchedAt?: number;
    };
  };
};
const CURRENT_VERSION = 1;

export interface ExchangeRateSource {
  getExchangeRate(
    primary?: Currency,
    secondary?: Currency
  ): Promise<ExchangeRateResult[]>;
}

export class MockRateSource implements ExchangeRateSource {
  async getExchangeRate(
    primary?: Currency,
    secondary?: Currency
  ): Promise<ExchangeRateResult[]> {
    if (primary === undefined && secondary === undefined) {
      throw new Error(
        "Primary and secondary currency cannot both be undefined"
      );
    }
    if (primary !== undefined && secondary !== undefined) {
      return [
        {
          baseCurrency: primary,
          quoteCurrency: secondary,
          rate: exRate[primary][secondary],
          date: new Date(),
        },
      ];
    } else if (primary) {
      return Object.keys(exRate[primary]).map((key) => {
        return {
          baseCurrency: primary,
          quoteCurrency: key,
          rate: exRate[primary][key as Currency],
          date: new Date(),
        };
      });
    } else {
      return Object.values(Currency).map((currency) => {
        return {
          baseCurrency: currency,
          quoteCurrency: secondary!,
          rate: exRate[currency][secondary as Currency],
          date: new Date(),
        };
      });
    }
  }
}

class ExchangeRateDataManager {
  private exchangeRateDataStore: ExchangeRateDataStore = {
    version: CURRENT_VERSION,
    rates: {},
  };
  private timeoutMillisecond: number;
  private setSessionStoragePromise?: Promise<void>;

  //4 hours
  constructor(timeoutMillisecond: number = 14400000) {
    this.timeoutMillisecond = timeoutMillisecond;
    if (typeof sessionStorage !== "undefined" && sessionStorage !== undefined) {
      const data = sessionStorage.getItem("MA_EXCHANGE_RATE");
      if (data) {
        let parsed: ExchangeRateDataStore = JSON.parse(data);
        if (parsed.version === CURRENT_VERSION) {
          this.exchangeRateDataStore = parsed;
        }
      }
    }
  }

  private setSessionStorage() {
    if (typeof sessionStorage === "undefined" || sessionStorage === undefined)
      return;
    sessionStorage.setItem(
      "MA_EXCHANGE_RATE",
      JSON.stringify(this.exchangeRateDataStore)
    );
  }

  private queueSetSessionStorage() {
    if (this.setSessionStoragePromise) return;
    this.setSessionStoragePromise = new Promise((resolve) =>
      setTimeout(() => {
        this.setSessionStorage();
        this.setSessionStoragePromise = undefined;
        resolve();
      }, 5000)
    );
  }

  getByQuoteCurrency(quoteCurrency: Currency): Optional<ExchangeRateDataMap> {
    const data = this.exchangeRateDataStore.rates[quoteCurrency];
    if (
      !data ||
      !data.fetchedAt ||
      data.fetchedAt + this.timeoutMillisecond < new Date().getTime()
    ) {
      return undefined;
    } else {
      return data.data;
    }
  }
  getByPair(
    baseCurrency: Currency,
    quoteCurrency: Currency
  ): Optional<ExchangeRateData> {
    const data = this.exchangeRateDataStore.rates[quoteCurrency];
    if (
      !data ||
      !data.data ||
      !data.data[baseCurrency] ||
      !data.data[baseCurrency].fetchedAt ||
      data.data[baseCurrency].fetchedAt + this.timeoutMillisecond <
        new Date().getTime()
    )
      return undefined;
    else return data.data[baseCurrency];
  }
  setByQuoteCurrency(quoteCurrency: Currency, input: ExchangeRateDataMap) {
    let data = input as ExchangeRateDataStoreMap;
    let fetchedAt = new Date().getTime();
    for (const key of Object.keys(data)) {
      if (data[key]) {
        data[key].fetchedAt = fetchedAt;
      }
    }
    this.exchangeRateDataStore.rates[quoteCurrency] = {
      data,
      fetchedAt,
    };
    this.queueSetSessionStorage();
  }
  setByPair(
    baseCurrency: Currency,
    quoteCurrency: Currency,
    data: ExchangeRateData
  ) {
    if (!this.exchangeRateDataStore.rates[quoteCurrency]) {
      this.exchangeRateDataStore.rates[quoteCurrency] = {
        data: {},
      };
    }
    this.exchangeRateDataStore.rates[quoteCurrency].data[baseCurrency] = {
      rate: data.rate,
      date: data.date,
      fetchedAt: new Date().getTime(),
    };
    this.queueSetSessionStorage();
  }
}

export class ExchangeRate {
  private BaseExRate: Optional<ExchangeRateDataMap>;
  BaseCurrency: Optional<Currency>;
  ExchangeRateSource: ExchangeRateSource;
  private exchangeRateDataStore: ExchangeRateDataManager =
    new ExchangeRateDataManager();

  constructor(exchangeRateSource: ExchangeRateSource) {
    this.ExchangeRateSource = exchangeRateSource;
    this.BaseExRate = undefined;
    this.BaseCurrency = undefined;
  }

  checkInitialized() {
    if (!this.BaseCurrency || !this.BaseExRate) throw new ExRateNotSet();
  }

  //#HACK mock missing exchange rate
  private async checkFetchedData(
    baseCurrency: Currency,
    exchangeRateData: ExchangeRateDataMap
  ) {
    let missing = false;
    let now = new Date();
    for (const currency of Object.values(Currency)) {
      if (!exchangeRateData[currency]) {
        missing = true;
        console.log(
          `Missing exchange rate data for ${baseCurrency}${currency}. Mocked to 1`
        );
        exchangeRateData[currency] = {
          rate: 1,
          date: now,
        };
      }
      // NOTE check if rate is 0
      if (exchangeRateData[currency].rate === 0) {
        const exRateData = await this.getExchangeRate(baseCurrency, currency);
        if (exRateData.rate === 0) {
          exchangeRateData[currency].rate = 1;
          exchangeRateData[currency].date = now;
          console.log(
            `Exchange rate data for ${currency} to ${baseCurrency} is 0, mocked to 1`
          );
        } else {
          exchangeRateData[currency].rate = new Decimal(1)
            .div(exRateData.rate)
            .toNumber();
          exchangeRateData[currency].date = exRateData.date;
        }
      }
    }
    if (missing) {
      console.log(
        `Missing some exchange rate data for display currency ${baseCurrency}. Mocked to 1`
      );
    }
  }

  async getAndSetBaseExRate(baseCurrency: Currency) {
    this.BaseExRate = await this.getAllExchangeRate(baseCurrency);
    this.BaseCurrency = baseCurrency;
  }

  amountToBase(input: Amount): Amount {
    return {
      value: this.amountValueToBaseWithData(
        input.currency as Currency,
        input.value
      ).value,
      currency: this.BaseCurrency!,
    };
  }

  amountValueToBaseWithData(
    currency: Currency,
    input: number
  ): { value: number; rateData: ExchangeRateData } {
    const rateData = this.getToBaseExchangeRate(currency);
    return {
      value: new Decimal(input)
        .mul(rateData.rate)
        .toDecimalPlaces(AllowedDecimalPlaces)
        .toNumber(),
      rateData,
    };
  }

  getToBaseExchangeRate(to: Currency): ExchangeRateData {
    this.checkInitialized();
    const exRateData = this.BaseExRate![to];
    if (exRateData) {
      return {
        rate: exRateData.rate,
        date: exRateData.date,
      };
    } else {
      console.error("No exchange rate data for", to);
      return {
        rate: 1,
        date: new Date(),
      };
    }
  }

  async getToTargetExchangeRates(
    to: Currency
  ): Promise<TargetCurrencyExchangeRateDataMap> {
    this.checkInitialized();
    const exRateDataMap = await this.getAllExchangeRate(to);
    const rates: ExchangeRateDataMap = {};
    for (const key of Object.keys(exRateDataMap)) {
      let rate =
        exRateDataMap[key].rate !== 0
          ? exRateDataMap[key].rate
          : new Decimal(1)
              .div((await this.getExchangeRate(key as Currency, to)).rate)
              .toNumber();
      if (rate === 0) {
        rate = 1;
        console.error(`Exchange rate is 0 for ${key} to ${to}, mocked to 1`);
      }
      rates[key] = {
        rate,
        date: exRateDataMap[key].date,
      };
    }
    return {
      targetCurrency: to,
      rates,
    };
  }

  private async fetchQuoteData(quoteCurrency: Currency) {
    try {
      const exchangeRateData = await this.ExchangeRateSource.getExchangeRate(
        undefined,
        quoteCurrency
      );

      const data = exchangeRateData.reduce((acc, val) => {
        acc[val.baseCurrency] = {
          rate: val.rate,
          date: val.date,
        };
        return acc;
      }, {} as ExchangeRateDataMap);
      data[quoteCurrency] = { rate: 1, date: new Date() };
      await this.checkFetchedData(quoteCurrency, data);
      return data;
    } catch (e) {
      //#TODO remove this when staging has exchange rate data
      console.log("Error fetching exchange rate data");
      console.log("USING MOCK EXCHANGE RATE NOW");
      const date = new Date();
      const mockExchangeRateData = await new MockRateSource().getExchangeRate(
        undefined,
        quoteCurrency
      );
      const data = mockExchangeRateData.reduce((acc, val) => {
        acc[val.baseCurrency] = {
          rate: val.rate,
          date: val.date,
        };
        return acc;
      }, {} as ExchangeRateDataMap);
      data[quoteCurrency] = { rate: 1, date };
      await this.checkFetchedData(quoteCurrency, data);
      return data;
    }
  }
  private async updateBaseCurrency(baseCurrency: Currency) {
    let data = this.exchangeRateDataStore.getByQuoteCurrency(baseCurrency);
    if (!data) {
      data = await this.fetchQuoteData(baseCurrency);
      this.exchangeRateDataStore.setByQuoteCurrency(baseCurrency, data);
      this.BaseExRate = data;
    }
  }
  private async getAllExchangeRate(
    currency: Currency
  ): Promise<ExchangeRateDataMap> {
    const maybeData = this.exchangeRateDataStore.getByQuoteCurrency(currency);

    if (maybeData) {
      return maybeData;
    }

    if (this.BaseCurrency) {
      await this.updateBaseCurrency(this.BaseCurrency);
    }
    if (currency == this.BaseCurrency && this.BaseExRate) {
      return this.BaseExRate;
    }

    let data = await this.fetchQuoteData(currency);
    this.exchangeRateDataStore.setByQuoteCurrency(currency, data);
    return data;
  }

  async getExchangeRate(
    from: Currency,
    to: Currency
  ): Promise<ExchangeRateData> {
    if (from === to) {
      return {
        rate: 1,
        date: new Date(),
      };
    }
    let maybeCached = this.exchangeRateDataStore.getByPair(from, to);
    if (maybeCached) {
      return maybeCached;
    }
    try {
      const exchangeRateData = await this.ExchangeRateSource.getExchangeRate(
        from,
        to
      );
      if (exchangeRateData.length === 0) {
        console.error("No exchange rate data for", from, to);
        return {
          rate: 1,
          date: new Date(),
        };
      } else {
        return {
          rate: exchangeRateData[0].rate,
          date: exchangeRateData[0].date,
        };
      }
    } catch (e) {
      //#TODO remove this when staging has exchange rate data
      console.log("Error fetching exchange rate data");
      console.log("USING MOCK EXCHANGE RATE NOW");
      const date = new Date();
      return {
        rate: exRate[from][to],
        date,
      };
    }
  }

  async amountToCurrencyWithData(
    value: Amount,
    currency: Currency
  ): Promise<AmountWithRateData> {
    const rateData = await this.getExchangeRate(
      value.currency as Currency,
      currency
    );
    return {
      amount: {
        value: new Decimal(value.value)
          .mul(rateData.rate)
          .toDecimalPlaces(AllowedDecimalPlaces)
          .toNumber(),
        currency,
      },
      rateData,
    };
  }

  multipleCurrencyValueToBase(input: MultipleCurrencyValuation): Amount {
    this.checkInitialized();
    const value = Object.entries(input)
      .reduce((sum, [currency, value]) => {
        return new Decimal(value)
          .mul(this.getToBaseExchangeRate(currency as Currency).rate)
          .toDecimalPlaces(AllowedDecimalPlaces)
          .add(sum);
      }, new Decimal(0))
      .toNumber();
    return {
      currency: this.BaseCurrency! as Currency,
      value,
    };
  }
}
