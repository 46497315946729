import {
  Amount,
  AssetType,
  AssetV2,
  MultiCurrencyAmount,
  compareGroupUpdate,
  Attachment,
  PathsOfAmountField,
  PeriodWithNumber,
} from "../common";
import { EncryptedType, RequireEncryptionFields } from "../../encryption/utils";
import { ErrorDataOutDated, InvalidInput } from "../error";
import {
  addDecimal,
  subDecimal,
  CompDifference,
  OptionalSimpleTypeKeysOf,
  SimpleTypeKeysOf,
  UpdateObject,
  buildArrayUpdate,
  buildObjectUpdate,
  deepCopy,
} from "../../utils";
import {
  Account,
  AccountState,
  AccountTransaction,
  Category,
  Command,
  Event,
  ToTagPairFunction,
} from "../cashAndBanking";
import {
  SupportLiabilityType,
  supportLiabilityTypes,
} from "../cashAndBankingSummary";
import { CoreFirestore, WithFieldValue } from "../../../coreFirebase";
import {
  LoanTypeVersion,
  VersionedType,
  VersionedTypeString,
  validateTypeUpToDate,
} from "../typeVersion";

export interface Allocation {
  assetId: string;
  percent: number;
  assetType: SupportLiabilityType;
}
export function AllocationEqual(a: Allocation, b: Allocation): boolean {
  return (
    a.assetId === b.assetId &&
    a.percent === b.percent &&
    a.assetType === b.assetType
  );
}

export interface Loan extends Account.Base {
  "@type": VersionedTypeString<VersionedType.Loan, 2>;
  subtype: Account.Type.LoanAccount;

  // @Encrypted
  accountNumber?: string;

  //outstandingAmount * -1 stored in `value`;
  initialAmount: Amount;
  startDate: Date;

  term: PeriodWithNumber;
  interest: PeriodWithNumber;
  commission?: Amount;
  // @Encrypted
  SWIFT_BIC?: string;
  sortCode?: string;
  allocations: Allocation[];
}
export namespace Loan {
  export function assureVersion(
    input: Loan | Encrypted,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(input, LoanTypeVersion, errorOnCoreOutDated);
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.Loan);
  }

  export const amountPaths: readonly PathsOfAmountField<Loan>[] = [
    "commission",
    "initialAmount",
    "value",
  ] as const;
  export type Create = Pick<
    Loan,
    | "id"
    | "name"
    | "subtype"
    | "country"
    | "institution"
    | "initialAmount"
    | "value"
    | "startDate"
    | "notes"
    | "term"
    | "interest"
    | "commission"
    | "accountNumber"
    | "SWIFT_BIC"
    | "sortCode"
    | "groupIds"
    | "allocations"
    | "extSource"
    | "extSourceId"
    | "extId"
    | "attachments"
  >;
  export type Update = Pick<
    Loan,
    | "name"
    | "country"
    | "institution"
    | "commission"
    | "notes"
    | "accountNumber"
    | "SWIFT_BIC"
    | "sortCode"
    | "term"
    | "interest"
    | "groupIds"
    | "allocations"
    | "attachments"
  >;
  export type UpdateEncrypted = RequireEncryptionFields<
    EncryptedType<Update, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;
  export type EncryptedKeys =
    | AssetV2.EncryptedKeys
    | "accountNumber"
    | "SWIFT_BIC";
  export type Encrypted = RequireEncryptionFields<
    EncryptedType<Loan, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;
  export type EncryptedPart = Pick<Loan, EncryptedKeys>;

  export function fromCreate(from: Create, ownerId: string): Loan {
    const data: WithFieldValue<Loan> = {
      ...from,
      version: 0,
      ownerId,
      createAt: CoreFirestore.serverTimestamp(),
      updateAt: CoreFirestore.serverTimestamp(),
      assetType: AssetType.CashAndBanking,
      "@type": LoanTypeVersion,
    };
    return data as Loan;
  }

  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<Update>[] = [
    "name",
    "institution",
  ];
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<Update>[] = [
    "country",
    "accountNumber",
    "SWIFT_BIC",
    "sortCode",
    "notes",
  ];
  export function intoUpdate(
    current: Loan,
    update: Update
  ): {
    updates: UpdateObject<Update>;
    metadata: {
      addedToGroup: AssetV2["groupIds"];
      removedFromGroup: AssetV2["groupIds"];
    };
  } {
    const metadata: any = {};
    const baseUpdateFields = buildObjectUpdate(
      current,
      update,
      NonOptionalSimpleTypeUpdatableKeys,
      OptionalSimpleTypeUpdatableKeys
    );

    if (!Amount.optionalEqual(current.commission, update.commission))
      if (update.commission) baseUpdateFields.commission = update.commission;
      else baseUpdateFields.commission = null;

    if (
      current.interest.num !== update.interest.num ||
      current.interest.period !== update.interest.period
    ) {
      baseUpdateFields.interest = update.interest;
    }
    if (
      current.term.num !== update.term.num ||
      current.term.period !== update.term.period
    ) {
      baseUpdateFields.term = update.term;
    }

    let isAllocationUpdated = false;
    if (current.allocations.length == update.allocations.length) {
      for (let i = 0; i < current.allocations.length; i++) {
        if (!AllocationEqual(current.allocations[i], update.allocations[i])) {
          isAllocationUpdated = true;
          break;
        }
      }
    } else {
      isAllocationUpdated = true;
    }

    metadata.shouldEncryptAllocation = isAllocationUpdated;
    if (isAllocationUpdated) baseUpdateFields.allocations = update.allocations;

    const { fieldUpdate: groupIdUpdate, groupChanges } = compareGroupUpdate(
      current.groupIds,
      update.groupIds
    );
    if (groupIdUpdate !== undefined) {
      baseUpdateFields.groupIds = groupIdUpdate;
    }
    if (groupChanges.addedToGroup)
      metadata.addedToGroup = groupChanges.addedToGroup;
    if (groupChanges.removedFromGroup)
      metadata.removedFromGroup = groupChanges.removedFromGroup;
    const { attachments, newImages } = Attachment.compareUpdate(
      current,
      update
    );
    if (newImages.length > 0) metadata.newImages = newImages;
    if (attachments !== undefined) baseUpdateFields.attachments = attachments;

    return { updates: baseUpdateFields, metadata };
  }

  export function validateEncryptedPart(
    data: UpdateObject<EncryptedPart> & {
      attachments?: Attachment.EncryptedPart[];
    },
    _isCreate: boolean = false
  ) {
    if (data.attachments) {
      data.attachments.forEach((attachment) =>
        Attachment.validateEncryptedPart(attachment)
      );
    }
  }

  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean
  ) {
    for (const key of amountPaths) {
      if (data[key]) Amount.validate(key, data[key]!);
    }
    if (isCreate && !data.startDate)
      throw new InvalidInput("Start date is required");
    if ((isCreate || data.value) && data.value!.value > 0) {
      throw new InvalidInput("Loan cannot have positive value");
    }
    if ((isCreate || data.initialAmount) && data.initialAmount!.value < 0) {
      throw new InvalidInput("Initial amount should be positive");
    }
    if ((isCreate || data.interest) && data.interest!.num < 0) {
      throw new InvalidInput("Interest should be positive");
    }
    if ((isCreate || data.term) && data.term!.num < 0) {
      throw new InvalidInput("Term should be positive");
    }
    if (isCreate || data.allocations) {
      data.allocations!.forEach((alloc) => {
        if (alloc.percent < 0 || alloc.percent > 100) {
          throw new InvalidInput(
            "Allocation percentage should be between 0 and 100"
          );
        }
        if (!supportLiabilityTypes.includes(alloc.assetType)) {
          throw new InvalidInput("Allocation asset type is not supported");
        }
      });
    }
    // optional fields
    if (data.commission && data.commission!.value < 0) {
      throw new InvalidInput("Commission should be positive");
    }
  }

  export function handle(
    state: AccountState<Encrypted>,
    command: Command,
    toTagPair: ToTagPairFunction<Encrypted>
  ): Event[] {
    const events: Event[] = [];
    switch (command.kind) {
      case Command.Kind.CreateAsset:
        {
          const asset = command.asset as Encrypted;
          events.push({
            kind: Event.Kind.AssetCreated,
            executerId: command.executerId,
            asset: command.asset,
            summaryData: [
              {
                prevOwnedValue: {},
                currOwnedValue: {},
                prevAssetNumber: 0,
                currAssetNumber: 1,
                prevItemNumber: 0,
                currItemNumber: 1,
                currTags: toTagPair(asset),
              },
            ],
          });
          if (asset.groupIds && asset.groupIds.length > 0) {
            events.push({
              kind: Event.Kind.GroupsUpdated,
              executerId: command.executerId,
              addIds: asset.groupIds,
              removedIds: [],
            });
          }
          if (asset.allocations) {
            events.push({
              kind: Event.Kind.AllocationsUpdated,
              executerId: command.executerId,
              data: asset.allocations,
              added: asset.allocations,
            });
          }
          const txId = asset.id;
          const txData = AccountTransaction.systemAccountCreation(
            asset.subtype,
            txId,
            asset.id,
            undefined,
            command.executerId,
            asset.value,
            //#HACK nothing encrypted, and this Transaction cannot update, the decryption will be skipped
            ""
          );
          events.push({
            kind: Event.Kind.TransactionAdded,
            executerId: command.executerId,
            parentId: asset.id,
            id: txId,
            data: txData,
            valueChange: AccountTransaction.getNumericSignedValue(txData),
            summaryData: [
              {
                prevOwnedValue: {},
                currOwnedValue: MultiCurrencyAmount.fromAmounts(asset.value),
                prevAssetNumber: 1,
                currAssetNumber: 1,
                prevItemNumber: 1,
                currItemNumber: 1,
                prevTags: toTagPair(asset),
                currTags: toTagPair(asset),
              },
            ],
          });
        }
        break;
      case Command.Kind.UpdateAsset:
        {
          if (state.account.closedWith) {
            throw new InvalidInput("Account is already closed");
          }
          const { executerId, asset, addedToGroup, removedFromGroup } = command;
          const assetUpdated: Event.AssetUpdated = {
            executerId,
            kind: Event.Kind.AssetUpdated,
            asset,
            previous: deepCopy(state.account),
            current: asset,
          };
          const updateAsset = <UpdateObject<UpdateEncrypted>>asset;
          if (updateAsset.institution) {
            const prevOwnedValue = MultiCurrencyAmount.fromAmounts(
              state.account.value
            );
            assetUpdated.summaryData = [
              {
                prevOwnedValue: { ...prevOwnedValue },
                currOwnedValue: { ...prevOwnedValue },
                prevAssetNumber: 1,
                currAssetNumber: 1,
                prevItemNumber: 1,
                currItemNumber: 1,
                prevTags: toTagPair(state.account),
                currTags: toTagPair(updateAsset, state.account),
              },
            ];
          }
          events.push(assetUpdated);

          const maybeAllocation = (<UpdateEncrypted>asset).allocations;
          if (maybeAllocation) {
            const currentAllocation = state.account.allocations;
            const result = buildArrayUpdate(
              currentAllocation,
              maybeAllocation,
              (value, array) => {
                const current = array.find((a) => a.assetId === value.assetId);
                if (!current) return CompDifference.NotFound;
                if (current.percent !== value.percent)
                  return CompDifference.changed;
                return CompDifference.Same;
              }
            );
            if (result) {
              if (
                result.removed.length > 0 ||
                result.added.length > 0 ||
                result.changed.length > 0
              ) {
                events.push({
                  kind: Event.Kind.AllocationsUpdated,
                  executerId,
                  data: maybeAllocation,
                  added: result.added,
                  removed: result.removed,
                  changed: result.changed,
                });
              }
            }
          }

          if (addedToGroup || removedFromGroup) {
            events.push({
              executerId,
              kind: Event.Kind.GroupsUpdated,
              addIds: addedToGroup ?? [],
              removedIds: removedFromGroup ?? [],
            });
          }
        }
        break;
      case Command.Kind.CloseAsset:
        if (state.account.value.value !== 0) {
          throw new InvalidInput("Account balance is not zero");
        }
        events.push({
          kind: Event.Kind.AccountClosed,
          executerId: command.executerId,
          summaryData: [
            {
              prevOwnedValue: {},
              currOwnedValue: {},
              prevAssetNumber: 1,
              currAssetNumber: 0,
              prevItemNumber: 1,
              currItemNumber: 0,
              prevTags: toTagPair(state.account),
              currTags: [],
            },
          ],
        });
        break;

      case Command.Kind.AddTransaction:
        if (state.account.closedWith) {
          throw new InvalidInput("Account is already closed");
        }
        if (state.account.extId || state.account.extSource) {
          throw new InvalidInput("CreditCard account is from external source");
        }
        const valueChange = AccountTransaction.getNumericSignedValue(
          command.data
        );
        //#NOTE value check, if required
        events.push({
          kind: Event.Kind.TransactionAdded,
          executerId: command.executerId,
          parentId: state.account.id,
          id: command.id,
          data: command.data,
          valueChange: { ...valueChange },
          summaryData: [
            {
              prevOwnedValue: MultiCurrencyAmount.fromAmounts(
                state.account.value
              ),
              currOwnedValue: MultiCurrencyAmount.fromAmounts(
                Amount.add(state.account.value, valueChange)
              ),
              prevAssetNumber: 1,
              currAssetNumber: 1,
              prevItemNumber: 1,
              currItemNumber: 1,
              prevTags: toTagPair(state.account),
              currTags: toTagPair(state.account),
            },
          ],
        });
        break;
      case Command.Kind.UpdateTransaction:
        {
          if (state.account.closedWith) {
            throw new InvalidInput("Account is already closed");
          }
          if (state.account.extId || state.account.extSource) {
            throw new InvalidInput(
              "CreditCard account is from external source"
            );
          }
          const currentTx = state.transactions[command.id];
          if (!currentTx) {
            throw new InvalidInput("Transaction not found");
          }
          if (currentTx.category === Category.SystemAccountCreation) {
            throw new InvalidInput(
              "Cannot update system account creation transaction"
            );
          }
          const valueChange = AccountTransaction.calculateValueChange(
            currentTx,
            command.update
          );
          events.push({
            kind: Event.Kind.TransactionUpdated,
            executerId: command.executerId,
            parentId: state.account.id,
            id: command.id,
            update: command.update,
            valueChange: { ...valueChange },
            summaryData: [
              {
                prevOwnedValue: MultiCurrencyAmount.fromAmounts(
                  state.account.value
                ),
                currOwnedValue: MultiCurrencyAmount.fromAmounts(
                  Amount.add(state.account.value, valueChange)
                ),
                prevAssetNumber: 1,
                currAssetNumber: 1,
                prevItemNumber: 1,
                currItemNumber: 1,
                prevTags: toTagPair(state.account),
                currTags: toTagPair(state.account),
              },
            ],
          });
        }
        break;
      case Command.Kind.DeleteTransaction:
        {
          if (state.account.closedWith) {
            throw new InvalidInput("Account is already closed");
          }
          if (state.account.extId || state.account.extSource) {
            throw new InvalidInput(
              "CreditCard account is from external source"
            );
          }
          const currentTx = state.transactions[command.id];
          if (!currentTx) {
            throw new InvalidInput("Transaction not found");
          }
          if (currentTx.category === Category.SystemAccountCreation) {
            throw new InvalidInput(
              "Cannot update system account creation transaction"
            );
          }
          const valueChange = Amount.toNegative(
            AccountTransaction.getNumericSignedValue(currentTx)
          );
          events.push({
            kind: Event.Kind.TransactionDeleted,
            executerId: command.executerId,
            parentId: state.account.id,
            id: command.id,
            valueChange: { ...valueChange },
            summaryData: [
              {
                prevOwnedValue: MultiCurrencyAmount.fromAmounts(
                  state.account.value
                ),
                currOwnedValue: MultiCurrencyAmount.fromAmounts(
                  Amount.add(state.account.value, valueChange)
                ),
                prevAssetNumber: 1,
                currAssetNumber: 1,
                prevItemNumber: 1,
                currItemNumber: 1,
                prevTags: toTagPair(state.account),
                currTags: toTagPair(state.account),
              },
            ],
          });
        }
        break;

      case Command.Kind.OverwriteValue: {
        if (
          state.account.extId === undefined ||
          state.account.extSource === undefined
        ) {
          throw new InvalidInput("Account is not from external source");
        }
        const event: Event.ValueUpdated = {
          kind: Event.Kind.ValueUpdated,
          executerId: command.executerId,
          valueChange: {},
        };
        if (command.primaryValue.currency != state.account.value.currency) {
          throw new InvalidInput("Currency mismatch");
        }
        event.valueChange[state.account.value.currency] = subDecimal(
          command.primaryValue.value,
          state.account.value.value
        );
        event.primaryValue = command.primaryValue;
        event.summaryData = [
          {
            prevOwnedValue: MultiCurrencyAmount.fromAmounts(
              state.account.value
            ),
            currOwnedValue: MultiCurrencyAmount.fromAmounts(
              command.primaryValue
            ),
            prevAssetNumber: 1,
            currAssetNumber: 1,
            prevItemNumber: 1,
            currItemNumber: 1,
            prevTags: toTagPair(state.account),
            currTags: toTagPair(state.account),
          },
        ];
        events.push(event);
        break;
      }
      case Command.Kind.DeleteAsset:
        if (state.account.closedWith) {
          throw new InvalidInput("Account is already closed");
        }
        events.push({
          kind: Event.Kind.AssetDeleted,
          executerId: command.executerId,
          summaryData: [
            {
              prevOwnedValue: MultiCurrencyAmount.fromAmounts(
                state.account.value
              ),
              currOwnedValue: {},
              prevAssetNumber: 1,
              currAssetNumber: 0,
              prevItemNumber: 1,
              currItemNumber: 0,
              prevTags: toTagPair(state.account),
              currTags: [],
            },
          ],
        });
        break;
      default:
        throw new Error("unreachable");
    }
    return events;
  }

  export function updateValue(
    account: Encrypted,
    valueChange: MultiCurrencyAmount
  ) {
    account.value.value = addDecimal(
      account.value.value,
      valueChange[account.value.currency] || 0
    );
  }
}
