import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { Loader2Icon } from 'lucide-react'
import { Path, useFieldArray, useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { otherCollectableTypeValues, type OtherCollectable } from 'core/remodel/types/belongings'
import {
  AssetType,
  AttachmentKind,
  Currency,
  CustomizedType,
  LocationType,
  MeasurementUnits,
  measurementUnitValues
} from 'core/remodel/types/common'
import { AcquisitionType, acquisitionTypeValues } from 'core/remodel/types/common/acquisition'
import { CountryOptions, locationTypeValues } from 'core/remodel/types/options'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { addCustomType, fetchCustomTypes, typeQuery } from '@/api/CommonService'
import {
  addContact,
  addContactPosition,
  addContactRoom,
  contactQuery,
  fetchContactOptions,
  fetchContactRoomOptions,
  fetchContactRoomPositionOptions
} from '@/api/ContactService'
import { fetchGroupOptions, groupQuery } from '@/api/GroupService'
import { fetchOtherInfo, otherQuery } from '@/api/OtherCollectableService'
import {
  addPropertyPosition,
  addPropertyRoom,
  fetchPropertyOptions,
  fetchPropertyPositionOptions,
  fetchPropertyRoomOptions,
  propertyQuery
} from '@/api/PropertyService'
import { cmToIn, inToCm, kgToLb, lbToKg } from '@/constants/unit'
import { cn } from '@/utils/classnames'
import { makeOptions, sortAlphabetically, startOfDayInTz } from '@/utils/formatter'
import { useManualEditTracker } from '@/hooks/useManualEditTracker'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  FormAutocomplete,
  FormCheckbox,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormInput,
  FormInsurancePicker,
  FormNumberInput,
  FormPercentInput,
  FormPriceInput,
  FormQuantityInput,
  FormSelect,
  FormTextarea,
  Input,
  SelectItem,
  TypePicker
} from '@/components/base'
import AttachmentPanel from '@/components/AttachmentPanel'
import { AttachmentIcon, PlusIcon, StarIcon, XIcon } from '@/components/icon'
import NewAddress from '@/components/NewAddress'
import { TabPanel, type Tab } from '@/components/TabPanel'

interface OtherCollectableFormProps {
  id?: string
  onCancel: () => void
  onSubmit: SubmitHandler<OtherCollectable>
}

export function OtherCollectableForm({ id, ...props }: OtherCollectableFormProps) {
  const { t } = useTranslation()
  const searchParams = useSearchParams()
  const database = useAuthStore((state) => state.database)
  const { data: other, isLoading, error } = useSWR(id && [otherQuery.info, id], fetchOtherInfo(database!))
  const { data: preferences } = useSWR([userQuery.currentPreferences], fetchCurrentPreferences(database!))

  const tabs = useMemo(() => {
    if (!t) return undefined
    return [
      {
        key: 'primary',
        label: t('PrimaryDetails'),
        desc: t('Required'),
        icon: <StarIcon />
      },
      {
        key: 'additional',
        label: t('AdditionalDetails'),
        desc: t('Optional'),
        icon: <PlusIcon />,
        subTabs: [
          { key: 'acquisition', label: t('Acquisition') },
          { key: 'location', label: t('Location') },
          { key: 'attributes', label: t('Attributes') },
          { key: 'ownership', label: t('Ownership') }
        ]
      },
      {
        key: 'attachments',
        label: t('Attachments'),
        icon: <AttachmentIcon />
      }
    ] satisfies Tab[]
  }, [t])

  const defaultValues = useMemo(() => {
    if (!database || !preferences) return undefined
    const { baseCurrency: currency, timeZone } = preferences
    if (!currency) return undefined
    return {
      id: database.genAssetId(),
      assetType: AssetType.OtherCollectables,
      // primary details
      name: '',
      subtype: '',
      brand: '',
      personalRefNo: '',
      purchasePrice: { currency, value: 0 },
      purchaseDate: startOfDayInTz(new Date(), timeZone),
      number: 1,
      price: { currency, value: 0 },
      totalCost: { currency, value: 0 },
      value: { currency, value: 0 },
      groupIds: [],
      notes: '',
      // acquisition
      acquisition: {
        acquisitionType: AcquisitionType.Direct,
        sellerId: '',
        invoiceNumber: '',
        insuranceIds: [],
        priceAsValue: false,
        otherCost: { currency, value: 0 },
        discountAdjustment: { currency, value: 0 },
        notes: '',
        auctionDetail: {
          auctionNumber: '',
          hammerPrice: { currency, value: 0 },
          taxPayable: { currency, value: 0 },
          buyerPremium: { currency, value: 0 },
          lotNumber: '',
          paddleNumber: ''
        },
        initialValuationAtPurchase: { currency, value: 0 }
      },
      // location
      location: {
        locationType: LocationType.MyProperty,
        locationId: '',
        roomId: '',
        position: '',
        notes: ''
      },
      // attributes
      measurementUnits: MeasurementUnits.Metric,
      height: 0,
      width: 0,
      depth: 0,
      weight: 0,
      attributeNotes: '',
      creationCompletionYear: startOfDayInTz(new Date(), timeZone),
      dateExecuted: startOfDayInTz(new Date(), timeZone),
      // ownership
      ownership: { myOwnership: 100, shareholder: [] },
      beneficiary: [],
      // attachments
      attachments: [],
      mainImage: undefined,
      // unused
      '@type': 'com.myassets/Belonging/v2',
      version: NaN,
      ownerId: '',
      createAt: new Date(),
      updateAt: new Date()
    } satisfies OtherCollectable
  }, [database, preferences])

  const defaultTab = useMemo(() => {
    if (!tabs) return undefined
    return searchParams.get('tab') ?? tabs[0]!.key
  }, [searchParams, tabs])

  return isLoading || ![tabs, defaultValues, defaultTab].every(Boolean) ? (
    <FormSkeleton />
  ) : (
    <FormEntity values={other} tabs={tabs!} defaultValues={defaultValues!} defaultTab={defaultTab!} {...props} />
  )
}

interface FormEntityProps {
  values?: OtherCollectable
  tabs: Tab[]
  defaultValues: OtherCollectable
  defaultTab: string
  onCancel: () => void
  onSubmit: SubmitHandler<OtherCollectable>
}

function FormEntity({ values, tabs, defaultValues, defaultTab, onCancel, onSubmit }: FormEntityProps) {
  const mode = !values ? 'Create' : 'Update'
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences } = useSWR([userQuery.currentPreferences], fetchCurrentPreferences(database!))
  const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(false)
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    watch,
    setValue
  } = useForm<OtherCollectable>({ defaultValues, values })

  // State for tracking user edits
  const { trackManualEdit, isManuallyEdited } = useManualEditTracker<OtherCollectable>()
  const shouldUpdateCurrentValue = !isManuallyEdited('value') && mode === 'Create'
  const shouldUpdateInitialValue = !isManuallyEdited('acquisition.initialValuationAtPurchase') && mode === 'Create'

  // Watch form fields
  const id = watch('id')
  const itemPrice = watch('price.value')
  const purchasePrice = watch('purchasePrice.value')
  const adjustmentValue = watch('acquisition.discountAdjustment.value')
  const hammerValue = watch('acquisition.auctionDetail.hammerPrice.value')
  const taxValue = watch('acquisition.auctionDetail.taxPayable.value')
  const premiumValue = watch('acquisition.auctionDetail.buyerPremium.value')
  const otherValue = watch('acquisition.otherCost.value')
  const totalCostValue = watch('totalCost.value')
  const totalCostCurrency = watch('totalCost.currency')
  const priceAsValue = watch('acquisition.priceAsValue')!
  const numberOfItems = watch('number')
  const locationType = watch('location.locationType')
  const locationId = watch('location.locationId')
  const roomId = watch('location.roomId')
  const acquisitionType = watch('acquisition.acquisitionType')
  const measurementUnits = watch('measurementUnits')
  const myOwnership = watch('ownership.myOwnership')
  const shareholder = watch('ownership.shareholder')
  const beneficiary = watch('beneficiary')
  const weight = watch('weight')
  const height = watch('height')
  const width = watch('width')
  const depth = watch('depth')

  // Derived values
  const isProperty = locationType === LocationType.MyProperty
  const isAddress = locationType === LocationType.Address
  const isNewAddress = locationType === LocationType.NewAddress
  const isAuction = acquisitionType === AcquisitionType.Auction
  const isDirect = acquisitionType === AcquisitionType.Direct

  // Field arrays
  const shareholderArray = useFieldArray({
    control,
    name: 'ownership.shareholder',
    rules: {
      validate: {
        percent: (owners) => owners.reduce((prev, { percent }) => prev + percent, myOwnership) <= 100,
        notEmpty: (owners) => owners.every((owner) => owner.contactId !== '')
      }
    }
  })
  const beneficiaryArray = useFieldArray({
    control,
    name: 'beneficiary',
    rules: {
      validate: {
        percent: (owners) => owners?.reduce((prev, { percent }) => prev + percent, 0) <= 100,
        notEmpty: (owners) => owners?.every((owner) => owner.contactId !== '')
      }
    }
  })

  // Controlled arrays
  const controlledShareholder = shareholderArray.fields.map((f, i) => ({ ...f, ...shareholder[i] }))
  const controlledBeneficiary = beneficiaryArray.fields.map((f, i) => ({ ...f, ...beneficiary?.[i] }))

  // Calculated values
  const remainingOwnership = useMemo(() => {
    const shared = controlledShareholder.reduce((acc, curr) => acc + curr.percent, 0)
    const total = shared + myOwnership
    return 100 - total
  }, [myOwnership, controlledShareholder])
  const remainingBeneficiary = useMemo(() => {
    const total = controlledBeneficiary.reduce((acc, curr) => acc + curr.percent, 0)
    return 100 - total
  }, [controlledBeneficiary])

  // Fetch data for options
  const { data: subtypes, isValidating: loadingSubTypes } = useSWR(
    [typeQuery.otherType, CustomizedType.OtherCollectablesType],
    fetchCustomTypes(database!)
  )
  const { data: brands, isValidating: loadingBrands } = useSWR(
    [typeQuery.otherBrand, CustomizedType.OtherCollectablesBrand],
    fetchCustomTypes(database!)
  )
  const { data: models, isValidating: loadingModels } = useSWR(
    [typeQuery.otherModel, CustomizedType.OtherCollectablesModel],
    fetchCustomTypes(database!)
  )
  const { data: propertyOptions, isLoading: loadingProperties } = useSWR(
    [propertyQuery.options],
    fetchPropertyOptions(database!)
  )
  const { data: contactOptions, isValidating: loadingContacts } = useSWR(
    [contactQuery.options],
    fetchContactOptions(database!)
  )
  const { data: groupOptions, isValidating: loadingGroups } = useSWR([groupQuery.options], fetchGroupOptions(database!))
  const { data: propertyRoomOptions, isValidating: loadingPropertyRooms } = useSWR(
    locationId && [propertyQuery.rooms, locationId],
    fetchPropertyRoomOptions(database!)
  )
  const { data: propertyPositionOptions, isValidating: loadingPropertyPositions } = useSWR(
    locationId && roomId && [propertyQuery.positions, locationId, roomId],
    fetchPropertyPositionOptions(database!)
  )
  const { data: contactRoomOptions, isValidating: loadingContactRooms } = useSWR(
    locationId && [contactQuery.rooms, locationId],
    fetchContactRoomOptions(database!)
  )
  const { data: contactPositionOptions, isValidating: loadingContactPositions } = useSWR(
    locationId && roomId && [contactQuery.positions, locationId, roomId],
    fetchContactRoomPositionOptions(database!)
  )

  // Create options
  const subtypeOptions: { label: string; value: string }[] = useMemo(() => {
    const base = makeOptions(otherCollectableTypeValues, (key) => `collectables:OtherCollectableTypeOptions.${key}`)
    const added = subtypes?.map((subtype) => ({ label: subtype, value: subtype })) ?? []
    return sortAlphabetically({ data: [...base, ...added], selector: (d) => d.label, locale: 'en' })
  }, [subtypes])
  const brandOptions = brands?.map((item) => ({ label: item, value: item }))
  const modelOptions = models?.map((item) => ({ label: item, value: item }))
  const measurementOptions = makeOptions(measurementUnitValues, (key) => `MeasurementUnits.${key}`)
  const locationTypeOptions = makeOptions(locationTypeValues, (key) => `LocationTypeOptions.${key}`)
  const acquisitionTypeOptions = makeOptions(acquisitionTypeValues, (key) => `AcquisitionTypeOptions.${key}`)
  const roomOptions = locationType === LocationType.MyProperty ? propertyRoomOptions : contactRoomOptions
  const loadingRooms = locationType === LocationType.MyProperty ? loadingPropertyRooms : loadingContactRooms
  const positionOptions = locationType === LocationType.MyProperty ? propertyPositionOptions : contactPositionOptions
  const loadingPositions = locationType === LocationType.MyProperty ? loadingPropertyPositions : loadingContactPositions

  const addRoom = async (roomName: string) => {
    switch (locationType) {
      case LocationType.MyProperty:
        const PropertyRoomId = await addPropertyRoom(database!, locationId, roomName)
        return PropertyRoomId
      case LocationType.Address:
      case LocationType.NewAddress:
        const ContactRoomId = await addContactRoom(database!, locationId, roomName)
        return ContactRoomId
    }
  }

  const addPosition = async (positionName: string) => {
    switch (locationType) {
      case LocationType.MyProperty:
        await addPropertyPosition(database!, locationId, roomId!, positionName)
        return positionName
      case LocationType.Address:
      case LocationType.NewAddress:
        await addContactPosition(database!, locationId, roomId!, positionName)
        return positionName
    }
  }

  const addNewContact = async (firstName: string) => await addContact(database!, { firstName })

  const onUnitChanged = (prev: string, next: string) => {
    if (prev === MeasurementUnits.Metric && next === MeasurementUnits.Imperial) {
      setValue('weight', (weight ?? 0) * kgToLb)
      setValue('height', (height ?? 0) * cmToIn)
      setValue('width', (width ?? 0) * cmToIn)
      setValue('depth', (depth ?? 0) * cmToIn)
    }
    if (prev === MeasurementUnits.Imperial && next === MeasurementUnits.Metric) {
      setValue('weight', (weight ?? 0) * lbToKg)
      setValue('height', (height ?? 0) * inToCm)
      setValue('width', (width ?? 0) * inToCm)
      setValue('depth', (depth ?? 0) * inToCm)
    }
  }

  const handleLocationCancel = () => {
    setValue('location.locationId', '')
    setValue('location.roomId', '')
    setValue('location.position', '')
  }

  const ShareholderUnselectContact = useCallback(
    (index: number) => {
      const otherShareHolders = [...controlledShareholder.slice(0, index), ...controlledShareholder.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherShareHolders.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledShareholder]
  )

  const beneficiaryUnselectContact = useCallback(
    (index: number) => {
      const otherBeneficiaries = [...controlledBeneficiary.slice(0, index), ...controlledBeneficiary.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherBeneficiaries?.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledBeneficiary]
  )

  const handleFieldValueChange = useCallback(
    (fieldName: Path<OtherCollectable>, value: number) => {
      switch (fieldName) {
        case 'number':
          setValue('purchasePrice.value', itemPrice * value)
          setValue('acquisition.auctionDetail.hammerPrice.value', itemPrice * value)
          shouldUpdateCurrentValue && setValue('value.value', itemPrice * value)
          shouldUpdateInitialValue && setValue('acquisition.initialValuationAtPurchase.value', itemPrice * value)
          break
        case 'price.value':
          setValue('purchasePrice.value', value * numberOfItems)
          setValue('acquisition.auctionDetail.hammerPrice.value', value * numberOfItems)
          shouldUpdateCurrentValue && setValue('value.value', value * numberOfItems)
          shouldUpdateInitialValue && setValue('acquisition.initialValuationAtPurchase.value', value * numberOfItems)
          break
        case 'purchasePrice.value':
          setValue('acquisition.auctionDetail.hammerPrice.value', value)
          shouldUpdateInitialValue && setValue('acquisition.initialValuationAtPurchase.value', value)
          break
        case 'acquisition.auctionDetail.hammerPrice.value':
          shouldUpdateInitialValue && setValue('acquisition.initialValuationAtPurchase.value', value)
          break
      }
    },
    [shouldUpdateCurrentValue, shouldUpdateInitialValue, setValue, numberOfItems, itemPrice]
  )

  const handleFieldCurrencyChange = useCallback(
    (_fieldName: Path<OtherCollectable>, currency: Currency) => {
      const currencyFields: Path<OtherCollectable>[] = [
        'price.currency',
        'purchasePrice.currency',
        'acquisition.auctionDetail.hammerPrice.currency',
        'acquisition.auctionDetail.taxPayable.currency',
        'acquisition.auctionDetail.buyerPremium.currency',
        'acquisition.discountAdjustment.currency',
        'acquisition.otherCost.currency',
        'totalCost.currency'
      ]
      shouldUpdateCurrentValue && currencyFields.push('value.currency')
      shouldUpdateInitialValue && currencyFields.push('acquisition.initialValuationAtPurchase.currency')

      currencyFields.forEach((field) => setValue(field, currency))
    },
    [setValue, shouldUpdateCurrentValue, shouldUpdateInitialValue]
  )

  useEffect(() => {
    const basePrice = isDirect ? purchasePrice : hammerValue
    const additionalCosts = isDirect ? otherValue - adjustmentValue : taxValue + premiumValue + otherValue
    setValue('totalCost.value', Math.round((basePrice + additionalCosts) * 100) / 100)
  }, [isDirect, purchasePrice, otherValue, hammerValue, taxValue, premiumValue, adjustmentValue, setValue])

  useEffect(() => {
    if (priceAsValue) {
      setValue('value.value', totalCostValue)
      setValue('value.currency', totalCostCurrency)
    }
  }, [priceAsValue, totalCostValue, totalCostCurrency, setValue])

  return (
    <>
      <TabPanel defaultValue={defaultTab}>
        <TabPanel.SideNav tabs={tabs} />
        <form
          className={'flex h-[95vh] grow flex-col justify-between gap-4 md:h-[700px]'}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={'grow overflow-auto px-4 pt-4'}>
            <TabPanel.Section value={'primary'}>
              <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
                <div className={'flex flex-col gap-4'}>
                  <FormCreatableAutocomplete
                    control={control}
                    name={'name'}
                    label={t('collectables:Field.CollectableModelEditionName')}
                    options={modelOptions}
                    onCreate={async (name) =>
                      await addCustomType(database!, CustomizedType.OtherCollectablesModel, name)
                    }
                    placeholder={t('SearchOrCreate')}
                    rules={{
                      required: t('validation:SpecifyField', {
                        field: '$t(collectables:Field.CollectableModelEditionName)'
                      })
                    }}
                    isLoading={loadingModels}
                  />
                  <FormCreatableAutocomplete
                    control={control}
                    name={'brand'}
                    label={t('collectables:Field.BrandManufacturerCreator')}
                    options={brandOptions}
                    onCreate={async (name) =>
                      await addCustomType(database!, CustomizedType.OtherCollectablesBrand, name)
                    }
                    placeholder={t('SearchOrCreate')}
                    rules={{
                      required: t('validation:SpecifyField', {
                        field: '$t(collectables:Field.BrandManufacturerCreator)'
                      })
                    }}
                    isLoading={loadingBrands}
                  />
                  <FormCreatableAutocomplete
                    control={control}
                    name={'subtype'}
                    label={t('collectables:Field.CollectableType')}
                    options={subtypeOptions}
                    onCreate={async (name) =>
                      await addCustomType(database!, CustomizedType.OtherCollectablesType, name)
                    }
                    placeholder={t('SearchOrCreate')}
                    rules={{
                      required: t('validation:SpecifyField', { field: '$t(collectables:Field.CollectableType)' })
                    }}
                    isLoading={loadingSubTypes}
                  />
                  <FormSelect
                    control={control}
                    name={'location.locationType'}
                    label={t('Field.LocationType')}
                    onSelected={() => {
                      setValue('location.locationId', '')
                      setValue('location.roomId', '')
                      setValue('location.position', '')
                    }}
                    rules={{ required: t('validation:SpecifyField', { field: '$t(Field.LocationType)' }) }}
                  >
                    {locationTypeOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </FormSelect>

                  {isProperty && (
                    <FormAutocomplete
                      control={control}
                      name={'location.locationId'}
                      label={t('Field.LocationOfAsset')}
                      options={propertyOptions}
                      placeholder={t('TypeToSearch')}
                      isLoading={loadingProperties}
                      onChanged={() => {
                        setValue('location.roomId', '')
                        setValue('location.position', '')
                      }}
                      rules={{ required: t('validation:SpecifyField', { field: '$t(Field.LocationOfAsset)' }) }}
                      onCancel={handleLocationCancel}
                    />
                  )}
                  {isAddress && (
                    <FormAutocomplete
                      control={control}
                      name={'location.locationId'}
                      label={t('Field.LocationOfAsset')}
                      options={contactOptions}
                      placeholder={t('TypeToSearch')}
                      isLoading={loadingContacts}
                      onChanged={() => {
                        setValue('location.roomId', '')
                        setValue('location.position', '')
                      }}
                      rules={{ required: t('validation:SpecifyField', { field: '$t(Field.LocationOfAsset)' }) }}
                      onCancel={handleLocationCancel}
                    />
                  )}
                  {isNewAddress && (
                    <>
                      <Button
                        className={'group justify-start gap-x-3 justify-self-start'}
                        onClick={() => setIsNewModalOpen(true)}
                        disabled={locationId.length > 0}
                        data-testid={'create-new-address-button'}
                        aria-label={'create-new-address-button'}
                      >
                        <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                          <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                        </div>
                        <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                          {t('CreateNewAddress')}
                        </span>
                      </Button>
                      {locationId.length > 0 && (
                        <div className={'flex items-center justify-between'}>
                          <p className={'text-sm'}>
                            {contactOptions?.find(({ value }) => value === locationId)?.label ?? '-'}
                          </p>
                          <Button
                            className={'rounded-full transition-colors hover:bg-grey/20'}
                            onClick={() => setValue('location.locationId', '')}
                            data-testid={'delete-address-button'}
                            aria-label={'delete-address-button'}
                          >
                            <XIcon className={'text-primary'} />
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                  <FormInput
                    control={control}
                    name={'personalRefNo'}
                    label={t('Field.PersonalRefNo')}
                    placeholder={t('Field.AddRefNumber')}
                  />
                </div>
                <div className={'flex flex-col gap-4'}>
                  <FormDatePicker
                    control={control}
                    name={'purchaseDate'}
                    label={t('Field.PurchaseDate')}
                    format={preferences?.dateFormat}
                    timeZone={preferences?.timeZone}
                    rules={{ required: t('validation:SpecifyField', { field: '$t(Field.PurchaseDate)' }) }}
                  />
                  <FormQuantityInput
                    control={control}
                    name={'number'}
                    label={t('Field.NumberOfItems')}
                    rules={{ required: t('validation:SpecifyField', { field: '$t(Field.NumberOfItems)' }) }}
                    min={1}
                    max={9999}
                    onChanged={(value) => handleFieldValueChange('number', value)}
                  />
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'price.currency', value: 'price.value' }}
                    label={t('Field.ItemPrice')}
                    rules={{
                      value: {
                        required: t('validation:SpecifyField', { field: '$t(Field.ItemPrice)' }),
                        validate: { greaterThanZero: (value) => value > 0 }
                      }
                    }}
                    format={preferences?.numberFormat}
                    digits={2}
                    onChangedCurrency={(value) => handleFieldCurrencyChange('price.currency', value)}
                    onChangedValue={(value) => handleFieldValueChange('price.value', value)}
                  />
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'purchasePrice.currency', value: 'purchasePrice.value' }}
                    label={t('Field.PurchasePrice')}
                    rules={{
                      value: {
                        required: t('validation:SpecifyField', { field: '$t(Field.PurchasePrice)' }),
                        validate: { greaterThanZero: (value) => value > 0 }
                      }
                    }}
                    format={preferences?.numberFormat}
                    digits={2}
                    onChangedCurrency={(value) => handleFieldCurrencyChange('purchasePrice.currency', value)}
                    onChangedValue={(value) => handleFieldValueChange('purchasePrice.value', value)}
                  />
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'value.currency', value: 'value.value' }}
                    label={t('Field.CurrentValuation')}
                    info={t('UsedForValuation')}
                    format={preferences?.numberFormat}
                    digits={2}
                    onFocus={() => trackManualEdit('value')}
                  />
                  <FormAutocomplete
                    control={control}
                    name={'groupIds'}
                    label={t('Field.Groups')}
                    options={groupOptions}
                    placeholder={t('TypeToSearch')}
                    isMulti={true}
                    isLoading={loadingGroups}
                  />
                </div>
              </div>

              <FormTextarea className={'col-span-1'} control={control} name={'notes'} label={t('Field.PrimaryNotes')} />
            </TabPanel.Section>
            <TabPanel.Section value={'additional.acquisition'}>
              <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
                <div className={'flex flex-col gap-4'}>
                  <FormSelect control={control} name={'acquisition.acquisitionType'} label={t('Field.AcquisitionType')}>
                    {acquisitionTypeOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </FormSelect>
                  <FormCreatableAutocomplete
                    control={control}
                    name={'acquisition.sellerId'}
                    label={t('Field.Seller')}
                    placeholder={t('TypeToSearchOrCreate')}
                    options={contactOptions}
                    onCreate={addNewContact}
                    isLoading={loadingContacts}
                  />
                  {isAuction && (
                    <>
                      <FormInput
                        control={control}
                        name={'acquisition.auctionDetail.auctionNumber'}
                        label={t('Field.AuctionNameNumber')}
                      />
                      <FormInput
                        control={control}
                        name={'acquisition.auctionDetail.paddleNumber'}
                        label={t('Field.PaddleNumber')}
                      />
                      <FormInput
                        control={control}
                        name={'acquisition.auctionDetail.lotNumber'}
                        label={t('Field.LotNumber')}
                      />
                    </>
                  )}
                  <FormInput
                    control={control}
                    name={'acquisition.invoiceNumber'}
                    label={t('Field.InvoiceNumber')}
                    type={'text'}
                  />
                  <FormInsurancePicker
                    control={control}
                    name={'acquisition.insuranceIds'}
                    label={t('Field.Insurances')}
                  />
                  <FormPriceInput
                    control={control}
                    name={{
                      currency: 'acquisition.initialValuationAtPurchase.currency',
                      value: 'acquisition.initialValuationAtPurchase.value'
                    }}
                    label={t('Field.InitialValuationAtPurchase')}
                    format={preferences?.numberFormat}
                    digits={2}
                    onFocus={() => trackManualEdit('acquisition.initialValuationAtPurchase')}
                  />
                </div>
                <div className={'flex flex-col gap-4'}>
                  <FormDatePicker
                    control={control}
                    name={'purchaseDate'}
                    label={t('Field.PurchaseDate')}
                    format={preferences?.dateFormat}
                    timeZone={preferences?.timeZone}
                    rules={{ required: true }}
                  />
                  {isDirect && (
                    <FormPriceInput
                      control={control}
                      name={{ currency: 'purchasePrice.currency', value: 'purchasePrice.value' }}
                      label={t('Field.PurchasePrice')}
                      rules={{
                        value: {
                          required: t('validation:SpecifyField', { field: '$t(Field.PurchasePrice)' }),
                          validate: { greaterThanZero: (value) => value >= 0 }
                        }
                      }}
                      format={preferences?.numberFormat}
                      digits={2}
                      onChangedCurrency={(value) => handleFieldCurrencyChange('purchasePrice.currency', value)}
                      onChangedValue={(value) => handleFieldValueChange('purchasePrice.value', value)}
                    />
                  )}
                  {isDirect && (
                    <FormPriceInput
                      control={control}
                      name={{
                        currency: 'acquisition.discountAdjustment.currency',
                        value: 'acquisition.discountAdjustment.value'
                      }}
                      label={t('collectables:Field.DiscountPriceAdjustment')}
                      format={preferences?.numberFormat}
                      digits={2}
                      disabled={{ currency: true }}
                    />
                  )}
                  {isAuction && (
                    <>
                      <FormPriceInput
                        control={control}
                        name={{
                          currency: 'acquisition.auctionDetail.hammerPrice.currency',
                          value: 'acquisition.auctionDetail.hammerPrice.value'
                        }}
                        label={t('Field.HammerPrice')}
                        format={preferences?.numberFormat}
                        digits={2}
                        onChangedCurrency={(value) =>
                          handleFieldCurrencyChange('acquisition.auctionDetail.hammerPrice.currency', value)
                        }
                        onChangedValue={(value) =>
                          handleFieldValueChange('acquisition.auctionDetail.hammerPrice.value', value)
                        }
                      />
                      <FormPriceInput
                        control={control}
                        name={{
                          currency: 'acquisition.auctionDetail.taxPayable.currency',
                          value: 'acquisition.auctionDetail.taxPayable.value'
                        }}
                        label={t('Field.TaxPayable')}
                        format={preferences?.numberFormat}
                        digits={2}
                        disabled={{ currency: true }}
                      />
                      <FormPriceInput
                        control={control}
                        name={{
                          currency: 'acquisition.auctionDetail.buyerPremium.currency',
                          value: 'acquisition.auctionDetail.buyerPremium.value'
                        }}
                        label={t('Field.BuyerPremium')}
                        format={preferences?.numberFormat}
                        digits={2}
                        disabled={{ currency: true }}
                      />
                    </>
                  )}
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'acquisition.otherCost.currency', value: 'acquisition.otherCost.value' }}
                    label={t('Field.OtherCostsEg')}
                    format={preferences?.numberFormat}
                    digits={2}
                    disabled={{ currency: true }}
                  />
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'totalCost.currency', value: 'totalCost.value' }}
                    label={t('Field.TotalCost')}
                    format={preferences?.numberFormat}
                    digits={2}
                    disabled={{ currency: true, value: true }}
                    allowNegative={true}
                  />
                  <FormCheckbox
                    control={control}
                    name={'acquisition.priceAsValue'}
                    label={t('TotalCostAsAssetValue')}
                  />
                </div>
              </div>

              <div className={'col-span-1ß'}>
                <FormTextarea control={control} name={'acquisition.notes'} label={t('Field.AcquisitionNotes')} />
              </div>
            </TabPanel.Section>
            <TabPanel.Section value={'additional.location'}>
              <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
                <div className={'flex flex-col gap-4'}>
                  <FormSelect
                    control={control}
                    name={'location.locationType'}
                    label={t('Field.LocationType')}
                    onSelected={handleLocationCancel}
                    rules={{ required: t('validation:SpecifyField', { field: '$t(Field.LocationType)' }) }}
                  >
                    {locationTypeOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </FormSelect>

                  {isProperty && (
                    <FormAutocomplete
                      control={control}
                      name={'location.locationId'}
                      label={t('Field.LocationOfAsset')}
                      options={propertyOptions}
                      placeholder={t('TypeToSearch')}
                      isLoading={loadingProperties}
                      onChanged={() => {
                        setValue('location.roomId', '')
                        setValue('location.position', '')
                      }}
                      rules={{ required: t('validation:SpecifyField', { field: '$t(Field.LocationOfAsset)' }) }}
                      onCancel={handleLocationCancel}
                    />
                  )}
                  {isAddress && (
                    <FormAutocomplete
                      control={control}
                      name={'location.locationId'}
                      label={t('Field.LocationOfAsset')}
                      options={contactOptions}
                      placeholder={t('TypeToSearch')}
                      isLoading={loadingContacts}
                      onChanged={() => {
                        setValue('location.roomId', '')
                        setValue('location.position', '')
                      }}
                      rules={{ required: t('validation:SpecifyField', { field: '$t(Field.LocationOfAsset)' }) }}
                      onCancel={handleLocationCancel}
                    />
                  )}
                  {isNewAddress && (
                    <>
                      <Button
                        className={'group justify-start gap-x-3 justify-self-start'}
                        onClick={() => setIsNewModalOpen(true)}
                        disabled={locationId.length > 0}
                        data-testid={'create-new-address-button'}
                        aria-label={'create-new-address-button'}
                      >
                        <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                          <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                        </div>
                        <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                          {t('CreateNewAddress')}
                        </span>
                      </Button>
                      {locationId.length > 0 && (
                        <div className={'flex items-center justify-between'}>
                          <p className={'text-sm'}>
                            {contactOptions?.find(({ value }) => value === locationId)?.label ?? '-'}
                          </p>
                          <Button
                            className={'rounded-full transition-colors hover:bg-grey/20'}
                            onClick={handleLocationCancel}
                            data-testid={'delete-address-button'}
                            aria-label={'delete-address-button'}
                          >
                            <XIcon className={'text-primary'} />
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className={'flex flex-col gap-4'}>
                  <FormCreatableAutocomplete
                    control={control}
                    name={'location.roomId'}
                    label={t('Field.Room')}
                    options={roomOptions}
                    onCreate={(name) => addRoom(name)}
                    placeholder={t('SearchOrCreate')}
                    isLoading={loadingRooms}
                    isDisabled={!locationId}
                    onChanged={() => setValue('location.position', '')}
                  />
                  <FormCreatableAutocomplete
                    control={control}
                    name={'location.position'}
                    label={t('Field.Position')}
                    options={positionOptions}
                    onCreate={(name) => addPosition(name)}
                    placeholder={t('SearchOrCreate')}
                    isLoading={loadingPositions}
                    isDisabled={!roomId}
                  />
                </div>
              </div>
              <div className={'col-span-1'}>
                <FormTextarea control={control} name={'location.notes'} label={t('Field.Notes')} />
              </div>
            </TabPanel.Section>
            <TabPanel.Section value={'additional.attributes'}>
              <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
                <div className={'flex flex-col gap-4'}>
                  <TypePicker
                    control={control}
                    name={'measurementUnits'}
                    label={t('Field.MeasurementUnits')}
                    options={measurementOptions}
                    onChanged={onUnitChanged}
                  />
                  <FormNumberInput
                    control={control}
                    name={'height'}
                    label={t('Field.Height')}
                    prefix={measurementUnits === MeasurementUnits.Metric ? 'cm' : 'in'}
                    format={preferences?.numberFormat}
                    digits={2}
                    rules={{
                      validate: {
                        maximum: (value) => {
                          const maximum = measurementUnits === MeasurementUnits.Metric ? 254_000 : 100_000
                          return value ? value <= maximum : true
                        }
                      }
                    }}
                  />
                  <FormNumberInput
                    control={control}
                    name={'width'}
                    label={t('Field.Width')}
                    prefix={measurementUnits === MeasurementUnits.Metric ? 'cm' : 'in'}
                    format={preferences?.numberFormat}
                    digits={2}
                    rules={{
                      validate: {
                        maximum: (value) => {
                          const maximum = measurementUnits === MeasurementUnits.Metric ? 254_000 : 100_000
                          return value ? value <= maximum : true
                        }
                      }
                    }}
                  />
                  <FormNumberInput
                    control={control}
                    name={'depth'}
                    label={t('Field.Depth')}
                    prefix={measurementUnits === MeasurementUnits.Metric ? 'cm' : 'in'}
                    format={preferences?.numberFormat}
                    digits={2}
                    rules={{
                      validate: {
                        maximum: (value) => {
                          const maximum = measurementUnits === MeasurementUnits.Metric ? 254_000 : 100_000
                          return value ? value <= maximum : true
                        }
                      }
                    }}
                  />
                  <FormNumberInput
                    control={control}
                    name={'weight'}
                    label={t('Field.Weight')}
                    prefix={measurementUnits === MeasurementUnits.Metric ? 'kg' : 'lb'}
                    format={preferences?.numberFormat}
                    digits={2}
                    rules={{
                      validate: {
                        maximum: (value) => {
                          const maximum = measurementUnits === MeasurementUnits.Metric ? 45_359.237 : 100_000
                          return value ? value <= maximum : true
                        }
                      }
                    }}
                  />
                  <FormInput
                    control={control}
                    name={'dimensionsSummary'}
                    label={t('collectables:Field.DimensionsSummary')}
                  />
                  {/* TODO rules */}
                  <FormInput control={control} name={'edition'} type={'text'} label={t('collectables:Field.Edition')} />
                </div>
                <div className={'flex flex-col gap-4'}>
                  <FormDatePicker
                    control={control}
                    name={'creationCompletionYear'}
                    label={t('collectables:Field.CreationCompletionYear')}
                    format={preferences?.dateFormat}
                    timeZone={preferences?.timeZone}
                  />
                  <FormInput control={control} name={'provenance'} label={t('collectables:Field.Provenance')} />
                  <FormAutocomplete
                    control={control}
                    name={'geography'}
                    label={t('collectables:Field.Geography')}
                    options={CountryOptions}
                    placeholder={t('TypeToSearch')}
                  />
                  <FormInput
                    control={control}
                    name={'medium'}
                    label={t('collectables:Field.Medium')}
                    placeholder={t('TypeToSearch')}
                  />
                  <FormInput
                    control={control}
                    name={'inscription'}
                    label={t('collectables:Field.Inscription')}
                    rules={{
                      maxLength: {
                        value: 100,
                        message: t('validation:SymbolsLimit', {
                          field: '$t(collectables:Field.Inscription)',
                          number: 100
                        })
                      }
                    }}
                  />
                  <FormInput
                    control={control}
                    name={'description'}
                    type={'text'}
                    label={t('collectables:Field.Description')}
                    rules={{
                      maxLength: {
                        value: 1000,
                        message: t('validation:SymbolsLimit', {
                          field: '$t(collectables:Field.Description)',
                          number: 1000
                        })
                      }
                    }}
                  />
                  <FormInput
                    control={control}
                    name={'catalogueText'}
                    type={'text'}
                    label={t('collectables:Field.CatalogueText')}
                    rules={{
                      maxLength: {
                        value: 1000,
                        message: t('validation:SymbolsLimit', {
                          field: '$t(collectables:Field.CatalogueText)',
                          number: 1000
                        })
                      }
                    }}
                  />
                </div>
              </div>
              <FormTextarea
                className={'col-span-1'}
                control={control}
                name={'attributeNotes'}
                label={t('Field.Notes')}
              />
            </TabPanel.Section>
            <TabPanel.Section value={'additional.ownership'} className={'items-start'}>
              <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
                <div className={'space-y-4'}>
                  <div className={'grid gap-1'}>
                    <div className={'flex items-center justify-between pr-10'}>
                      <span className={'text-sm'}>{t('Field.Ownership')}</span>
                      {remainingOwnership === 0 && (
                        <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                      )}
                      {remainingOwnership > 0 && (
                        <span className={'text-xs text-[#d89423]'}>
                          {t('RemainingPercent', { percent: remainingOwnership })}
                        </span>
                      )}
                      {remainingOwnership < 0 && (
                        <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>
                      )}
                    </div>
                    <div className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                      <Input className={'h-[38px] border'} defaultValue={t('Field.MyOwnership')} disabled={true} />
                      <FormPercentInput control={control} name={`ownership.myOwnership`} />
                    </div>
                  </div>
                  <div className={'grid gap-1'}>
                    <span className={'text-sm'}>{t('Field.Shareholder')}</span>
                    <ul className={'space-y-2 empty:hidden'}>
                      {shareholderArray.fields.map((shareholder, index) => (
                        <li key={shareholder.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                          <FormCreatableAutocomplete
                            control={control}
                            name={`ownership.shareholder.${index}.contactId`}
                            options={ShareholderUnselectContact(index)}
                            onCreate={addNewContact}
                            isLoading={loadingContacts}
                            placeholder={t('SearchOrCreate')}
                          />
                          <FormPercentInput control={control} name={`ownership.shareholder.${index}.percent`} />
                          <Button
                            className={'rounded-full transition-colors hover:bg-grey/20'}
                            onClick={() => shareholderArray.remove(index)}
                          >
                            <XIcon className={'text-primary'} />
                          </Button>
                        </li>
                      ))}
                    </ul>
                    <Button
                      className={'group justify-start gap-x-3 justify-self-start py-2'}
                      onClick={() => shareholderArray.append({ contactId: '', percent: 0 })}
                      data-testid={'add-shareholder-button'}
                      aria-label={'add-shareholder-button'}
                    >
                      <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                        <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                      </div>
                      <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                        {t('AddShareholders')}
                      </span>
                    </Button>
                    {/* TODO: Treat Asset Value As */}
                  </div>
                </div>
                <div className={'space-y-1'}>
                  <div className={'flex items-center justify-between pr-10'}>
                    <span className={'text-sm'}>{t('Field.Beneficiary')}</span>
                    {remainingBeneficiary === 0 && (
                      <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                    )}
                    {remainingBeneficiary > 0 && (
                      <span className={'text-xs text-[#d89423]'}>
                        {t('RemainingPercent', { percent: remainingBeneficiary })}
                      </span>
                    )}
                    {remainingBeneficiary < 0 && (
                      <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>
                    )}
                  </div>
                  <ul className={'space-y-2 empty:hidden'}>
                    {beneficiaryArray.fields.map((beneficiary, index) => (
                      <li key={beneficiary.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                        <FormCreatableAutocomplete
                          control={control}
                          name={`beneficiary.${index}.contactId`}
                          options={beneficiaryUnselectContact(index)}
                          onCreate={addNewContact}
                          isLoading={loadingContacts}
                          placeholder={t('SearchOrCreate')}
                        />
                        <FormPercentInput control={control} name={`beneficiary.${index}.percent`} />
                        <Button
                          className={'rounded-full transition-colors hover:bg-grey/20'}
                          onClick={() => beneficiaryArray.remove(index)}
                        >
                          <XIcon className={'text-primary'} />
                        </Button>
                      </li>
                    ))}
                  </ul>
                  <Button
                    className={'group justify-start gap-x-3 justify-self-start py-2'}
                    onClick={() => beneficiaryArray.append({ contactId: '', percent: 0 })}
                    data-testid={'add-beneficiary-button'}
                    aria-label={'add-beneficiary-button'}
                  >
                    <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                      <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                    </div>
                    <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                      {t('AddBeneficiaries')}
                    </span>
                  </Button>
                </div>
              </div>
            </TabPanel.Section>
            <TabPanel.Section value={'attachments'} className={'h-full md:grid-cols-1'}>
              <AttachmentPanel
                assetId={id}
                control={control}
                name={{ mainImage: 'mainImage', attachments: 'attachments' }}
                widgetOptions={[
                  AttachmentKind.AssetImage,
                  AttachmentKind.PrimaryDetails,
                  AttachmentKind.Acquisition,
                  AttachmentKind.Location,
                  AttachmentKind.Attributes,
                  AttachmentKind.Ownership,
                  AttachmentKind.Beneficiaries
                ]}
              />
            </TabPanel.Section>
          </div>
          <fieldset className={'flex justify-center gap-2 p-4 md:justify-end'} disabled={isSubmitting}>
            <Button
              id={'collectables_add_other_cancel'}
              className={'min-w-[130px]'}
              variant={'outline'}
              size={'md'}
              onClick={onCancel}
              data-testid={'cancel-button'}
              aria-label={'cancel-button'}
            >
              {t('Cancel')}
            </Button>
            <Button
              id={'collectables_add_other_create'}
              className={'group relative min-w-[130px]'}
              variant={'solid'}
              size={'md'}
              type={'submit'}
              disabled={!isValid || !locationId}
              data-testid={'submit-button'}
              aria-label={'submit-button'}
            >
              {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
              <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
            </Button>
          </fieldset>
        </form>
      </TabPanel>

      <NewAddress
        isOpen={isNewModalOpen}
        onSubmitted={(id) => setValue('location.locationId', id)}
        onClose={() => setIsNewModalOpen(false)}
      />
    </>
  )
}

function FormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}
