import { mutate } from 'swr'
import { unstable_serialize } from 'swr/infinite'

import { Database, type MapiSearchParams } from 'core/remodel/database'
import { ActionType } from 'core/remodel/types/actions/base'
import {
  Amount,
  AssetType,
  AttachmentKind,
  Currency,
  LocationType,
  MapiSearchWithValueObject,
  Optional
} from 'core/remodel/types/common'
import { Asset } from 'core/remodel/types/common/asset'
import { OtherRoomType, OwnerDetail, RentalDetail, type OtherRoom, type Property } from 'core/remodel/types/properties'
import { PropertySummary } from 'core/remodel/types/propertySummary'
import type { LocationSupportedAssetType } from 'core/remodel/types/relations'
import type { LocationInfo } from 'core/remodel/types/relations/locationInfo'
import { getKeyWithId } from '@/api/ActivityService'
import { AssociatedFile } from '@/api/CommonService'
import { convertCurrency } from '@/utils/currency'
import { delay } from '@/utils/delay'
import { getFullName } from '@/utils/formatter'
import { ImageSizes } from '@/utils/imageTools'
import { isFulfilled, isOwner } from '@/utils/predicate'
import type { ActionDetails } from '@/components/Actions'
import type { OfferValues, PropertyValues, RentOutValues, SoldValues, ValuationValues } from '@/components/form'

export const propertyQuery = {
  options: 'property-options',
  summary: 'property-summary',
  list: 'property-list',
  info: 'property-info',
  assets: 'property-assets',
  assetsBreakdown: 'assets-breakdown',
  assetsFilter: 'property-assets-filter',
  filter: 'property-filter',
  rooms: 'property-rooms',
  allRooms: 'property-all-rooms',
  positions: 'property-positions',
  action: 'property-action'
} as const

const mutateProperty = (id?: string) => {
  mutate((key) => Array.isArray(key) && Object.values(propertyQuery).includes(key[0]))
  if (id) mutate(unstable_serialize(getKeyWithId(AssetType.Property, id)))
}

export function fetchPropertyOptions(database: Database) {
  return async ([_key]: [typeof propertyQuery.options]) => {
    const isLocatable = ({ archived, closedWith }: Property) => !archived && !closedWith
    const list = await database.property.getAllRaw()
    return list.filter(isLocatable).map((property) => ({ label: property.name, value: property.id }))
  }
}

export function fetchPropertySummary(database: Database) {
  return async ([_key, currency = database.ExRate.BaseCurrency]: [typeof propertyQuery.summary, Currency?]) => {
    if (!currency) throw Error('No base currency')

    const [
      { assets, liabilities: _liabilities, netValue: _netValue, ...summary },
      properties,
      { Property: liabilities = { currency, value: 0 } },
      { Property: breakdown },
      { rates }
    ] = await Promise.all([
      database.property.getSyncedSummary(currency),
      database.property.getAll(),
      database.cashAndBanking.getAssetTypeLiabilities([AssetType.Property], currency),
      database.cashAndBanking.getAssetLiabilities([AssetType.Property], currency),
      database.ExRate.getToTargetExchangeRates(currency)
    ])

    const values: Pick<PropertySummary.Display, 'assets' | 'liabilities' | 'netValue'> = {
      assets,
      liabilities,
      netValue: { currency, value: assets.value - liabilities.value }
    }
    const breakdownMap = new Map(Object.entries(breakdown ?? {}))
    const breakdownLiabilities = properties
      .filter(({ id }) => breakdownMap.has(id))
      .map(({ id, name }) => ({ name, value: breakdownMap.get(id)! }))

    return {
      ...values,
      ...summary,
      properties: convertCurrency(properties, rates, currency),
      breakdownLiabilities
    }
  }
}

export function fetchProperties(database: Database) {
  return async ([_key, query]: [typeof propertyQuery.list, Record<string, any>]) => {
    return await database.getAssetsByTypes([AssetType.Property], query)
  }
}

export function fetchPropertyInfo(database: Database) {
  return async ([_key, id]: [typeof propertyQuery.info, string]) => {
    const property = await database.property.getById(id)
    try {
      if (isOwner(property.ownershipType, property.detail)) throw new Error('Is owner')
      if (!property.detail.landlordId) throw new Error('Without landlord')
      const landlord = await database.Account.getContact(property.detail.landlordId)
      if (landlord) throw new Error('Landlord found')
      return { ...property, detail: { ...property.detail, landlordId: undefined } }
    } catch {
      return property
    }
  }
}

// TODO, need to handle wine
async function getMapiAssetsOfProperty(database: Database, id: string): Promise<MapiSearchWithValueObject[]> {
  const batchSize = 300
  const { totalCount } = await database.getMapiAssetsByPropertyId(id, { limit: 1 })
  const promises = Array.from({ length: Math.ceil(totalCount / batchSize) }, async (_, index) => {
    const query = { limit: batchSize, offset: index * batchSize }
    const { list } = await database.getMapiAssetsByPropertyId(id, query)
    return list
  })
  return (await Promise.all(promises)).flat()
}

export function fetchPropertyAssets(database: Database) {
  return async ([_key, id, query]: [typeof propertyQuery.assets, string, Partial<MapiSearchParams>]) => {
    return await database.getAssetsByPropertyId<Asset>(id, query)
  }
}

//#NOTE wine from mapi doesn't have correct value
function transformAssetsOfProperty(baseCurrency: Currency, assets: MapiSearchWithValueObject[]) {
  return assets.map((asset) => ({
    assetId: asset.fireId,
    assetType: asset.category as LocationSupportedAssetType,
    subtype: asset.subType,
    value: asset.ownedValue || Amount.zero(baseCurrency),
    sold: !!(asset.excludeFromValue as Optional<boolean>)
  }))
}

export function fetchPropertyAssetsBreakdown(database: Database) {
  return async ([_key, id]: [typeof propertyQuery.assetsBreakdown, string]) => {
    const assets = await getMapiAssetsOfProperty(database, id)
    return await database.property.getAssetsBreakdown(transformAssetsOfProperty(database.ExRate.BaseCurrency!, assets))
  }
}

export function fetchPropertyAssetsFilter(database: Database) {
  return async ([_key, id]: [typeof propertyQuery.assetsFilter, string]) => {
    const [assets, property] = await Promise.all([getMapiAssetsOfProperty(database, id), database.property.getById(id)])
    // asset type
    const assetType = Array.from(new Set(assets.map((asset) => asset.category)))
    // rooms
    const { bedroom = [], bathroom = [], otherRoom = [], carPark = [] } = property.configuration ?? {}
    const rooms = [bedroom, bathroom, otherRoom, carPark].flat()
    const roomOptions = rooms.map((room) => ({ label: room.name, value: room.id }))
    return { assetType, roomOptions }
  }
}

export function fetchPropertyFilter(database: Database) {
  return async ([_key]: [typeof propertyQuery.filter]) => {
    const { subtype, value, purchaseAt } = await database.getFilterInfo(AssetType.Property)
    return { subtype, value, purchaseAt }
  }
}

// HACK
export function fetchPropertyRoomOptions(database: Database) {
  return async ([_key, assetId]: [typeof propertyQuery.rooms, string]) => {
    const property = await database.property.getById(assetId)
    if (!property.configuration) return []
    const { bedroom, bathroom, otherRoom, carPark } = property.configuration
    const rooms = [bedroom, bathroom, otherRoom, carPark].flat()
    return rooms.map((room) => ({ label: room.name, value: room.id }))
  }
}

export function fetchAllRoomOptions(database: Database) {
  return async ([_key, locationIds]: [typeof propertyQuery.allRooms, string[]]) => {
    const contactResponses = await Promise.allSettled(locationIds.map((id) => database.Account.getContact(id)))
    const contactLocations = contactResponses.filter(isFulfilled).map(({ value }) => value)
    const contactRooms = contactLocations.flatMap((contact) => {
      if (!contact.room) return []
      const rooms = contact.room
      return rooms.map((room) => ({ label: room.name, value: room.id }))
    })
    const propertyResponses = await Promise.allSettled(locationIds.map((id) => database.property.getById(id)))
    const propertyLocations = propertyResponses.filter(isFulfilled).map(({ value }) => value)
    const propertyRooms = propertyLocations.flatMap((property) => {
      if (!property.configuration) return []
      const { bedroom, bathroom, otherRoom, carPark } = property.configuration
      const rooms = [bedroom, bathroom, otherRoom, carPark].flat()
      return rooms.map((room) => ({ label: room.name, value: room.id }))
    })

    return [...contactRooms, ...propertyRooms]
  }
}

// HACK
export function fetchPropertyPositionOptions(database: Database) {
  return async ([_key, assetId, roomId]: [typeof propertyQuery.positions, string, string | undefined]) => {
    const property = await database.property.getById(assetId)
    if (!property.configuration) return []
    const { bedroom, bathroom, otherRoom, carPark } = property.configuration!
    const targetRoom = [bedroom, bathroom, otherRoom, carPark].flat().find((item) => item.id === roomId)
    if (!targetRoom) return []
    const positions = targetRoom.position.map((item) => ({ label: item, value: item }))
    return positions
  }
}

export function fetchActions(database: Database) {
  return async ([_key, id]: [typeof propertyQuery.action, string]) => {
    const raw = await database.property.getAllActions(id)
    const actions: ActionDetails[] = await Promise.all(
      raw.map(async (action) => {
        switch (action.actionType) {
          case ActionType.AddValuation:
            try {
              if (!action.valuedById) return action
              const valueBy = await database.Account.getContact(action.valuedById)
              const valueByName = getFullName(valueBy)
              return { ...action, valueByName }
            } catch (e) {
              return { ...action, valuedById: '', valueByName: 'DeletedContact' }
            }
          case ActionType.AddOffer:
            try {
              const buyer = await database.Account.getContact(action.buyer)
              const buyerName = getFullName(buyer)
              return { ...action, buyerName }
            } catch {
              return { ...action, buyer: '', buyerName: 'DeletedContact' }
            }
          case ActionType.RentOut:
            try {
              const tenantId = action.tenantId
              const tenantName = getFullName(await database.Account.getContact(tenantId))
              return { ...action, tenantId, tenantName }
            } catch {
              return { ...action, tenantId: 'DeletedContact' }
            }
          default:
            return action
        }
      })
    )
    return actions
  }
}

const cleanupFormData = (data: PropertyValues): PropertyValues => {
  const { detail, ownershipType, ...rest } = data
  const isOwnerDetail = isOwner(ownershipType, detail)

  const cleanedDetail: RentalDetail | OwnerDetail = isOwnerDetail
    ? {
        acquisition: detail.acquisition,
        ownership: detail.ownership,
        beneficiary: detail.beneficiary
      }
    : {
        agreementType: detail.agreementType,
        monthlyRental: detail.monthlyRental,
        securityDeposit: detail.securityDeposit,
        term: detail.term,
        landlordId: detail.landlordId,
        otherFees: detail.otherFees,
        notes: detail.notes
      }

  return { ...rest, detail: cleanedDetail, ownershipType }
}

export async function addProperty(database: Database, id: string, data: PropertyValues) {
  const cleanData = cleanupFormData(data)
  await database.property.add({ ...cleanData, id })
  await delay()
  mutateProperty()
}

export async function updateProperty(database: Database, id: string, data: PropertyValues) {
  const cleanData = cleanupFormData(data)

  const { attachments: newAttach = [] } = cleanData
  const { attachments: oldAttach = [] } = await database.property.getById(id)
  await database.property.update({ ...cleanData, id })
  Promise.all(
    oldAttach
      .filter(({ key }) => !newAttach.some((attach) => attach.key === key))
      .map(async ({ key }) => {
        try {
          const { contentType = '' } = await database.Attachments.getMeta(id, key)
          if (contentType.startsWith('image/')) {
            // remove thumbnails
            Promise.all(
              Object.values(ImageSizes).map((size) =>
                database.Attachments.delete(id, `${key}_${size}`).catch((e) => console.log(e))
              )
            )
          }
          database.Attachments.delete(id, key)
        } catch (error) {
          console.log(error)
        }
      })
  )
  await delay()
  mutateProperty(id)
}

export async function deleteProperty(database: Database, id: string) {
  const task = await database.property.delete(id)
  await task.run()
  // HACK, it need more time to wait for the task to be done
  await delay(3000)
  mutateProperty(id)
}

// HACK
export async function addPropertyRoom(database: Database, id: string, roomName: string) {
  const property = await database.property.getById(id)
  if (!property.configuration) throw new Error('Property configuration not found')
  const newRoomId = database.genAssetId()
  const newRoom: OtherRoom = {
    id: newRoomId,
    name: roomName,
    otherRoomType: OtherRoomType.OtherRoom,
    position: []
  }
  const newValue: Property = {
    ...property,
    configuration: {
      ...property.configuration!,
      otherRoom: [...property.configuration!.otherRoom, newRoom]
    }
  }
  await database.property.update(newValue)
  await delay()
  mutateProperty(id)
  return newRoomId
}

// HACK
export async function addPropertyPosition(database: Database, id: string, roomId: string, positionName: string) {
  const property = await database.property.getById(id)
  const { bedroom, bathroom, otherRoom, carPark } = property.configuration!
  const rooms = { bedroom, bathroom, otherRoom, carPark }
  const targetRoomType = (Object.keys(rooms) as (keyof typeof rooms)[]).find((key) =>
    rooms[key].find((item) => item.id === roomId)
  )
  if (!targetRoomType) throw new Error('Room not found')
  const targetRooms = rooms[targetRoomType].map((item) =>
    item.id === roomId ? { ...item, position: [...item.position, positionName] } : item
  )
  const newValue: Property = {
    ...property,
    configuration: {
      ...property.configuration!,
      [targetRoomType]: targetRooms
    }
  }
  await database.property.update(newValue)
  await delay()
  mutateProperty(id)
}

export async function updateAssetImages(database: Database, id: string, images: AssociatedFile[]) {
  const property = await database.property.getById(id)
  const oldAttach = property.attachments ?? []
  const newAttach = [
    ...images,
    ...(property.attachments?.filter((attach) => attach.type !== AttachmentKind.AssetImage) ?? [])
  ]
  await database.property.update({ ...property, attachments: newAttach, mainImage: images[0]?.key })
  Promise.all(
    oldAttach
      .filter(({ key }) => !newAttach.some((attach) => attach.key === key))
      .map(async ({ key }) => {
        // remove thumbnails
        Promise.all(
          Object.values(ImageSizes).map((size) =>
            database.Attachments.delete(id, `${key}_${size}`).catch((e) => console.log(e))
          )
        )
        database.Attachments.delete(id, key).catch((e) => console.log(e))
      })
  )
  await delay()
  mutateProperty(id)
}

// valuation
export async function addValuation(database: Database, id: string, data: ValuationValues) {
  const actionId = database.genAssetId()
  await database.property.addValuation(id, { ...data, id: actionId })
  await delay()
  mutateProperty(id)
}

export async function updateValuation(database: Database, id: string, data: ValuationValues) {
  await database.property.updateValuation(id, data)
  await delay()
  mutateProperty(id)
}

export async function deleteValuation(database: Database, id: string, valuationId: string) {
  await database.property.deleteValuation(id, valuationId)
  await delay()
  mutateProperty(id)
}

// offer
export async function addOffer(database: Database, id: string, data: OfferValues) {
  const actionId = database.genAssetId()
  await database.property.addOffer(id, { ...data, id: actionId })
  await delay()
  mutateProperty(id)
}

export async function updateOffer(database: Database, id: string, data: OfferValues) {
  await database.property.updateOffer(id, data)
  await delay()
  mutateProperty(id)
}

export async function deleteOffer(database: Database, id: string, actionId: string) {
  await database.property.deleteOffer(id, actionId)
  await delay()
  mutateProperty(id)
}

// rent
export async function rentOut(database: Database, id: string, data: RentOutValues) {
  const actionId = database.genAssetId()
  await database.property.rentOut(id, { ...data, id: actionId })
  await delay()
  mutateProperty(id)
}

export async function updateRentInfo(database: Database, id: string, data: RentOutValues) {
  await database.property.updateRentInfo(id, data)
  // TODO: update the attachment related to this action
  await delay()
  mutateProperty(id)
}

export async function deleteRentInfo(database: Database, id: string, actionId: string) {
  // TODO: update the attachment related to this action
  await database.property.deleteRentInfo(id, actionId)
  await delay()
  mutateProperty(id)
}

// sold
export async function markAsSold(database: Database, id: string, data: SoldValues) {
  const actionId = database.genAssetId()
  await database.property.markAsSold(id, { ...data, id: actionId })
  await delay()
  mutateProperty(id)
}

export async function updateSoldInfo(database: Database, id: string, data: SoldValues) {
  await database.property.updateSoldInfo(id, data)
  await delay()
  mutateProperty(id)
}

export async function deleteSoldInfo(database: Database, id: string, soldId: string) {
  await database.property.deleteSoldInfo(id, soldId)
  await delay()
  mutateProperty(id)
}

// archive
export async function archive(
  database: Database,
  id: string,
  relocateTo?: LocationInfo,
  callback?: (percentage: number) => void
) {
  // check if there are assets in the property
  const items = await getMapiAssetsOfProperty(database, id)
  if (relocateTo && items.length > 0) {
    let progress: Record<'current' | 'total', number> = { current: 0, total: items.length }

    const newLocation: LocationInfo = {
      ...relocateTo,
      locationType: relocateTo.locationType === LocationType.MyProperty ? LocationType.MyProperty : LocationType.Address
    }

    for (const { fireId: id, category: assetType } of items) {
      switch (assetType) {
        case AssetType.Belonging: {
          const belongings = await database.belonging.getById(id)
          const oldLocation = belongings.location
          if (oldLocation.locationId === newLocation.locationId) continue

          await database.belonging.update({ ...belongings, location: newLocation })
          progress = { current: progress.current + 1, total: progress.total }
          callback && callback(Math.round((progress.current / progress.total) * 100))
          break
        }
        case AssetType.Art: {
          const arts = await database.art.getById(id)
          const oldLocation = arts.location
          if (oldLocation.locationId === newLocation.locationId || arts.closedWith) continue

          await database.art.update({ ...arts, location: newLocation })
          progress = { current: progress.current + 1, total: progress.total }
          callback && callback(Math.round((progress.current / progress.total) * 100))
          break
        }
        case AssetType.OtherCollectables: {
          const otherCollectables = await database.otherCollectable.getById(id)
          const oldLocation = otherCollectables.location
          if (oldLocation.locationId === newLocation.locationId) continue

          await database.otherCollectable.update({ ...otherCollectables, location: newLocation })
          progress = { current: progress.current + 1, total: progress.total }
          callback && callback(Math.round((progress.current / progress.total) * 100))
          break
        }
        // FIXME
        // case AssetType.WineAndSpirits: {
        //   // id: bottleId
        //   await database.wine.relocateBottle(wineId!, purchaseId!, [id], newLocation)
        //   progress = { current: progress.current + 1, total: progress.total }
        //   callback && callback(Math.round((progress.current / progress.total) * 100))
        //   break
        // }
        default: {
          console.log(`relocateAssets: assetType ${assetType} not supported`)
          break
        }
      }
    }
  }

  // archive property
  const archive = await database.property.archive(id, relocateTo)
  await archive.run()
  await delay()
  mutateProperty(id)
}

export async function restoreArchived(database: Database, id: string) {
  await database.property.restoreArchived(id)
  await delay()
  mutateProperty(id)
}

// delete assets
export async function deleteAssets(
  database: Database,
  id: string,
  selectedIds: string[],
  callback?: (percentage: number) => void
) {
  const allItems = await getMapiAssetsOfProperty(database, id)
  const items = allItems.filter(({ fireId }) => selectedIds.includes(fireId))

  if (items.length > 0) {
    let progress: Record<'current' | 'total', number> = { current: 0, total: items.length }

    for (const { fireId: id, category: assetType } of items) {
      switch (assetType) {
        case AssetType.Belonging: {
          await database.belonging.delete(id)
          progress = { current: progress.current + 1, total: progress.total }
          callback && callback(Math.round((progress.current / progress.total) * 100))
          break
        }
        case AssetType.Art: {
          await database.art.delete(id)
          progress = { current: progress.current + 1, total: progress.total }
          callback && callback(Math.round((progress.current / progress.total) * 100))
          break
        }
        case AssetType.OtherCollectables: {
          await database.otherCollectable.delete(id)
          progress = { current: progress.current + 1, total: progress.total }
          callback && callback(Math.round((progress.current / progress.total) * 100))
          break
        }
        // FIXME
        // case AssetType.WineAndSpirits: {
        //   const removal: Removal = {
        //     reason: RemovalReason.DeleteAndExclude,
        //     date: new Date(),
        //     notes: ''
        //   }
        //   await database.wine.removeBottle(wineId!, purchaseId!, [id], removal)
        //   progress = { current: progress.current + 1, total: progress.total }
        //   callback && callback(Math.round((progress.current / progress.total) * 100))
        //   break
        // }
        default: {
          console.log(`deleteAssets: assetType ${assetType} not supported`)
          break
        }
      }
    }
  }

  await delay()
  mutateProperty(id)
}

// relocate assets
export async function relocateAssets(
  database: Database,
  id: string,
  relocateTo: LocationInfo,
  selectedIds: string[],
  callback?: (percentage: number) => void
) {
  const allItems = await getMapiAssetsOfProperty(database, id)
  const items = allItems.filter(({ fireId }) => selectedIds.includes(fireId))

  if (items.length > 0) {
    let progress: Record<'current' | 'total', number> = { current: 0, total: items.length }

    const newLocation: LocationInfo = {
      ...relocateTo,
      locationType: relocateTo.locationType === LocationType.MyProperty ? LocationType.MyProperty : LocationType.Address
    }

    for (const { fireId: id, category: assetType } of items) {
      switch (assetType) {
        case AssetType.Belonging: {
          const belongings = await database.belonging.getById(id)
          const oldLocation = belongings.location
          if (oldLocation.locationId === newLocation.locationId) continue

          await database.belonging.update({ ...belongings, location: newLocation })
          progress = { current: progress.current + 1, total: progress.total }
          callback && callback(Math.round((progress.current / progress.total) * 100))
          break
        }
        case AssetType.Art: {
          const arts = await database.art.getById(id)
          const oldLocation = arts.location
          if (oldLocation.locationId === newLocation.locationId || arts.closedWith) continue

          await database.art.update({ ...arts, location: newLocation })
          progress = { current: progress.current + 1, total: progress.total }
          callback && callback(Math.round((progress.current / progress.total) * 100))
          break
        }
        case AssetType.OtherCollectables: {
          const otherCollectables = await database.otherCollectable.getById(id)
          const oldLocation = otherCollectables.location
          if (oldLocation.locationId === newLocation.locationId) continue

          await database.otherCollectable.update({ ...otherCollectables, location: newLocation })
          progress = { current: progress.current + 1, total: progress.total }
          callback && callback(Math.round((progress.current / progress.total) * 100))
          break
        }
        // FIXME
        // case AssetType.WineAndSpirits: {
        //   // id: bottleId
        //   await database.wine.relocateBottle(wineId!, purchaseId!, [id], newLocation)
        //   progress = { current: progress.current + 1, total: progress.total }
        //   callback && callback(Math.round((progress.current / progress.total) * 100))
        //   break
        // }
        default: {
          console.log(`relocateAssets: assetType ${assetType} not supported`)
          break
        }
      }
    }
  }

  await delay()
  mutateProperty(id)
}
