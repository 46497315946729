import * as React from 'react'
import { addYears, format, getMonth, getYear, setMonth, setYear, subYears } from 'date-fns'
import { Calendar as CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'
import { useController, type Control, type FieldPath, type FieldValues, type RegisterOptions } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { cn } from '@/utils/classnames'
import { Button, Popover, PopoverContent, PopoverTrigger } from '@/components/base'

interface FormMonthPickerProps<T extends FieldValues, U extends FieldPath<T>> {
  label?: string
  className?: string
  fromYear?: number
  toYear?: number
  name: U
  control: Control<T>
  rules?: RegisterOptions<T>
  disabled?: boolean
}

export function FormMonthPicker<T extends FieldValues, U extends FieldPath<T>>({
  label,
  className,
  fromYear = 1900,
  toYear = 2100,
  name,
  control,
  rules,
  disabled = false
}: FormMonthPickerProps<T, U>) {
  const id = React.useId()
  const { field } = useController({ control, name, rules })
  const { i18n } = useTranslation()

  return (
    <div className={cn('grid gap-y-1', className)}>
      {label && (
        <label
          htmlFor={id}
          className={cn(
            'text-xs text-[#414554]',
            rules?.required !== undefined && 'after:ml-0.5 after:text-error after:content-["*"]'
          )}
        >
          {label}
        </label>
      )}
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id={id}
            className={
              'box-content h-9 justify-end gap-x-2 border border-gray-200 bg-grey-input px-3 text-sm text-text focus:border-primary'
            }
            disabled={disabled}
            data-testid={`${name}-month-picker-trigger`}
            aria-label={`${name}-month-picker-trigger`}
          >
            {field.value ? format(field.value, 'MM/yy') : <span>{'MM/YY'}</span>}
            <CalendarIcon className={'text-primary'} />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={'w-auto p-0'}>
          <div className={'min-w-[256px] p-4'}>
            <div className={'mb-4 flex items-center justify-between'}>
              <Button className={'hover:text-text'} onClick={() => field.onChange(subYears(field.value, 1))}>
                <ChevronLeftIcon size={16} />
              </Button>
              <select
                className={'bg-transparent'}
                value={getYear(field.value)}
                onChange={(e) => field.onChange(setYear(field.value, +e.target.value))}
              >
                {range(fromYear, toYear).map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
              <Button className={'hover:text-text'} onClick={() => field.onChange(addYears(field.value, 1))}>
                <ChevronRightIcon size={16} />
              </Button>
            </div>
            <div className={'grid grid-cols-3'}>
              {getMonths(i18n.language, 'short').map((label, index) => (
                <Button
                  key={index}
                  className={cn(
                    'p-2 text-sm text-text',
                    index === getMonth(field.value) ? 'bg-primary text-white' : 'hover:bg-grey/20'
                  )}
                  onClick={() => field.onChange(setMonth(field.value, index))}
                >
                  {label}
                </Button>
              ))}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

function getMonths(locales?: string | string[], format: 'long' | 'short' = 'long') {
  const months = Array.from({ length: 12 }).map((_, i) => i)
  const formatter = new Intl.DateTimeFormat(locales, { month: format })
  return months.map((month) => formatter.format(new Date(0, month)))
}

function range(start: number, end: number) {
  return Array(Math.ceil(end - start))
    .fill(start)
    .map((_, i) => i + start)
}
